/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import moment from 'moment';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ideaIcon from 'images/icons/idea.png';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import iconHome from 'images/fillGray/homeOutline.svg';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import KEY from '../../Const';
import { ITimeBox, IHour } from '../../types/DateTime';
import { ISelected, ISelectedSalonBackUp } from '../../types/Selected';
import SalonBackUp from './components/SalonBackUp';
import './styles.scss';
import SuggestBooking from './components/SuggestBooking';

const TitleSuggestSalon: FC<{
    data: any;
    handleClickSalonSuggest: Function;
    selected: ISelected;
    handleClickDateBook: Function;
}> = ({ data, handleClickSalonSuggest, selected, handleClickDateBook }) => {
    const name = selected[KEY.STYLIST].name.trim().split(' ');
    const displayNameStylist = `${
        name.length > 1 ? name[name.length - 2] : ''
    } ${name[name.length - 1]}`;

    return data?.length > 0 ? (
        <div className="flex items-center ">
            <div
                className="fadeInUp mr-2 flex items-center"
                style={{ flex: '0 0 24px' }}
            >
                <img
                    src={ideaIcon}
                    alt=""
                    className="animate-pulse w-6 h-6"
                    style={{ animationDuration: '500ms' }}
                />
            </div>
            <div className="fadeInUp" style={{ animationDelay: '700ms' }}>
                <SuggestBooking
                    handleClick={handleClickSalonSuggest}
                    data={[]}
                    text={<div>Bạn không có giờ phù hợp?</div>}
                />
            </div>
        </div>
    ) : selected[KEY.STYLIST].id > 0 ? (
        <SuggestBooking
            handleClick={handleClickDateBook}
            data={data}
            text={
                <div>
                    {displayNameStylist} còn rất nhiều giờ trống vào ngày mai,
                    click để đổi sang ngày mai bạn nhé!
                </div>
            }
        />
    ) : (
        <div className="flex items-center">
            <div
                className="fadeInUp flex items-center mr-2"
                style={{ flex: '0 0 24px' }}
            >
                <img
                    src={ideaIcon}
                    alt=""
                    className="animate-pulse w-6 h-6"
                    style={{ animationDuration: '500ms' }}
                />
            </div>
            <div className="fadeInUp" style={{ animationDelay: '700ms' }}>
                <SuggestBooking handleClick={handleClickDateBook} data={data} />
            </div>
        </div>
    );
};

export default TitleSuggestSalon;
