import React, { FC } from 'react';
import { Modal } from 'antd';
import './style.scss';

const ConfirmModal: FC<{
    title: string;
    visible: boolean;
    onCancel: any;
    className?: string;
    children?: React.ReactNode;
}> = ({ title, visible, onCancel, className, children }) => {
    return (
        <div>
            <Modal
                className={`modal-confirm ${className}`}
                title={title}
                visible={visible}
                width={380}
                footer={null}
                onCancel={onCancel}
            >
                {children}
            </Modal>
        </div>
    );
};

export default ConfirmModal;
