import React, { Fragment, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import iconBack from 'images/icons/chevronLeft.svg';
import './index.scss';
import './indexV2.scss';

import { useCookies } from 'react-cookie';
import { Button } from 'antd';
import API from 'repository';
import OtpInput from 'components/OtpInput/index.js';
import trackingConst from 'config/TrackingConst';
import Cookies from 'js-cookie';

export const Step2Captcha = ({
    prevStep,
    onChangePassword,
    actionSendOtp,
    state,
    setState,
    getPhone,
    actions,
    commonDataTracking,
    postTracking,
    onCancel,
    showMessage,
    countErrorLogin,
    setCountErrorLogin,
    isCaptcha,
    phoneBooking,
}) => {
    const { phone, loading, password, error } = state;

    const { executeRecaptcha } = useGoogleReCaptcha();
    const KEY_ENTER = 13;

    const handleReCaptchaVerify = async () => {
        if (executeRecaptcha && isCaptcha) {
            const token = await executeRecaptcha('');
            if (token) return token;
        }
        return '';
    };

    const handleLogin = async () => {
        const phone = getPhone();
        if (!phone) return;
        const { password } = state;
        if (password.length !== 6) {
            setState({ ...state, error: 'Mật khẩu sai định dạng.' });
            return;
        }
        if (phone) {
            try {
                const token = await handleReCaptchaVerify();
                setState({ ...state, loading: true });
                const value = {
                    phone,
                    password,
                    recaptcha: countErrorLogin > 2 ? token : '',
                };
                const _data = await API.login(value);
                if (_data.success) {
                    setCountErrorLogin(0);
                    localStorage.setItem('accessToken', _data.accessToken);
                    localStorage.setItem('customerPhone', phone);

                    const cookieExpires = new Date(
                        new Date().getTime() + 3600 * 1000 * 24 * 365 * 3,
                    );
                    Cookies.set('_cus_accessToken2', _data.accessToken, {
                        path: '/',
                        expires: cookieExpires,
                        domain: process.env.REACT_APP_BASE_URL,
                        secure: true,
                    });

                    const user = await actions.fetchUser();
                    if (user.data.userInfo.userIdSql) {
                        const params = {
                            ...commonDataTracking,
                            eventId: trackingConst.Events.LOGIN_SUCCESS,
                            data: {
                                customerId: user.data.userInfo.userIdSql,
                                phone: state.phone.replace(/\./g, '') || '',
                            },
                        };
                        postTracking(params);
                        showMessage('Đăng nhập thành công!', 'success');
                        onCancel();
                    }
                    setState({ ...state, loading: false, error: '' });
                } else {
                    if (_data?.enableCaptcha) setCountErrorLogin(4);
                    else setCountErrorLogin(countErrorLogin + 1);

                    setState({
                        ...state,
                        loading: false,
                        error:
                            _data?.enableCaptcha && countErrorLogin < 2
                                ? ''
                                : _data.message,
                        captcha: !!_data?.enableCaptcha,
                    });
                }
            } catch (er) {
                setState({ ...state, loading: false, error: '' });
                console.log(er);
            }
        }
    };
    const onKeyDown = (e, param) => {
        if (e.keyCode === KEY_ENTER) {
            switch (param) {
                case 'login':
                    handleLogin();
                    break;
                default:
                    break;
            }
        }
    };
    useEffect(() => {
        if (state.captcha && isCaptcha && executeRecaptcha) {
            handleLogin();
        }
    }, [state.captcha, executeRecaptcha]);
    useEffect(() => {
        if (isCaptcha && executeRecaptcha && password) handleLogin();
    }, []);
    return (
        <>
            {!phoneBooking ? (
                <div
                    className="from__btn btn-back"
                    onClick={prevStep}
                    role="presentation"
                >
                    <img src={iconBack} alt="back-icon" />
                </div>
            ) : null}
            <div className="form__text">
                <Fragment>
                    Nhập mật khẩu của SĐT <b>{phone}</b> hoặc chọn{' '}
                    <b>Quên mật khẩu</b>
                </Fragment>
            </div>
            <div className="form__input form__input-pin">
                <OtpInput
                    value={password}
                    onChange={(value) => onChangePassword('password', value)}
                    numInputs={6}
                    isInputNum
                    focusStyle={{ borderColor: '#222222' }}
                    shouldAutoFocus
                    onKeyDown={(e) => onKeyDown(e, 'login')}
                />
            </div>
            {error && <div className="form__message">{error}</div>}
            <div className="form__getPassword">
                <span onClick={actionSendOtp} role="presentation">
                    Quên mật khẩu
                </span>
            </div>
            <Button
                type="primary"
                onClick={handleLogin}
                className="form__button btn-color-1"
                loading={loading}
            >
                HOÀN TẤT
            </Button>
        </>
    );
};
