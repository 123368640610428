/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Dropdown, Menu, notification } from 'antd';
import { fetchUser, logoutUser } from 'actions/user';
import {
    showModalLogin,
    setTitleModalLogin,
    setIsHandleMenuBooked,
} from 'actions/common';
import CustomModal from 'components/modalcheckbooking/ModalCheckBooking';
import './style_v2.scss';
import closeButton from 'images/icons/close.svg';
import menuButton from 'images/icons/menu.svg';
import { connect } from 'react-redux';
import { CaretDownOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery';
import { Helmet } from 'react-helmet';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import Silver from 'images/iconMember/Silver.png';
import Gold from 'images/iconMember/Gold.png';
import Diamond from 'images/iconMember/Diamond.png';
import ModalLogin from '../../login/ModalLogin';
import { listMenu } from './ListMenuV3';
import { getQuery } from '../../func/Function';

const converCurrency = (value) => {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

function menuDropDown(logout, balance) {
    return (
        <Menu className="menu-topup">
            <Menu.Item>
                <div
                    style={{
                        minWidth: '170px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div> Số dư TOPUP </div>
                    <div className="balance-topup">
                        {converCurrency(balance)}đ
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item>
                <Link to="/gioi-thieu" title="Lịch sử chi tiêu">
                    Lịch sử chi tiêu
                </Link>
            </Menu.Item>
            <Menu.Item>
                <div onClick={() => logout()}> Đăng xuất </div>
            </Menu.Item>
        </Menu>
    );
}

const HeaderV4 = ({
    showModalLogin,
    name,
    phone,
    balance,
    fetchUser,
    trackingData,
    setTitleModalLogin,
    setIsHandleMenuBooked,
    logoutUser,
    memberType,
}) => {
    const appSources = ['customer_ios', 'customer_android', 'friendly_checkin'];
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [queryParams, setQueryParams] = useState({ inApp: 0, source: '' });
    const history = useHistory();
    const [active, setActive] = useState(false);
    const [visible, setVisible] = useState(false);
    const [memberIcon, setMemberIcon] = useState();
    const location = useLocation();
    const listMenuDisplay = isMobile ? listMenu.mb : listMenu.pc;
    // tracking
    const commonDataTracking = useRef({
        pageId: trackingConst.Pages.COMMON_PAGE,
        screenId: trackingConst.Screens.COMMON_SCREEN,
        eventId: '',
        tokenKey: trackingData.token,
        source: trackingData.source,
        utmSource: trackingData.utmSource,
    });

    async function postTracking(params) {
        try {
            await API.postTrackingDynamic(params);
        } catch (e) {
            console.log(e.message);
        }
    }

    useEffect(() => {
        setQueryParams(getQuery(window.location.search));
        // history.listen((location) => {
        //     setQueryParams(getQuery(location.search))
        // })
    }, []);

    function toggleActiveMenu(value) {
        setActive(value);

        // tracking
        const params = {
            ...commonDataTracking.current,
            eventId: trackingConst.Events.HEADER_TOGGLE_MENU,
        };
        postTracking(params);
    }

    useEffect(() => {
        if (active && isMobile) {
            $('body').css({
                overflow: 'hidden',
                height: '100%',
            });
        } else {
            $('body').css({
                overflow: 'auto',
                height: 'auto',
            });
        }
    }, [active, isMobile]);

    function logout() {
        logoutUser();
        fetchUser();
        notification.success({
            message: 'Thông báo',
            description: 'Đăng xuất thành công!',
            className: 'success',
        });
    }

    const handleClickMenu = (e, menu) => {
        e.preventDefault();
        toggleActiveMenu(false);
        if (menu.to) {
            window.open(menu.url, '_blank');
        } else if (menu.url === '#login') {
            history.push({
                pathname: '/',
            });
            setIsHandleMenuBooked(true);
            if (!phone) {
                showModalLogin(true);
                setTitleModalLogin(
                    <div>
                        ĐĂNG NHẬP <span> ĐỂ XEM LỊCH ĐÃ ĐẶT </span>
                    </div>,
                );
            }
        } else {
            history.push(menu.url);
        }
        // tracking
        if (Object.prototype.hasOwnProperty.call(menu, 'eventId')) {
            const params = {
                ...commonDataTracking.current,
                eventId: menu.eventId,
            };
            postTracking(params);
        }
    };

    const handleClickLogin = () => {
        showModalLogin(true);

        // tracking
        const params = {
            ...commonDataTracking.current,
            eventId: trackingConst.Events.HEADER_CLICK_LOGIN,
        };
        postTracking(params);
    };
    useEffect(() => {
        switch (memberType) {
            case 2:
                setMemberIcon(Silver);
                break;
            case 3:
                setMemberIcon(Gold);
                break;
            case 4:
                setMemberIcon(Diamond);
                break;
            default:
                break;
        }
    }, [memberType]);
    return (
        <div>
            <Helmet>
                <title>Moyo - Chuỗi gội & chăm sóc da đầu thế hệ mới</title>
                <meta
                    name="description"
                    content="Moyo - Chuỗi gội & chăm sóc da đầu thế hệ mới, mang đến trải nghiệm tinh tế và hiện đại với công nghệ tiên tiến và các sản phẩm chăm sóc tóc chất lượng cao!"
                    charSet="utf-8"
                />
                <meta
                    name="keywords"
                    itemProp="keywords"
                    content="moyo, Chuỗi gội đầu, thế hệ mới, trải nghiệm tinh tế, hiện đại, công nghệ tiên tiến, sản phẩm, chăm sóc tóc, chất lượng cao"
                    charSet="utf-8"
                />
            </Helmet>
            {!(
                isMobile &&
                (queryParams.inApp || appSources.includes(queryParams.source))
            ) && (
                <div className="new-header">
                    <div className="new-header__container">
                        <nav className="nav-bar">
                            <div className="nav-bar__logo-main">
                                <Link to="/" title="trang chủ">
                                    <img src="/logo.png" alt="trang chủ" />
                                </Link>
                            </div>
                            <div
                                className={
                                    active
                                        ? 'nav-bar__menu active'
                                        : 'nav-bar__menu'
                                }
                            >
                                <div
                                    onClick={() => toggleActiveMenu(!active)}
                                    className="nav-bar__close"
                                >
                                    <img src={closeButton} alt="menu" />
                                </div>
                                <div className="nav-bar__logo">
                                    <a href="#" title="trang chủ">
                                        <img
                                            src="/logo.png"
                                            alt="trang chủ"
                                            className="max-w-[114px]"
                                        />
                                    </a>
                                </div>
                                <ul className="menu">
                                    {listMenuDisplay &&
                                        listMenuDisplay.length > 0 &&
                                        listMenuDisplay.map((item) => {
                                            return (
                                                <li
                                                    key={`list-menu-display-${item.title}`}
                                                    className={`menu-item ${
                                                        item.class
                                                            ? item.class
                                                            : ''
                                                    }`}
                                                    role="presentation"
                                                >
                                                    <a
                                                        href={item.url}
                                                        onClick={(e) =>
                                                            handleClickMenu(
                                                                e,
                                                                item,
                                                            )
                                                        }
                                                    >
                                                        <span
                                                            className={`menu-item-text pointer ${
                                                                location.pathname ===
                                                                item.url
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {item.title}
                                                        </span>
                                                    </a>
                                                </li>
                                            );
                                        })}
                                </ul>
                                {/* <div className={'menu-action'}>
                                                        <a
                                                            href='#'
                                                            className={'menu-action__button'}
                                                            onClick={() =>
                                                                handleViewLatestBooking(true)
                                                            }
                                                        >
                                                            Xem lịch cắt
                                                        </a>
                                                    </div> */}
                            </div>
                            <div className="nav-bar__action">
                                {/* <div
                                                        className='booking-button pointer'
                                                        onClick={() => handleViewLatestBooking()}
                                                    >
                                                        <span>Xem lịch đặt</span>
                                                    </div> */}
                                {phone ? (
                                    <Dropdown
                                        overlay={menuDropDown(logout, balance)}
                                        placement="bottomRight"
                                        trigger={['click']}
                                    >
                                        <div className="login-button pointer item-center">
                                            <span
                                                className="uppercase"
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {memberType ? (
                                                    <div className="member-icon">
                                                        <img
                                                            className="icon"
                                                            src={memberIcon}
                                                            alt=""
                                                        />
                                                    </div>
                                                ) : null}
                                                <span className="member-name">
                                                    {' '}
                                                    {name || phone}
                                                </span>
                                            </span>
                                            <CaretDownOutlined />
                                        </div>
                                    </Dropdown>
                                ) : (
                                    <div
                                        className="login-button"
                                        onClick={handleClickLogin}
                                    >
                                        <span> Đăng nhập </span>
                                    </div>
                                )}
                                <div
                                    onClick={() => toggleActiveMenu(!active)}
                                    className="menu-button"
                                >
                                    <img src={menuButton} alt="" />
                                </div>
                            </div>
                        </nav>
                    </div>
                    <ModalLogin />
                    <CustomModal
                        visible={visible}
                        setVisible={setVisible}
                        phone={phone}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    const { user } = state;
    return {
        phone: user.phoneNumber,
        name: user.userName,
        balance: user.accountBalance,
        trackingData: state.trackingData,
        memberType: user.memberType,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUser: () => dispatch(fetchUser(false)),
        showModalLogin: (value) => dispatch(showModalLogin(value)),
        setTitleModalLogin: (value) => dispatch(setTitleModalLogin(value)),
        setIsHandleMenuBooked: (value) =>
            dispatch(setIsHandleMenuBooked(value)),
        logoutUser: () => dispatch(logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderV4);
