import React, { FC, useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import trackingConst from 'config/TrackingConst';
import API from 'repository';
import { ISelected } from '../../types/Selected';
import KEY from '../../Const';
import './styles.scss';
import SwiperImage from './SwiperImage';
import { formatPriceServiceToNum } from 'utils/BookingUltils';

const ModalSuggestService: FC<{
    trackingData: any;
    selected: ISelected;
    onChangeSelectedService: Function;
    isClickNextInService: boolean;
}> = ({
    trackingData,
    selected,
    onChangeSelectedService,
    isClickNextInService,
}) => {
    const [satisfyingRule, setSatisfyingRule] = useState<any>({});
    const [isShowModalSuggestService, setIsShowModalSuggestService] = useState<
        boolean
    >(false);

    const commonDataTracking = useRef<any>({
        pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
        screenId: trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
        eventId: '',
        tokenKey: trackingData.token,
        source: trackingData.source,
        utmSource: trackingData.utmSource,
        data: {
            phone: selected[KEY.PHONE],
            salonId: selected[KEY.SALON].id.toString(),
            suggestServices: satisfyingRule.listService
                ? JSON.stringify(satisfyingRule.listService[0])
                : '',
            chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
        },
    });

    async function postTracking(data: any) {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    }
    const checkRuleEqual = (listIdService: any) => {
        return (
            listIdService.length === selected.chosenService.length &&
            selected.chosenService.every((service) => {
                return listIdService.includes(service.serviceId);
            })
        );
    };

    const checkRuleContain = (listIdService: any) => {
        return (
            listIdService.length <= selected.chosenService.length &&
            listIdService.every((idService: any) => {
                return (
                    selected.chosenService.findIndex(
                        (service) => service.serviceId === idService,
                    ) !== -1
                );
            })
        );
    };

    const checkRuleRegex = (regex: any) => {
        const stringIds = `${selected.chosenService
            .map((service) => service.serviceId)
            .join(';')};`;
        return !!stringIds.match(regex);
    };

    const checkRuleNextStep = (ruleNextSteps: any) => {
        let isValidRule = false;
        if (selected.salon?.selectConsultation) return false;
        if (ruleNextSteps && ruleNextSteps.length >= 1) {
            ruleNextSteps.every((rule: any) => {
                switch (rule.ruleType) {
                    case 'equals': {
                        isValidRule = checkRuleEqual(rule.serviceSelected);
                        break;
                    }
                    case 'regex': {
                        isValidRule = checkRuleRegex(rule.serviceSelected);
                        break;
                    }
                    default: {
                        isValidRule = checkRuleContain(rule.serviceSelected);
                    }
                }
                if (isValidRule) {
                    const dataTracking = {
                        ...commonDataTracking.current,
                        eventId:
                            trackingConst.Events.SHOW_POPUP_SUGGEST_SERVICE,
                        data: {
                            phone: selected[KEY.PHONE],
                            salonId: selected[KEY.SALON].id.toString(),
                            suggestServices: rule.listService
                                ? JSON.stringify(rule.listService[0])
                                : '',
                            chosenServices: JSON.stringify(
                                selected[KEY.CHOSEN_SERVICES],
                            ),
                        },
                    };
                    postTracking(dataTracking);
                    setSatisfyingRule(rule);
                    setIsShowModalSuggestService(true);
                    return false;
                }
                return true;
            });
        }
    };

    const handleClose = (isClickOk: boolean) => {
        setIsShowModalSuggestService(false);
        if (isClickOk) {
            const service = satisfyingRule.listService[0];
            const newService = {
                ...service,
                oldPriceNum: service?.oldPrice
                    ? formatPriceServiceToNum(service.oldPrice)
                    : service.priceNum,
                oldPrice: service?.oldPrice || service.price,
            };
            const listServiceWithOutDuplicate = [
                ...selected.chosenService,
                newService,
            ];
            onChangeSelectedService(listServiceWithOutDuplicate);
        }

        // tracking
        const dataTracking = {
            ...commonDataTracking.current,
            eventId: isClickOk
                ? trackingConst.Events.CLICK_BUTTON_OK_IN_POPUP_SUGGEST_SERVICE
                : trackingConst.Events
                      .CLICK_BUTTON_CLOSE_IN_POPUP_SUGGEST_SERVICE,
        };

        postTracking(dataTracking);
    };

    useEffect(() => {
        if (isClickNextInService && selected.listServices.rulesNextStep) {
            checkRuleNextStep(selected.listServices.rulesNextStep);
        }
    }, [isClickNextInService, selected.listServices.rulesNextStep]);

    return (
        <Modal
            visible={isShowModalSuggestService}
            className="modal-suggest-service"
            onCancel={() => handleClose(false)}
            onOk={() => handleClose(true)}
            cancelText="Bỏ qua"
            okText="Dùng thêm"
            closable={false}
            width={232}
        >
            <div>
                {satisfyingRule?.listService?.length > 0
                    ? satisfyingRule.listService.map((service: any) => {
                          return (
                              <>
                                  <div className="banner">
                                      <img
                                          src={service.serviceImage[0]}
                                          alt="dich vu"
                                      />
                                  </div>
                                  <div
                                      className="title"
                                      dangerouslySetInnerHTML={{
                                          __html: satisfyingRule.title,
                                      }}
                                  >
                                      {/* {satisfyingRule.title} */}
                                  </div>
                                  <div className="price">
                                      {service?.price}{' '}
                                      {service?.oldPrice !== service.price ? (
                                          <span className="line-through">
                                              {service?.oldPrice}
                                          </span>
                                      ) : null}
                                  </div>
                              </>
                          );
                      })
                    : null}
                <div className="content">
                    <img src={satisfyingRule?.image} alt="" />
                </div>
            </div>
        </Modal>
    );
};

export default ModalSuggestService;
