export function formatPhone(phone) {
    if (!phone) return '';
    let _phone = phone.match(/\d+/g).join('');
    const { length } = _phone;
    const breakPoint = [4, 7];
    if (length > 10) {
        _phone = _phone.substring(0, 10);
    }
    if (length > breakPoint[1]) {
        return `${_phone.substring(0, breakPoint[0])}.${_phone.substring(
            breakPoint[0],
            breakPoint[1],
        )}.${_phone.substring(breakPoint[1], length)}`;
    }
    if (length > breakPoint[0] && length <= breakPoint[1]) {
        return `${_phone.substring(0, breakPoint[0])}.${_phone.substring(
            breakPoint[0],
            length,
        )}`;
    }
    return _phone;
}

export const formatPhone2 = (phone) => {
    let newPhone = '';
    const { length } = phone;
    if (
        phone[0] === '+' &&
        phone[1] === '8' &&
        phone[2] === '4' &&
        length < 13
    ) {
        newPhone = phone.replace('+84', '0');
    }
    if (phone[0] === '8' && phone[1] === '4' && length < 12) {
        newPhone = phone.replace('84', '0');
    }
    if (phone[0] === '0' && phone[2] !== '0' && length < 11) {
        newPhone = phone;
    }
    return newPhone;
};

export const validatePhone = (phone) => {
    // remove +84
    let newPhone = phone.toString().trim().replace('+', '');
    let nationalCode = newPhone.slice(0, 2);
    if (nationalCode === '84')
        newPhone = '0' + newPhone.slice(2, newPhone.length);

    // Validate invalid phone
    if (newPhone.slice(1, 2) === '0') return;
    if (newPhone.length !== 10) return;
    return newPhone;
};

export const validLengthPhone = (phone) => {
    if (!phone || phone.length === 0) return 15;
    let newPhone = phone.toString().trim();
    if (newPhone.slice(0, 3) === '+84') return 12;
    if (newPhone.slice(0, 2) === '84') return 11;
    if (newPhone.slice(0, 1) === '0') return 10;
    return 10;
};

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const currencyFormatVND = (money) => {
    const value = money ? money : '0';
    const formatter = new Intl.NumberFormat('vi-VN', {
        currency: 'VND',
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
};

export const roundDecimal = (num, decimal = 2) => {
    if (!num) return 0;
    // eslint-disable-next-line no-restricted-properties
    const powNumber = Math.pow(10, decimal);
    return Math.ceil(num * powNumber) / powNumber;
};
