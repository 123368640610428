import CustomerInsightClient from './client/SavingLocationClient';

export default {
    fetchUserSpinInputApi(type) {
        return CustomerInsightClient.get(`/api/v1/wheeling/${type}`);
    },
    fetchGiftListApi(type) {
        return CustomerInsightClient.get(`/api/v1/wheeling/gifts/${type}`);
    },
    spinWheelApi(type) {
        return CustomerInsightClient.post(`/api/v1/wheeling/process/${type}`);
    },
};
