/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import API from 'repository';
import { connect, useSelector } from 'react-redux';
import usePrevious from 'hooks/usePrevious';
import trackingConst from 'config/TrackingConst';
import {
    formatPriceServiceToNum,
    formatPriceWithKCharacter,
    removeDuplicateGroupService,
} from 'utils/BookingUltils';
import KEY from '../Const';
import DetailService from './ServiceDetail';
import Services from './ListServices';
import './styles.scss';
import { IListService, IService, IServiceGroup } from '../types/Service';
import { ISelected } from '../types/Selected';
import { fetchCampaign } from '../CampaignScreen/ListCampaignScreen';
import { setChosenServiceWithCampaign } from '../CampaignScreen';

const initListService = () => {
    const list = [];
    for (let i = 0; i < 4; i += 1) {
        const item = {
            serviceGroups: [],
            categoryName: '',
            order: i,
            title: '',
        };
        list.push(item);
    }
    return list;
};

const Service: FC<{
    onChangeChosenService: Function;
    onChangeStep: Function;
    chosenService: IService[];
    selected: ISelected;
    trackingData: any;
    userId: any;
    onChangeListServiceScreen: Function;
    setListCampaign: Function;
    setChosenCampaign: Function;
    gotoCampaignScreen: Function;
    ruleShowBooking: any;
    setPreviOusChosenServices: Function;
    allowChange: boolean;
    setAllowChange: Function;
}> = ({
    onChangeChosenService,
    onChangeStep,
    chosenService = [],
    selected,
    trackingData,
    userId,
    onChangeListServiceScreen,
    setListCampaign,
    setChosenCampaign,
    gotoCampaignScreen,
    ruleShowBooking,
    setPreviOusChosenServices,
    allowChange,
    setAllowChange,
}) => {
    const [layoutDefault, setLayoutDefault] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isGenZMember, setIsGenZMember] = useState<boolean>(false);
    const [showDetailServiceGroup, setShowDetailServiceGroup] = useState<
        IServiceGroup
    >({
        groupId: 1,
        groupName: '',
        services: [],
        upsaleServices: [],
    });
    const [listService, setListService] = useState<IListService[]>(
        initListService,
    );
    const [positionScroll, setPositionScroll] = useState<number>(0);
    const common = useSelector((state: any) => state.common);

    const { step } = selected;
    const prevStep = usePrevious(step);

    async function postTracking(data: any) {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    }

    const getListServiceScreen = (
        list: any,
        chosenCampaign = selected.chosenCampaign,
    ) => {
        return list.map((category: any) => {
            return {
                ...category,
                serviceGroups: category.serviceGroups.map((svGroup: any) => {
                    const newListService = svGroup.services.map(
                        (service: any) => {
                            let campaignIdApply = null;
                            let newService = { ...service };
                            selected[KEY.LIST_SERVICES].rulesSpecialDay.map(
                                (rules: any) => {
                                    const isRuleDate = rules?.date?.includes(
                                        selected[KEY.DATE_BOOK],
                                    );
                                    if (isRuleDate) {
                                        if (
                                            rules?.serviceSpecial &&
                                            service.serviceId ===
                                                rules?.serviceId
                                        ) {
                                            newService = {
                                                ...rules.serviceSpecial,
                                                isDefaultService:
                                                    service.isDefaultService,
                                            };
                                        } else if (
                                            rules?.serviceSpecialStylist &&
                                            rules?.serviceStylist?.serviceId ===
                                                service.serviceId
                                        ) {
                                            newService = {
                                                ...rules.serviceSpecialStylist,
                                                isDefaultService:
                                                    service.isDefaultService,
                                            };
                                        }
                                    }
                                },
                            );
                            let newPrice = newService.price;
                            let newOldPrice = newService.oldPrice;
                            if (chosenCampaign.length) {
                                chosenCampaign.map((campaign) => {
                                    if (
                                        campaign.serviceApplies &&
                                        campaign.serviceApplies.length &&
                                        campaign.valid
                                    ) {
                                        campaign.serviceApplies.map(
                                            (serviceCampaign) => {
                                                if (
                                                    newService.serviceId ===
                                                    serviceCampaign.id
                                                ) {
                                                    const priceService = formatPriceServiceToNum(
                                                        newService.price || '0',
                                                    );
                                                    if (
                                                        priceService >
                                                        serviceCampaign.price
                                                    ) {
                                                        newPrice = formatPriceWithKCharacter(
                                                            serviceCampaign.price,
                                                        );
                                                        newOldPrice =
                                                            serviceCampaign.originPrice >
                                                            0
                                                                ? formatPriceWithKCharacter(
                                                                      serviceCampaign.originPrice,
                                                                  )
                                                                : service?.price ||
                                                                  '0';
                                                        campaignIdApply =
                                                            campaign.campaignId;
                                                    }
                                                }
                                            },
                                        );
                                    }
                                });
                            }
                            return {
                                ...newService,
                                price: newPrice,
                                oldPrice: newOldPrice,
                                campaignId: campaignIdApply,
                                serviceImage: newService?.serviceImage
                                    ? newService.serviceImage
                                    : service?.serviceImage
                                    ? service.serviceImage
                                    : [],
                            };
                        },
                    );
                    return {
                        ...svGroup,
                        services: newListService,
                    };
                }),
            };
        });
    };

    const fetchCampaignOnChangeService = async (selectedService: any) => {
        try {
            const data: ListCampaignType = await fetchCampaign(
                selectedService,
                selected.chosenCampaign,
                selected.customerPhone,
                selected.salon.id,
            );
            if (data) {
                const newData = [...data.salon, ...data.customer];
                const chosenCampaignIds = selected.chosenCampaign.map(
                    (item) => item.campaignId,
                );
                const newChosenCampaign = newData.filter((item) =>
                    chosenCampaignIds.includes(item.campaignId),
                );
                const newSelectedService = setChosenServiceWithCampaign(
                    selectedService,
                    newChosenCampaign,
                    selected.listAllService,
                );
                setChosenCampaign(newChosenCampaign);
                onChangeChosenService(newSelectedService);
                if (selected[KEY.LIST_SERVICES].data) {
                    const newListServiceScreen = getListServiceScreen(
                        selected[KEY.LIST_SERVICES].data,
                        newChosenCampaign,
                    );
                    setListService(newListServiceScreen);
                }
            }
            setListCampaign(data);
        } catch (error) {}
    };

    const onSelectServiceDetailScreen = (newSelectedService: any) => {
        onChangeChosenService(newSelectedService);
        fetchCampaignOnChangeService(newSelectedService);
        const dataTracking: any = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_DETAIL_SERVICE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            eventId: trackingConst.Events.CLICK_SELECT_SERVICE,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(newSelectedService),
                customerId: userId || 0,
            },
        };
        postTracking(dataTracking);
    };

    const onSelectService = async (
        service: any,
        checked: boolean,
        isScreenIdServices = false,
    ) => {
        const {
            listService: listSubService,
            ...serviceWithoutSubService
        } = service;
        let newSelectedService = [];

        if (checked) {
            let listServiceWithOutDuplicate = removeDuplicateGroupService(
                chosenService,
                service,
            );
            // add parent service
            if (service.parentServiceId) {
                const hasExistParent =
                    listServiceWithOutDuplicate.findIndex(
                        (serv: any) =>
                            serv.serviceId === service.parentServiceId,
                    ) !== -1;
                if (!hasExistParent) {
                    listService.forEach((category: any) => {
                        category.services.forEach((item: any) => {
                            if (item.serviceId === service.parentServiceId) {
                                const {
                                    listService,
                                    ...serviceWithoutSub
                                } = item;
                                listServiceWithOutDuplicate = removeDuplicateGroupService(
                                    listServiceWithOutDuplicate,
                                    serviceWithoutSub,
                                );
                                listServiceWithOutDuplicate.push(
                                    serviceWithoutSub,
                                );
                            }
                        });
                    });
                }
            }
            const listServiceWithOutDuplicateGroup = [
                ...listServiceWithOutDuplicate,
            ].filter((sv: any) => service.groupId !== sv.groupId);

            newSelectedService = [
                ...listServiceWithOutDuplicateGroup,
                serviceWithoutSubService,
            ];
        } else {
            newSelectedService = chosenService.filter(
                (selectedService) =>
                    selectedService.serviceId !== service.serviceId,
            );
            if (listSubService && listSubService.length > 0) {
                newSelectedService = newSelectedService.filter(
                    (selectedService) =>
                        selectedService.parentServiceId !== service.serviceId,
                );
            }
        }
        onChangeChosenService(newSelectedService);
        fetchCampaignOnChangeService(newSelectedService);
        // tracking
        const dataTracking: any = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: isScreenIdServices
                ? trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN
                : trackingConst.Screens.BOOKING_SELECT_DETAIL_SERVICE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            eventId: checked
                ? trackingConst.Events.CLICK_SELECT_SERVICE
                : trackingConst.Events.CLICK_REMOVE_SERVICE,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(newSelectedService),
                customerId: userId || 0,
            },
            extendData: {
                serviceId: service.serviceId.toString(),
            },
        };
        if (
            Object.prototype.hasOwnProperty.call(service, 'campaignId') &&
            service.campaignId
        ) {
            dataTracking.data.campaignId = service.campaignId;
        }

        postTracking(dataTracking);
    };

    const getData = (list: any) => {
        if (list.data) {
            setIsLoading(false);
            const listCategoryOder = list.data.sort(
                (catA: any, catB: any) => catA.order - catB.order,
            );
            setListService(listCategoryOder);

            const listNewServices = getListServiceScreen(list.data);
            setListService(listNewServices);

            if (chosenService.length === 0) {
                const defaultSelectedServices: any = [];

                listNewServices.forEach((category: any) => {
                    category.serviceGroups.forEach((svGroup: any) => {
                        svGroup.services.forEach((service: any) => {
                            if (service.selected === 1) {
                                const {
                                    listService,
                                    ...serviceWithoutSubService
                                } = service;
                                defaultSelectedServices.push(
                                    serviceWithoutSubService,
                                );
                            }
                            if (
                                service.listService &&
                                service.listService.length > 0
                            ) {
                                service.listService.forEach((service: any) => {
                                    if (service.selected === 1)
                                        defaultSelectedServices.push(service);
                                });
                            }
                        });
                    });
                });
                onChangeChosenService(defaultSelectedServices);
            } else {
                const defaultSelectedServices: any = [];

                const chosenServiceIds = chosenService.map(
                    (item) => item.serviceId,
                );

                listNewServices.forEach((category: any) => {
                    category.serviceGroups.forEach((svGroup: any) => {
                        svGroup.services.forEach((service: any) => {
                            if (chosenServiceIds.includes(service.serviceId)) {
                                defaultSelectedServices.push(service);
                            }
                        });
                    });
                });
                onChangeChosenService(defaultSelectedServices);
            }
        }
        setIsGenZMember(list.isAlertCampaign || false);
    };

    useEffect(() => {
        if (selected[KEY.LIST_SERVICES]) {
            getData(selected[KEY.LIST_SERVICES]);
        }
    }, [selected[KEY.LIST_SERVICES]]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setPreviOusChosenServices();
    }, []);

    useEffect(() => {
        if (prevStep === 3 && step === 2) {
            window.scrollTo({ top: positionScroll });
        }
    }, [step]);

    return (
        <>
            {step === 3 ? (
                <DetailService
                    serviceGroup={showDetailServiceGroup}
                    setService={setShowDetailServiceGroup}
                    onChangeStep={onChangeStep}
                    onSelectService={(newSelectedService: IService[]) =>
                        onSelectServiceDetailScreen(newSelectedService)
                    }
                    selected={selected}
                />
            ) : step === 2 ? (
                <Services
                    selected={selected}
                    listService={listService}
                    setShowDetailService={setShowDetailServiceGroup}
                    onSelectService={onSelectService}
                    isLoading={isLoading}
                    isGenZMember={isGenZMember}
                    trackingData={trackingData}
                    userId={userId}
                    onChangeStep={onChangeStep}
                    setPositionScroll={setPositionScroll}
                    gotoCampaignScreen={() => gotoCampaignScreen()}
                    ruleShowBooking={ruleShowBooking}
                    layoutDefault={layoutDefault}
                    setLayoutDefault={setLayoutDefault}
                    setAllowChange={setAllowChange}
                    allowChange={allowChange}
                />
            ) : null}
        </>
    );
};
function mapStateToProps(state: any) {
    const { trackingData, user } = state;
    return {
        trackingData,
        userPhone: user.phoneNumber,
        userId: user.userId,
    };
}
export default connect(mapStateToProps)(Service);
