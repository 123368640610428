import React, { FC, useState, useEffect, createRef } from 'react';
import usePrevious from 'hooks/usePrevious';
import { diffTimeToday } from 'utils/BookingUltils';
import API from 'repository';
import moment from 'moment';
import 'moment/locale/vi';
import { capitalizeFirstLetter } from 'utils/NumberUtils';
import trackingConst from 'config/TrackingConst';
import iconMemberSilver from 'images/member_silver.png';
import iconMemberGold from 'images/member_gold.png';
import iconMemberDiamond from 'images/member_diamond.png';
import BoxShadow from 'components/BoxShadown/BoxShadown';
import KEY from '../../Const';
import { IDatebook } from '../type';
import Selected from '../components/Selected';
import { ISelected } from '../../types/Selected';

const TODAY = moment().format('DD-MM-YYYY');

const listToDay = (
    dateBook: string,
    daysBookFuture: number,
    bookDates: any,
) => {
    const iconMember = (index: number) => {
        switch (index) {
            case 3:
                return iconMemberSilver;
            case 4:
                return iconMemberGold;
            case 5:
            case 6:
                return iconMemberDiamond;
            default:
                return null;
        }
    };
    let list: any[] = [];
    if (bookDates?.length > 0) {
        console.log('bookDates', bookDates);
        list = bookDates.map((m: any, index: number) => {
            let dateDiff: any = 0;
            if (diffTimeToday(m.date)) {
                dateDiff = diffTimeToday(m.date);
            }
            return {
                day: m.date,
                name:
                    dateDiff === 0
                        ? 'Hôm nay'
                        : dateDiff === 1
                        ? 'Ngày mai'
                        : dateDiff === 2
                        ? 'Ngày kia'
                        : '',
                th: capitalizeFirstLetter(
                    moment(m.date, 'DD-MM-YYYY').format('ddd'),
                ),
                isActive: index + 1 <= daysBookFuture,
                iconMember: iconMember(index),
                tag: m.tag,
                isSpecial: m.isSpecial,
            };
        });
    } else {
        list = [
            {
                day: dateBook,
                name: 'Hôm nay',
                th: capitalizeFirstLetter(
                    moment(dateBook, 'DD-MM-YYYY').format('ddd'),
                ),
                isActive: true,
                iconMember: iconMember(0),
                tag: '',
                isSpecial: false,
            },
        ];
    }
    // filter loai bo ngay theo member
    return list.slice(0, 3);
};

const useOutside = (ref: any, callBackFunction: Function) => {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callBackFunction();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

const DateBook: FC<{
    selected: ISelected;
    dateBook: string;
    salonId: number;
    stylistId: number;
    chosenService: any[];
    onChangeDay: Function;
    role: any;
    isStylistBusy: any;
    isSalonBusy: any;
    isLoadingHour: boolean;
    setHasClickNextInSalon: any;
    setHasClickInService: any;
    trackingData: any;
    isShow: boolean;
    setIsShow: Function;
    isBookStylistMaster: boolean;
    changeServiceWithChangeStylistDateBook: Function;
    allowChange: boolean;
}> = ({
    onChangeDay,
    role,
    dateBook,
    isStylistBusy,
    isSalonBusy,
    isLoadingHour,
    salonId,
    setHasClickNextInSalon,
    trackingData,
    stylistId,
    chosenService,
    setHasClickInService,
    selected,
    isShow,
    setIsShow,
    isBookStylistMaster,
    changeServiceWithChangeStylistDateBook,
    allowChange,
}) => {
    const [dateTime, setDateTime] = useState<IDatebook[]>([]);
    const [date, setDate] = useState<any>({});
    const [isShowNote, setIsShowNote] = useState<boolean>(true);
    const refDiv = createRef<HTMLDivElement>();
    const prevDateBook: any = usePrevious(date);

    useOutside(refDiv, () => {
        if (isShow) setIsShow(false);
    });

    const postTracking = async (data: any) => {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const dataTracking = {
        pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
        screenId: trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
        eventId: trackingConst.Events.CLICK_SELECT_DATEBOOK,
        tokenKey: trackingData.token,
        source: trackingData.source,
        utmSource: trackingData.utmSource,
        data: {
            customerId: trackingData.customerId || 0,
            phone: selected[KEY.PHONE],
            salonId,
            chosenServices: JSON.stringify(chosenService),
            dateBook,
            stylistId,
            hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
            hourBook: selected[KEY.HOUR].hour,
        },
    };

    const trackingClickDateBook = () => {
        postTracking({
            ...dataTracking,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
        });
    };

    const handleDate = (event: any, item: any) => {
        trackingClickDateBook();
        if (dateBook !== item.day && item.isActive) {
            setIsShow(false);
            onChangeDay(item);
        } else if (!item.isActive) {
            event.stopPropagation();
        }
    };

    const TextDate = (item: any) => {
        return (
            <div className="flex w-full space-between item-center">
                <div>
                    {item.name && <span>{item.name}, </span>}
                    <span className="">{item.th} </span>
                    {item.day && (
                        <>({item.day.slice(0, 5).replace('-', '/')})</>
                    )}
                </div>
                {item.tag && (
                    <div
                        className={`text-center w-24 py-0.5 px-2 mr-1.5 text-xs text-white rounded ${
                            item.isSpecial ? 'bg-red-fd8b7c' : 'bg-green-11b14b'
                        }`}
                    >
                        {item.tag}
                    </div>
                )}
            </div>
        );
    };

    const renderNoteChangePrice = () => {
        return (
            selected.chosenServiceWeekend.length > 0 &&
            selected.chosenServiceWeekend.map((item) => (
                <div
                    key={item.serviceId}
                    className={`mt-2.5 text-sm ${
                        isBookStylistMaster || date.isSpecial
                            ? 'color-red-f46969'
                            : 'text-gray-500'
                    }`}
                >
                    {isBookStylistMaster ? (
                        <>
                            <span className="bold">
                                {selected.stylist.name}
                            </span>{' '}
                            là Stylist Master chỉ phục vụ tối đa 12 khách/ngày,
                            giá
                        </>
                    ) : (
                        <>*Giá </>
                    )}
                    {item.name} {date.tag ? date.tag.toLocaleLowerCase() : ''}{' '}
                    là {item.price}
                </div>
            ))
        );
    };

    const renderNoteSalonBusy = () => {
        if (!isLoadingHour && isSalonBusy < 0 && isStylistBusy > 0) {
            return (
                <div className="color-red-f46969 text-xs mt-1.5">
                    {date.name || date.th} salon hết lịch mất rồi, bạn chọn giúp
                    chúng mình ngày khác hoặc salon khác nhé!
                </div>
            );
        }
        return '';
    };

    // pending
    const renderIcon = (date: any) => {
        if (date && date.iconMember) {
            return (
                <div style={{ marginRight: '5px' }}>
                    <img
                        src={date.iconMember}
                        alt=""
                        className="w-[28px] h-auto"
                    />
                </div>
            );
        }
        return <div style={{ width: '35px' }} />;
    };

    const renderDropdownDate = () => {
        let eventId = isShow
            ? trackingConst.Events.CLICK_CLOSE_DATEBOOK
            : trackingConst.Events.CLICK_SHOW_DATEBOOK;
        if (salonId === 0) {
            setIsShow(false);
            setHasClickNextInSalon(0);
            eventId = trackingConst.Events.CLICK_BUTTON_DATEBOOK;
        } else {
            setHasClickNextInSalon(1);
            if (chosenService.length > 0 || allowChange) {
                setHasClickInService(1);
                setIsShow(!isShow);
            } else {
                setHasClickInService(0);
                eventId = trackingConst.Events.CLICK_BUTTON_DATEBOOK;
            }
        }
        postTracking({ ...dataTracking, eventId });
    };

    useEffect(() => {
        // Show note change price
        const isDateBookInWeekend = date.isSpecial;
        const isPrevDateBookInWeekend = prevDateBook
            ? prevDateBook.isSpecial
            : false;

        setIsShowNote(
            (isDateBookInWeekend ||
                (!isDateBookInWeekend && isPrevDateBookInWeekend) ||
                isBookStylistMaster) &&
                selected.chosenServiceWeekend.length > 0 &&
                selected.chosenService.length > 0,
        );
    }, [date, isBookStylistMaster, selected.chosenServiceWeekend]);

    useEffect(() => {
        const newDateTime = listToDay(
            dateBook,
            role.daysBookFuture,
            role.bookDates,
        );
        setDateTime(newDateTime);
    }, [role]);

    useEffect(() => {
        if (dateTime.length) {
            dateTime.forEach((item) => {
                if (dateBook === item.day) {
                    setDate(item);
                }
            });
        }
    }, [dateTime, dateBook]);

    useEffect(() => {
        changeServiceWithChangeStylistDateBook(
            selected.listServices,
            selected.chosenService,
            dateBook,
            null,
        );
    }, [dateBook]);

    return (
        <div className="block__box">
            <div className="relative" id="datebookId" ref={refDiv}>
                <Selected
                    type="datebook"
                    value={date ? TextDate(date) : ''}
                    placeholder="Xem tất cả dịch vụ hấp dẫn"
                    message=""
                    onHandleClick={() => renderDropdownDate()}
                    required={false}
                    show={isShow}
                />
                <div
                    className={`filter drop-shadow bg-white absolute top-11 w-full z-20 opacity-0 ${
                        isShow ? 'opacity-100 fade-in' : ''
                    }`}
                >
                    <BoxShadow isShow={isShow}>
                        {dateTime.length > 0 &&
                            dateTime.map((item, key) => {
                                return (
                                    <div
                                        key={`${key + 1}`}
                                        className={`${
                                            item.isActive
                                                ? 'border-b border-gray-e8 cursor-pointer'
                                                : 'border-b border-white cursor-not-allowed color-a3a3a3 bg-gray-e8'
                                        } text-sm flex py-3 pl-1.5 pr-3.5`}
                                        onClick={(event) =>
                                            handleDate(event, item)
                                        }
                                        role="presentation"
                                    >
                                        {renderIcon(item)}
                                        {TextDate(item)}
                                    </div>
                                );
                            })}
                    </BoxShadow>
                </div>
                {dateBook !== TODAY && renderNoteSalonBusy()}
            </div>
            {isShowNote && renderNoteChangePrice()}
        </div>
    );
};
export default DateBook;
