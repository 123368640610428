import React, { FC, useEffect, useState } from 'react';
import { ENUM_BACKGROUND_TAG_SERVICE } from 'utils/BookingUltils';
import iconChecked from 'images/icons/icon_checked_blue.svg';
import iconTime from 'images/icons/icon_time.svg';
import iconInfo from 'images/icons/ic_info.svg';
import iconTick from 'images/icons/ic_tick.svg';
import api from 'repository';
import trackingConst from 'config/TrackingConst';
import KEY from '../../Const';
import { IService, IServiceGroup } from '../../types/Service';
import { ISelected } from '../../types/Selected';
import { useSelector } from 'react-redux';

const ItemServices: FC<{
    serviceGroup: IServiceGroup;
    chosenService: IService[];
    onSelectService: Function;
    trackingData: any;
    selected: ISelected;
    userId: any;
    onClickDetailService: Function;
    layoutDefault: boolean;
}> = ({
    serviceGroup,
    chosenService,
    trackingData,
    selected,
    userId,
    onSelectService,
    onClickDetailService,
    layoutDefault,
}) => {
    const common = useSelector((state: any) => state.common);
    const [serviceActive, setServiceActive] = useState<IService | null>();
    const [
        serviceActiveInit,
        setServiceActiveInit,
    ] = useState<IService | null>();
    const [showError, setShowError] = useState(false);

    const postTracking = async (data: any) => {
        try {
            await api.postTrackingDynamic(data);
        } catch (e) {
            console.log(e);
            throw e;
        }
    };

    const handleClickDetailService = (service: any) => {
        if (serviceActiveInit?.hasDetail) {
            onClickDetailService(service);
        }
        // tracking
        const dataTracking: any = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            eventId: serviceActiveInit?.hasDetail
                ? trackingConst.Events.CLICK_SERVICE_HAS_DETAIL
                : trackingConst.Events.CLICK_SERVICE_NO_HAS_DETAIL,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(chosenService),
                customerId: userId || 0,
            },
            extendData: {
                groupId: service.groupId.toString(),
            },
        };
        if (
            Object.prototype.hasOwnProperty.call(service, 'campaignId') &&
            service.campaignId
        ) {
            dataTracking.data.campaignId = service.campaignId;
        }

        postTracking(dataTracking);
    };

    const onErrorImage = (e: any) => {
        e.target.src =
            'https://storage.30shine.com/ResourceWeb/data/images/Booking/booking-bạn-mac-dinh.jpg';
    };

    const getValueCheckGroupBox = (svGroup: IServiceGroup) => {
        return (
            chosenService.findIndex((service) =>
                svGroup.services
                    .map((item) => item.serviceId)
                    .includes(service.serviceId),
            ) >= 0
        );
    };
    const checkedGroup = getValueCheckGroupBox(serviceGroup);

    const onClickSelectedService = () => {
        if (checkedGroup) setServiceActive(null);
        if (serviceGroup?.services.length === 1) {
            if (!checkedGroup) setServiceActive(serviceGroup?.services[0]);
            onSelectService(serviceGroup?.services[0], !checkedGroup);
        } else {
            if (!serviceActive) {
                setShowError(true);
                return;
            }
            onSelectService(serviceActive, !checkedGroup);
        }
    };

    const getColorTagService = (index: number) => {
        switch (index) {
            case 0: {
                const color = ENUM_BACKGROUND_TAG_SERVICE.find(
                    (cate) => cate.categoryId === serviceActiveInit?.categoryId,
                );
                return color
                    ? `${color.background} ${color.color}`
                    : 'bg-[#FFE985] text-[#222]';
            }
            case 1:
                if (serviceActiveInit?.used === 1) {
                    // lần trước
                    if (serviceActiveInit?.selected === 1)
                        return 'bg-[#11B14B] text-[#FFF]';

                    // từng dùng
                    return 'bg-[#F4933C] text-[#FFF]';
                }
                return 'bg-[#11B14B] text-[#F0F8FF]';
            default:
                return 'bg-[#FFE985] text-[#222]';
        }
    };

    const activeGroupService = (service: IService) => {
        return (
            serviceActive?.serviceId === service.serviceId &&
            serviceGroup.services.length > 1
        );
    };

    const onHandleClickSelectedServiceVariant = (service: IService) => {
        const chosenVariant = chosenService.find(
            (serviceItem) => serviceItem.serviceId === service.serviceId,
        );
        if (serviceActive?.serviceId === service.serviceId) {
            setServiceActive(null);
        } else setServiceActive(service);
        onSelectService(service, chosenVariant === undefined, false);
    };

    useEffect(() => {
        //  lưu lại dv được set default từ api để lấy ảnh, tiêu đề
        const svDefault = serviceGroup.services.find(
            (sv) => sv.isDefaultService,
        );
        if (svDefault) {
            setServiceActiveInit(svDefault);
        }
    }, [serviceGroup]);

    useEffect(() => {
        if (chosenService.length) {
            const serviceGroupId = serviceGroup.services.map(
                (item) => item.serviceId,
            );
            const newServiceActive = chosenService.find((service) =>
                serviceGroupId.includes(service.serviceId),
            );

            if (newServiceActive) {
                setServiceActive(newServiceActive);
            } else {
                setServiceActive(null);
            }
        }
    }, [chosenService, serviceGroup]);

    useEffect(() => {
        if (serviceActive) {
            setShowError(false);
        }
    }, [serviceActive]);

    return (
        <div className="h-full flex flex-col gap-1.5 rounded-[10px] overflow-hidden pb-[14px] shadow-[0px_2px_10px_0px_rgba(0,0,0,0.1)] ">
            <div
                onClick={() => handleClickDetailService(serviceGroup)}
                role="presentation"
                className={`service__image relative ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
            >
                <img
                    src={
                        serviceActiveInit?.serviceImage?.length
                            ? serviceActiveInit?.serviceImage[0]
                            : ''
                    }
                    alt={serviceActiveInit?.name}
                    onError={(e) => {
                        onErrorImage(e);
                    }}
                    className="w-full"
                />
                {serviceActiveInit?.hasDetail ? (
                    <img
                        src={iconInfo}
                        alt={serviceActiveInit?.name}
                        className="absolute top-1 right-1 lg:hover:invert rounded-full cursor-pointer w-[26px] h-[26px]"
                    />
                ) : (
                    ''
                )}
            </div>
            <div
                className={`flex items-baseline justify-between gap-x-[3px] px-[5px] ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleClickDetailService(serviceGroup)}
                role="presentation"
            >
                <div className="text-[#222222] font-semibold line-clamp-2 pr-2 md:pr-4 text-sm">
                    {serviceActiveInit?.name}
                </div>

                <div className="flex-none flex items-center text-sm text-[#222222]">
                    <img src={iconTime} alt="" className="mr-1" />
                    {serviceActiveInit?.timeServe}
                </div>
            </div>

            <div
                className={`text-[#5F5F5F] text-xs line-clamp-3 min-h-[32px] px-[5px] ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleClickDetailService(serviceActiveInit)}
                role="presentation"
            >
                {serviceActive?.subtitle || serviceActiveInit?.subtitle}
            </div>
            <div
                className={`mt-auto flex flex-wrap gap-[5px] px-[5px] min-h-[24px] ${
                    serviceActiveInit?.hasDetail ? 'cursor-pointer' : ''
                }`}
                onClick={() => handleClickDetailService(serviceGroup)}
                role="presentation"
            >
                {serviceActiveInit?.tags && serviceActiveInit?.tags?.length
                    ? serviceActiveInit.tags.map((tag, index) => (
                          <div
                              key={tag}
                              className={`rounded-[3px] ${getColorTagService(
                                  index,
                              )} text-xs py-1 px-[4px]`}
                          >
                              {tag}
                          </div>
                      ))
                    : null}
            </div>

            <div className="px-[5px] flex gap-[5px]">
                {serviceGroup?.services.map((service) => (
                    <div
                        className={`flex-1 flex flex-col relative rounded-[5px] py-1 cursor-pointer ${
                            activeGroupService(service)
                                ? 'bg-[#FFF9FC]'
                                : 'lg:hover:bg-[#FFF9FC]'
                        }
                        ${
                            serviceGroup?.services.length === 1
                                ? ''
                                : 'border border-[#EF4E24]'
                        }
                        ${showError ? ' border border-animation' : ''}
                        `}
                        onClick={() =>
                            onHandleClickSelectedServiceVariant(service)
                        }
                        aria-hidden="true"
                        key={service.serviceId}
                    >
                        <div
                            className={`px-2 lg:px-3 text-[#222222] text-[10px] lg:text-xs font-semibold text-center line-clamp-1 ${
                                activeGroupService(service) ? 'pl-3' : ''
                            }`}
                        >
                            {service.versionName}
                        </div>

                        <img
                            src={iconTick}
                            alt=""
                            className={`absolute top-[-1px] left-[-1px] transition-all rounded-l-[5px] w-[20px] lg:w-[24px] ${
                                activeGroupService(service)
                                    ? 'opacity-1'
                                    : 'opacity-0'
                            }`}
                        />
                        <div className="mt-auto text-sm font-semibold text-[#3D3D3D] text-center">
                            {service?.price ? service.price : ''}{' '}
                            {service?.oldPrice !== service?.price ? (
                                <span className="font-semibold text-[#5F5F5F] text-[#3D3D3D] line-through pl-1 lg:pl-2.5">
                                    {service?.oldPrice}
                                </span>
                            ) : null}
                        </div>
                    </div>
                ))}
            </div>
            <div
                className={`mx-[5px] cursor-pointer flex items-center justify-center border border-[#EF4E24] rounded-[5px] transition-all py-2 px-4 text-sm font-semibold ${
                    checkedGroup
                        ? 'bg-[#FFF9FC] text-[#EF4E24] lg:hover:bg-white '
                        : 'bg-[#EF4E24] text-white lg:hover:bg-[#FFF9FC] lg:hover:text-[#EF4E24]'
                }`}
                onClick={() => onClickSelectedService()}
                role="presentation"
            >
                <img
                    src={iconChecked}
                    alt="icon"
                    className={`w-[13px] mr-1.5 ${
                        checkedGroup ? 'block' : 'hidden'
                    }`}
                />
                {checkedGroup
                    ? common.lang === 'vi'
                        ? 'Đã thêm'
                        : 'Added'
                    : common.lang === 'vi'
                    ? 'Thêm dịch vụ'
                    : 'Add'}
            </div>
        </div>
    );
};

export default ItemServices;
