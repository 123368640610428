import Client from './client/SearchAddressClient';

const resources = 'api';

export default {
    searchAddress(keyword) {
        return Client.get(
            `${resources}/appcustomer/location/autocomplete?input=${keyword}`,
        );
    },
    getSearchAddressLocation(placeId){
        return Client.get(
            `${resources}/appcustomer/location/address-by-placeid?placeid=${placeId}`,
        );
    }
};
