import React, { FC, useState, useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import useWindowSize from 'hooks/useWindowSize';
import classNames from 'classnames';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import './index.scss';
import '../index.scss';
import LayoutHeadTitle from '../headTitle';

const LayoutMedia: FC<{ data: any }> = ({ data }) => {
    const size: any = useWindowSize();
    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState<boolean>(true);
    const [isLastSlide, setIsLastSlide] = useState<boolean>(false);
    const swiperSlide = useRef<any>(null);
    const [isFirstSiwperSlide, setIsFirstSiwperSlide] = useState<boolean>(
        false,
    );
    const [isLastSiwperSlide, setIsLastSiwperSlide] = useState<boolean>(false);
    const [activeIndexSlide, setActiveIndexSlide] = useState<number>(-1);
    const isMobile = size.width < 768;
    const listMedia = data.list;

    const goNext = (ref: any, lastSlide: Function, firstSlide: Function) => {
        if (ref.current && ref.current.swiper) {
            ref.current.swiper.slideNext();
            firstSlide(ref.current.swiper.isBeginning);
            lastSlide(ref.current.swiper.isEnd);
        }
    };

    const goPrev = (ref: any, lastSlide: Function, firstSlide: Function) => {
        if (ref.current && ref.current.swiper) {
            ref.current.swiper.slidePrev();
            firstSlide(ref.current.swiper.isBeginning);
            lastSlide(ref.current.swiper.isEnd);
        }
    };

    const handleSlideChange = (
        ref: any,
        lastSlide: Function,
        firstSlide: Function,
    ) => {
        lastSlide(ref.current.swiper.isEnd);
        firstSlide(ref.current.swiper.isBeginning);
    };

    const classButtonPrev = (isFirst: boolean) => {
        return classNames('swiper_button button_prev', {
            swiper_button_disable: isFirst,
        });
    };
    const classButtonNext = (isLast: boolean) => {
        return classNames('swiper_button button_next', {
            swiper_button_disable: isLast,
        });
    };

    const handleClickImg = (index: number) => {
        setActiveIndexSlide(index);
    };

    useEffect(() => {
        if (activeIndexSlide >= 0 && swiperRef.current && swiperSlide.current) {
            swiperSlide.current.swiper.slideTo(activeIndexSlide, 0);
        }
        if (activeIndexSlide === 0) {
            setIsFirstSiwperSlide(true);
        } else {
            setIsFirstSiwperSlide(false);
        }
        if (activeIndexSlide === listMedia.length - 1) {
            setIsLastSiwperSlide(true);
        } else {
            setIsLastSiwperSlide(false);
        }
    }, [activeIndexSlide]);

    const paramsSwiper = {
        spaceBetween: 30,
        breakpoints: {
            768: {
                slidesPerView:
                    data.slidePerViewPC > 0 ? data.slidePerViewPC : 4,
                spaceBetween: 30,
            },
            0: {
                slidesPerView:
                    data.slidePerViewMB > 0 ? data.slidePerViewMB : 2.3,
                spaceBetween: 16,
            },
        },
        on: {
            slideChange: () =>
                handleSlideChange(swiperRef, setIsLastSlide, setIsFirstSlide),
            touchMove: () =>
                handleSlideChange(swiperRef, setIsLastSlide, setIsFirstSlide),
            init: () =>
                handleSlideChange(swiperRef, setIsLastSlide, setIsFirstSlide),
        },
        draggable: true,
    };
    const paramsSwiperSlide = {
        spaceBetween: 10,
        slidesPerView: 1,
        on: {
            slideChange: () =>
                handleSlideChange(
                    swiperSlide,
                    setIsLastSiwperSlide,
                    setIsFirstSiwperSlide,
                ),
            touchMove: () =>
                handleSlideChange(
                    swiperSlide,
                    setIsLastSiwperSlide,
                    setIsFirstSiwperSlide,
                ),
            init: () =>
                handleSlideChange(
                    swiperSlide,
                    setIsLastSiwperSlide,
                    setIsFirstSiwperSlide,
                ),
        },
        draggable: true,
    };

    const isShowButtonSwiper =
        data.list.length > Math.round(data.slidePerViewPC);

    return (
        <div className="layout layout-media">
            <div className="container">
                <LayoutHeadTitle data={data} />

                {data.list.length > 0 && (
                    <div className="media__wrapper relative">
                        <Swiper {...paramsSwiper} ref={swiperRef}>
                            {data.list.map((item: any, index: number) => (
                                <div
                                    className="pointer"
                                    key={item.id}
                                    onClick={() => handleClickImg(index)}
                                >
                                    <div className="wrapper__img pointer">
                                        <img
                                            src={
                                                isMobile && item.linkImageMb
                                                    ? item.linkImageMb
                                                    : item.linkImage
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                            ))}
                        </Swiper>
                        {isShowButtonSwiper && (
                            <>
                                <Button
                                    className={classButtonPrev(isFirstSlide)}
                                    shape="circle"
                                    disabled={isFirstSlide}
                                    icon={
                                        <LeftOutlined className="swiper_button_icon" />
                                    }
                                    onClick={() =>
                                        goPrev(
                                            swiperRef,
                                            setIsLastSlide,
                                            setIsFirstSlide,
                                        )
                                    }
                                />
                                <Button
                                    className={classButtonNext(isLastSlide)}
                                    shape="circle"
                                    disabled={isLastSlide}
                                    icon={
                                        <RightOutlined className="swiper_button_icon" />
                                    }
                                    onClick={() =>
                                        goNext(
                                            swiperRef,
                                            setIsLastSlide,
                                            setIsFirstSlide,
                                        )
                                    }
                                />
                            </>
                        )}
                    </div>
                )}
                <Modal
                    visible={activeIndexSlide >= 0}
                    centered
                    onCancel={() => setActiveIndexSlide(-1)}
                    footer={null}
                    width={size.width > 768 ? '40%' : '95%'}
                    wrapClassName="media__modal"
                >
                    {listMedia.length > 0 && (
                        <Swiper {...paramsSwiperSlide} ref={swiperSlide}>
                            {listMedia.map((item: any) => (
                                <div className="pointer" key={item.id}>
                                    <div className="wrapper__img pointer">
                                        <img
                                            src={
                                                item.linkMedia || item.linkImage
                                            }
                                            alt=""
                                        />
                                    </div>
                                </div>
                            ))}
                        </Swiper>
                    )}
                    <Button
                        className={classButtonPrev(isFirstSiwperSlide)}
                        shape="circle"
                        icon={<LeftOutlined className="swiper_button_icon" />}
                        onClick={() =>
                            goPrev(
                                swiperSlide,
                                setIsLastSiwperSlide,
                                setIsFirstSiwperSlide,
                            )
                        }
                    />
                    <Button
                        className={classButtonNext(isLastSiwperSlide)}
                        shape="circle"
                        icon={<RightOutlined className="swiper_button_icon" />}
                        onClick={() =>
                            goNext(
                                swiperSlide,
                                setIsLastSiwperSlide,
                                setIsFirstSiwperSlide,
                            )
                        }
                    />
                </Modal>
            </div>
        </div>
    );
};

export default LayoutMedia;
