import {
    COMMON_ADD_LOADING,
    COMMON_REMOVE_LOADING,
    COMMON_SET_SPIN,
    COMMON_SET_SHOW_MODAL_LOGIN,
    COMMON_SET_TITLE_MODAL_LOGIN,
    COMMON_SET_IS_CLICK_MENU_BOOKED,
    COMMON_SET_IS_INAPP,
    COMMON_SET_LANGUAGE,
} from '../actionTypes/common';

export type addLoadingAction = {
    type: typeof COMMON_ADD_LOADING;
};

export type removeLoadingAction = {
    type: typeof COMMON_REMOVE_LOADING;
};

export type setSpinAction = {
    type: typeof COMMON_SET_SPIN;
    value: boolean;
};

export type showModalLoginAction = {
    type: typeof COMMON_SET_SHOW_MODAL_LOGIN;
    isShow: boolean;
};

export type setTitleModalLoginAction = {
    type: typeof COMMON_SET_TITLE_MODAL_LOGIN;
    title: string;
};

export type setIsHandleMenuBookedAction = {
    type: typeof COMMON_SET_IS_CLICK_MENU_BOOKED;
    value: boolean;
};

export type setIsInAppAction = {
    type: typeof COMMON_SET_IS_INAPP;
    value: boolean;
};

export type setLanguageAction = {
    type: typeof COMMON_SET_LANGUAGE;
    value: string;
};

export const addLoading = (): addLoadingAction => {
    return {
        type: COMMON_ADD_LOADING,
    };
};

export const removeLoading = (): removeLoadingAction => {
    return {
        type: COMMON_REMOVE_LOADING,
    };
};

export const setSpin = (value: boolean): setSpinAction => {
    return {
        type: COMMON_SET_SPIN,
        value,
    };
};

export const showModalLogin = (isShow: boolean): showModalLoginAction => {
    return {
        type: COMMON_SET_SHOW_MODAL_LOGIN,
        isShow,
    };
};

export const setTitleModalLogin = (title: string): setTitleModalLoginAction => {
    return {
        type: COMMON_SET_TITLE_MODAL_LOGIN,
        title,
    };
};

export const setIsHandleMenuBooked = (
    value: boolean,
): setIsHandleMenuBookedAction => {
    return {
        type: COMMON_SET_IS_CLICK_MENU_BOOKED,
        value,
    };
};

export const setIsInApp = (value: boolean): setIsInAppAction => {
    return {
        type: COMMON_SET_IS_INAPP,
        value,
    };
};

export const setLanguage = (value: string): setLanguageAction => {
    return {
        type: COMMON_SET_LANGUAGE,
        value,
    };
};

export type Action =
    | addLoadingAction
    | removeLoadingAction
    | setSpinAction
    | showModalLoginAction
    | setTitleModalLoginAction
    | setIsHandleMenuBookedAction
    | setIsInAppAction
    | setLanguageAction;
