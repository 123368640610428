import React, { FC, useEffect, useRef } from 'react';
import $ from 'jquery';

declare const window: any;
const YoutubePlayer: FC<{
    id: number;
    activeSlide?: number;
    videoId: string;
    playerOptions?: any;
    onPlaySlide?: any;
    onStopSlide?: any;
}> = ({
    id,
    activeSlide,
    videoId,
    playerOptions,
    onPlaySlide,
    onStopSlide,
}) => {
    const refYTB = useRef<any>(null);
    let loadYT: any = null;

    const postMessageToPlayer = (player: any, command: any) => {
        if (!player || !command) return;
        player.contentWindow?.postMessage(JSON.stringify(command), '*');
    };

    const onPlayVideo = (e: any) => {
        if (activeSlide === id) {
            e.target.mute();
            e.target.playVideo();
        }
    };

    const onPlayerStateChange = (e: any) => {
        if (e.data === 1 && activeSlide === id) {
            if (onStopSlide) onStopSlide();
        } else if (e.data === 0 && activeSlide === id) {
            if (onPlaySlide) {
                onPlaySlide();
            } else {
                e.target.seekTo(0);
                e.target.playVideo();
            }
        }
    };

    const onLoadVideo = () => {
        const player: any = new window.YT.Player(refYTB.current, {
            videoId: videoId,
            playerVars: {
                autoplay: 1,
                playsinline: 1,
                ...playerOptions,
            },
            events: {
                onReady: onPlayVideo,
                onStateChange: onPlayerStateChange,
            },
        });
    };

    useEffect(() => {
        const player: any = $(`#youtube-player-${id}`)[0];
        if (activeSlide !== id) {
            postMessageToPlayer(player, {
                event: 'command',
                func: 'stopVideo',
            });
        } else {
            postMessageToPlayer(player, {
                event: 'command',
                func: 'mute',
            });
            postMessageToPlayer(player, {
                event: 'command',
                func: 'playVideo',
            });
        }
    }, [activeSlide]);

    useEffect(() => {
        if (!window.YT) {
            loadYT = new Promise((resolve) => {
                const tag = document.createElement('script');
                tag.src = 'https://www.youtube.com/iframe_api';
                const firstScriptTag: any = document.getElementsByTagName(
                    'script',
                )[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
                window.onYouTubeIframeAPIReady = () => resolve(window.YT);
            });
            loadYT.then(() => {
                onLoadVideo();
            });
        } else {
            onLoadVideo();
        }
    }, []);

    return <div id={`youtube-player-${id}`} className="video" ref={refYTB} />;
};

export default YoutubePlayer;
