import Client from './client/BLogStyleClient';
import StoreClient from './client/StoreClient';

export default {
    // api old home
    getTopLike() {
        return Client.get(`api/stylemaster/top-like-in-month-pagesize-4`);
    },
    // end api old home
    // api old home
    getBlogHome() {
        return Client.get(
            `wp-json/wp/v2/posts?per_page=3&categories_exclude=2122`,
        );
    },
    // end api old home
    getBanner() {
        return StoreClient.get(`/web/homepage-banner.json?v=${Math.random()}`);
    },
    getDataDynamicLayout() {
        return StoreClient.get('/web/v3/configs/dynamic-layout.json');
    },
    getFeedBackCustomer() {
        return StoreClient.get('/web/v3/configs/feedback.json');
    },

    getHomepageConfig() {
        return StoreClient.get(`/web/homepage-config.json?v=${Math.random()}`);
    },
};
