import { Dropdown } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iconVi from 'images/flag-vi.png';
import iconEn from 'images/flag-en.png';
import { setLanguage } from 'actions/common';

const DropdownLanguage = () => {
    const common = useSelector((state: any) => state.common);
    const dispatch = useDispatch();

    const onClickLanguage = (lang: string) => {
        if (lang === common.lang) return;
        dispatch(setLanguage(lang));
    };

    const content = (
        <div>
            <div
                className={`flex gap-1 items-center cursor-pointer font-content text-[#20489B] font-medium p-2 text-xs ${
                    common.lang === 'vi'
                        ? 'bg-[#F0F8FF]'
                    : 'bg-white hover:bg-[#DBE9F6]'
                }`}
                onClick={() => onClickLanguage('vi')}
                aria-hidden="true"
            >
                <img src={iconVi} alt="" /> VIE (Tiếng Việt)
            </div>
            <div
                className={`flex gap-1 items-center cursor-pointer font-content text-[#20489B] font-medium p-2 text-xs ${
                    common.lang === 'en'
                        ? 'bg-[#F0F8FF]'
                    : 'bg-white hover:bg-[#DBE9F6]'
                }`}
                onClick={() => onClickLanguage('en')}
                aria-hidden="true"
            >
                <img src={iconEn} alt="" /> ENG (English)
            </div>
        </div>
    );

    return (
        <Dropdown
            overlay={content}
            className="button-lang"
            placement="bottomRight"
            overlayClassName="popover-layout"
            trigger={['click']}
        >
            <div>
                {common.lang === 'vi' ? (
                    <>
                        <img src={iconVi} alt="" />
                    </>
                ) : (
                    <>
                        <img src={iconEn} alt="" />
                    </>
                )}
            </div>
        </Dropdown>
    );
};

export default DropdownLanguage;
