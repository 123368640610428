import React, { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import BookingApi from 'repository/BookingApi';
import './styles.scss';
import { IService } from '../types/Service';
import ListCampaign from './components/ListCampaign';
import ScanQR from './components/ScanQR';
import { useDispatch, useSelector } from 'react-redux';
import { showModalLogin } from 'actions/common';
import { setBookingPhone } from 'actions/booking';

export const fetchCampaign = async (
    chosenService: IService[],
    chosenCampaign: CampaignType[],
    phone: string,
    salonId: number,
) => {
    try {
        const chosenServiceIDs = chosenService
            .map((item) => item.serviceId)
            .join(',');
        const chosenCampaignIDs = chosenCampaign.map((item) => item.campaignId);
        const param: ParamsListCampaignBookingType = {
            campaignUsing: chosenCampaignIDs,
            phone,
            salonId,
            services: chosenServiceIDs,
        };

        const response = await BookingApi.getListCampaignBooking(param);
        if (response?.data) {
            return response?.data;
        }
        return null;
    } catch (error) {
        return null;
    }
};

const ListCampaignScreen: React.FC<{
    handleGotoCampaignDetail: Function;
    phone: string;
    salonId: number;
    chosenService: IService[];
    onClickChosenCampaign: Function;
    chosenCampaign: CampaignType[];
    listCampaign: ListCampaignType | null;
    setListCampaign: Function;
    setChosenCampaign: Function;
    tabs: string;
    setTabs: Function;
}> = ({
    handleGotoCampaignDetail,
    phone,
    salonId,
    chosenService,
    onClickChosenCampaign,
    chosenCampaign,
    listCampaign,
    setListCampaign,
    setChosenCampaign,
    tabs,
    setTabs,
}) => {
    const [listCustomer, setListCustomer] = useState<CampaignType[]>([]);
    const [listPublic, setListPublic] = useState<CampaignType[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const user = useSelector((state: any) => state.user);
    const dispatch = useDispatch();

    const onClickLogin = () => {
        dispatch(showModalLogin(true));
        dispatch(setBookingPhone(phone));
    };

    const onClickChangeTab = (activeKey: string) => {
        if (
            activeKey === '2' &&
            user?.phoneNumber &&
            user.phoneNumber !== phone
        ) {
            setOpenModal(true);
            return;
        }
        setTabs(activeKey);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        fetchCampaign(chosenService, chosenCampaign, phone, salonId).then(
            (data) => {
                if (data) {
                    setListCampaign(data);
                }
            },
        );
    }, [user.userId]);

    useEffect(() => {
        if (listCampaign) {
            setListCustomer(
                listCampaign.customer?.length > 0
                    ? listCampaign.customer.sort(function (x, y) {
                          return x.valid === y.valid ? 0 : x.valid ? -1 : 1;
                      })
                    : [],
            );
            setListPublic(
                listCampaign.salon?.length > 0
                    ? listCampaign.salon.sort(function (x, y) {
                          return x.valid === y.valid ? 0 : x.valid ? -1 : 1;
                      })
                    : [],
            );
            const newData = [...listCampaign.salon, ...listCampaign.customer];

            const listNewChosenCampaign = newData.filter((campaign) =>
                chosenCampaign.some((f) => {
                    return f.campaignId === campaign.campaignId;
                }),
            );
            setChosenCampaign(listNewChosenCampaign);
        }
    }, [listCampaign]);

    return (
        <div className="campaign-screen">
            {!user.userId ? (
                <div className="flex flex-col items-center pt-6 min-h-screen ">
                    <span className="text-sm mb-2">
                        Bạn vui lòng đăng nhập để hiển thị ưu đãi
                    </span>
                    <div
                        onClick={() => onClickLogin()}
                        className="bg-[#20489B] cursor-pointer py-2 px-4 rounded uppercase text-white"
                    >
                        Đăng nhập
                    </div>
                </div>
            ) : (
                <ListCampaign
                    handleGotoCampaignDetail={(campaign: CampaignType) =>
                        handleGotoCampaignDetail(campaign)
                    }
                    list={[...listPublic, ...listCustomer]}
                    onClickChosenCampaign={(campaign: CampaignType) =>
                        onClickChosenCampaign(campaign)
                    }
                    chosenCampaign={chosenCampaign}
                >
                    <ScanQR
                        getReward={() =>
                            fetchCampaign(
                                chosenService,
                                chosenCampaign,
                                phone,
                                salonId,
                            )
                        }
                    />
                </ListCampaign>
            )}
            <Modal
                visible={openModal}
                className="modal-checkbox modal-noti-chosencampaign"
                onCancel={() => setOpenModal(false)}
                width={480}
                footer={null}
                title="THÔNG BÁO"
                centered
            >
                <>
                    <div className="text-sm text-center">
                        Bạn vui lòng đăng nhập bằng số điện thoại đang đặt lịch{' '}
                        <b>{phone}</b>{' '}
                        <span className="whitespace-nowrap">
                            để hiển thị ưu đãi.
                        </span>
                    </div>
                </>
            </Modal>
        </div>
    );
};

export default ListCampaignScreen;
