import StoreClient from './client/StoreClient';

export default {
    // api old home
    getListImages() {
        return StoreClient.get('/shine_moment/photos.json');
    },
    getListShineMemberConfig() {
        return StoreClient.get(
            '/ResourceApp/30S_Customer/shinemember_config_v2.json',
        );
    },
};
