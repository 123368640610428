import Client from './client/SavingLocationClient';

export default {
    getListHistoryCustomer(param) {
        return Client.get(
            `/api/customer-history?pageNum=${param.pageNum}&customerId=${param.customerId}&brandName=MOYO`,
        );
    },
    getTagRating() {
        return Client.get(`/api/rating`);
    },
    checkCustomerIsRating() {
        return Client.get(`/api/rating/check`);
    },
    putWrongImage(param) {
        return Client.put(`/api/customer-history/report-wrong-image`, param);
    },
    postRating(param) {
        return Client.post(`/api/rating`, param);
    },
    postRatingImage(param) {
        return Client.put(`/api/customer-history/rating-image`, param);
    },
};
