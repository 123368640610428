import { Reducer } from 'redux';
import { BANNER_SET } from '../actionTypes/banner';
import { Action } from '../actions/banner';

export type Banner = {
    type: string;
    link: string;
    order: number;
    redirectLink: string;
};

export type IStateBanner = Array<Banner>;

const initialState: IStateBanner = [];

const banner: Reducer<IStateBanner, Action> = (
    state = initialState,
    action: Action,
) => {
    switch (action.type) {
        case BANNER_SET: {
            const newBanner = state.concat(action.payload);

            return newBanner;
        }

        default:
            return state;
    }
};

export default banner;
