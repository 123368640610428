import React, { useEffect, useState } from 'react';
import RewardApi from 'repository/RewardApi';
import { useDispatch, useSelector } from 'react-redux';
import ServiceItem from './ServiceItem';
import { IService } from '../types/Service';

const CampaignDetailScreen: React.FC<{
    campaignDetailSelected: CampaignType | null;
    onClickChosenCampaign: Function;
    chosenService: IService[];
    listAllServices: IService[];
    handleGoToCampaignScreen: Function;
    changeChosenServiceBooking: Function;
    salonId: number;
    tabs: string;
}> = ({
    campaignDetailSelected,
    onClickChosenCampaign,
    chosenService,
    listAllServices,
    handleGoToCampaignScreen,
    changeChosenServiceBooking,
    salonId,
    tabs,
}) => {
    const [data, setData] = useState<CampaignDetailType | null>(null);
    const [isChosenService, setIsChosenService] = useState(false);
    const listServiceId = chosenService.map((item) => item.serviceId);
    const user = useSelector((state: any) => state.user);

    const handleOnClickChosenCampaign = () => {
        if (isChosenService) {
            handleGoToCampaignScreen();
            onClickChosenCampaign({
                ...campaignDetailSelected,
                serviceApplies: data?.serviceApplies,
            });
        }
    };

    const getCampaignDetail = async () => {
        try {
            const response = await RewardApi.getRewardDetail({
                campaignId: campaignDetailSelected?.campaignId,
                salonId,
            });
            if (response?.data) {
                const newServiceApplies =
                    response.data.serviceApplies.map((item: any) => ({
                        ...item,
                        selected: listServiceId.includes(item.id),
                    })) || [];
                const newServiceCondition =
                    response.data.serviceConditions.map((item: any) => ({
                        ...item,
                        selected: listServiceId.includes(item.id),
                    })) || [];
                setData({
                    ...response?.data,
                    serviceApplies: newServiceApplies,
                    serviceConditions: newServiceCondition,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (data) {
            let chosen = true;
            if (data.serviceApplies.length || data.serviceConditions.length) {
                chosen =
                    data?.serviceApplies.filter((item) => item.selected)
                        .length > 0 ||
                    data?.serviceConditions.filter((item) => item.selected)
                        .length > 0;
            }
            setIsChosenService(chosen);
        }
    }, [data]);

    useEffect(() => {
        if (data) {
            setData({
                ...data,
                serviceApplies: data.serviceApplies.length
                    ? data.serviceApplies.map((item) => ({
                          ...item,
                          selected: listServiceId.includes(item.id),
                      }))
                    : [],
                serviceConditions: data.serviceConditions.length
                    ? data.serviceConditions.map((item) => ({
                          ...item,
                          selected: listServiceId.includes(item.id),
                      }))
                    : [],
            });
        }
    }, [chosenService]);

    useEffect(() => {
        if (tabs === '2' && !user.userId) {
            handleGoToCampaignScreen();
            return;
        }
        if (campaignDetailSelected) {
            getCampaignDetail();
        }
    }, [campaignDetailSelected, user.userId, tabs]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="bg-white ">
            <div className="bg-[#20489B] h-[69px]" />
            <div className="-mt-[50px] shadow-[0px_3px_8px_0px_rgba(151,151,151,0.21)] flex items-center bg-white mx-4 rounded-[10px] px-2.5 py-4">
                <img src={data?.imageUrl} alt="" className="h-[52px]" />
                <div className="pl-2">
                    <div className="text-sm font-medium">{data?.name}</div>
                    <div className="text-xs font-normal text-[#A3A3A3]">
                        HSD: {data?.endDate}
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 mx-[14px] pt-3 pb-3">
                <div>
                    <div className="text-[#000000] text-sm">
                        Thời gian sử dụng
                    </div>

                    <div className="text-[#787878] text-sm">
                        {data?.startDate} đến {data?.endDate}
                    </div>
                </div>

                <div>
                    <div className="text-[#000000] text-sm">Số lần sử dụng</div>
                    <div className="text-[#787878] text-sm">
                        {data?.remainUsage}
                    </div>
                </div>

                <div>
                    <div className="text-[#000000] text-sm">
                        Hình thức thanh toán
                    </div>

                    <div className="text-[#787878] text-sm">
                        {data?.paymentMethod}
                    </div>
                </div>

                <div>
                    <div className="text-[#000000] text-sm">Salon áp dụng</div>

                    <div className="text-[#787878] text-sm">
                        {data?.salonApplies}
                    </div>
                </div>
            </div>
            <div className="px-[14px]">
                <div className="border-t border-gray-200 pb-2.5">
                    <div className="text-[#000000] text-sm text-sm pt-2.5 pb-1">
                        Điều kiện
                    </div>
                    <div className="text-[#787878] text-sm pb-2.5">
                        {data?.condition}
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                        {data?.serviceConditions &&
                        data.serviceConditions.length > 0
                            ? data.serviceConditions.map((service) => (
                                  <ServiceItem
                                      key={service.id}
                                      data={service}
                                      chosenService={chosenService}
                                      listAllServices={listAllServices}
                                      campaignId={
                                          campaignDetailSelected?.campaignId ||
                                          null
                                      }
                                      changeChosenServiceBooking={(
                                          list: IService[],
                                      ) => {
                                          changeChosenServiceBooking(list);
                                          const newServiceCondition =
                                              data.serviceConditions.map(
                                                  (item) => ({
                                                      ...item,
                                                      selected: list
                                                          .map(
                                                              (item) =>
                                                                  item.serviceId,
                                                          )
                                                          .includes(item.id),
                                                  }),
                                              ) || [];
                                          setData({
                                              ...data,
                                              serviceConditions: newServiceCondition,
                                          });
                                      }}
                                  />
                              ))
                            : null}
                    </div>
                </div>

                <div className="border-t border-gray-200 pb-2.5">
                    <div className="text-[#000000] text-sm text-sm pt-2.5 pb-1">
                        Khuyến mãi
                    </div>
                    <div className="text-[#787878] text-sm pb-2.5">
                        {data?.effect}
                    </div>
                    <div className="grid grid-cols-2 gap-2 ">
                        {data?.serviceApplies && data.serviceApplies.length > 0
                            ? data.serviceApplies.map((service) => (
                                  <ServiceItem
                                      key={service.id}
                                      data={service}
                                      chosenService={chosenService}
                                      listAllServices={listAllServices}
                                      campaignId={
                                          campaignDetailSelected?.campaignId ||
                                          null
                                      }
                                      changeChosenServiceBooking={(
                                          list: IService[],
                                      ) => {
                                          changeChosenServiceBooking(list);
                                          const newServiceApplies =
                                              data.serviceApplies.map(
                                                  (item) => ({
                                                      ...item,
                                                      selected: list
                                                          .map(
                                                              (item) =>
                                                                  item.serviceId,
                                                          )
                                                          .includes(item.id),
                                                  }),
                                              ) || [];
                                          setData({
                                              ...data,
                                              serviceApplies: newServiceApplies,
                                          });
                                      }}
                                  />
                              ))
                            : null}
                    </div>
                </div>
            </div>
            <div className="button-affix">
                <div className="flex text-center space-between content-step">
                    <div
                        className={`right button-next pointer text-white ${
                            isChosenService ? '!bg-[#20489B]' : '!bg-[#E8E8E8]'
                        } hover:opacity-50`}
                        onClick={() => handleOnClickChosenCampaign()}
                        role="presentation"
                    >
                        <span>Chọn ưu đãi</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignDetailScreen;
