import { Dispatch } from 'redux';
import { store } from '../App';
import {
    BOOKING_SET_ID_SUCCESS,
    BOOKING_SET_TIME_SPECIAL,
    BOOKING_SET_PHONE,
} from '../actionTypes/booking';

export type setBookingIdAction = {
    type: typeof BOOKING_SET_ID_SUCCESS;
    bookingId: number;
};

export type setBookingPhoneAction = {
    type: typeof BOOKING_SET_PHONE;
    bookingPhone: string;
};

export type setBookingTimeSpecialAction = {
    type: typeof BOOKING_SET_TIME_SPECIAL;
    listTimeSpecialHours: Array<any>;
};

export const setBookingId = (bookingId: number): setBookingIdAction => {
    return {
        type: BOOKING_SET_ID_SUCCESS,
        bookingId,
    };
};

export const setBookingPhone = (
    bookingPhone: string,
): setBookingPhoneAction => {
    return {
        type: BOOKING_SET_PHONE,
        bookingPhone,
    };
};

export const setTimeSpecial = (
    listTimeSpecialHours: any,
): setBookingTimeSpecialAction => {
    return {
        type: BOOKING_SET_TIME_SPECIAL,
        listTimeSpecialHours,
    };
};

export const setListIconHotTime = (value: any) => (dispatch: Dispatch) => {
    const specialHours = [...store.getState().booking.listTimeSpecialHours];
    let list = specialHours;
    if (value.length) {
        for (let index = 0; index < value.length; index += 1) {
            if (
                !specialHours.length ||
                !specialHours.filter((item) => item.name === value[index].name)
                    .length
            ) {
                list.push(value[index]);
            }
        }
    } else {
        list = [];
    }

    dispatch({
        type: BOOKING_SET_TIME_SPECIAL,
        payload: list,
    });
};

export type Action =
    | setBookingIdAction
    | setBookingPhoneAction
    | setBookingTimeSpecialAction;
