import React, { FC, useEffect, useState } from 'react';
import API from 'repository';
import TopProduct from './TopProduct';
import Banner from '../banner';
import LayoutHeadTitle from '../headTitle';
import './style.scss';

const LayoutShop: FC<{ data: any }> = ({ data }) => {
    const [topProducts, setTopProducts] = useState<any>([]);

    const fetchTopProduct = async () => {
        try {
            const response = await API.getShopProduct();
            if (response.data.meta.success) {
                const listTopProduct = response.data.data;
                setTopProducts(listTopProduct);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchTopProduct();
    }, []);

    return (
        <div
            className={`home_store_v2 bg_custom_shop ${
                data?.bg_custom ? 'bg_custom' : ''
            }`}
        >
            <div className="home_store_container container">
                <LayoutHeadTitle data={data} />
                <div className="home_store_banner">
                    <Banner data={data} />
                </div>
                <TopProduct listTopProduct={topProducts} />
            </div>
        </div>
    );
};

export default LayoutShop;
