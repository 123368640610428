import moment from 'moment';
import queryString from 'query-string';
import { ISelected as ISelectedBookingNew } from '../pages/bookingNew/types/Selected';

export function setQuery(
    _selected: ISelectedBookingNew,
    ortherParams: any = {},
) {
    const params: string = queryString.stringify({
        step: _selected.step,
        phone: _selected.customerPhone,
        salonId: _selected.salon.id,
        ...ortherParams,
    });
    return params;
}

export function getQuery(params: string) {
    const data: any = queryString.parse(params);
    if (data.step) {
        if (Number(data.step) > 5) {
            data.step = 0;
        } else {
            data.step = Number(data.step) > 0 ? Number(data.step) : 0;
        }
    }
    if (data.salonId) data.salonId = Number(data.salonId);
    if (
        data.source === 'customer_ios' &&
        Number(data.inApp) === 1 &&
        Number(data.salonId) === 20
    ) {
        data.salonId = 0;
    }
    return data;
}

export const removeDuplicateSalon = (especiallySalon: any) => {
    if (especiallySalon.length < 2) return especiallySalon;
    const listRemoveDuplicate = especiallySalon.filter(
        (salon: any, index: any, self: any) =>
            self.findIndex((item: any) => item.id === salon.id) === index,
    );
    return listRemoveDuplicate;
};

export const distanceBetween = (point1: any, point2: any) => {
    const dX = point2.longitude - point1.longitude;
    const dY = point2.latitude - point1.latitude;
    const distance = Math.sqrt(Math.pow(dX, 2) + Math.pow(dY, 2));

    return Math.round(distance * 1110) / 10;
};

export function diffTimeToday(value: string) {
    if (!value) return false;
    let count = 0;
    const time: any = value.split('-');
    const dateOpen = moment([time[2], time[1] - 1, time[0]]);

    const toDay = moment(new Date()).format('DD-MM-YYYY');
    const _time: any = toDay.split('-');
    const _toDay = moment([_time[2], _time[1] - 1, _time[0]]);

    count = dateOpen.diff(_toDay, 'days');
    if (count && count > -1) return count;
    return false;
}
export const removeDuplicateGroupService = (listService: any, service: any) => {
    const result = listService.filter(
        (serv: any) => serv.group !== service.group || !service.group,
    );
    const listRemoveChild = result.filter((ser: any) => {
        if (
            ser.parentServiceId &&
            !result.some((item: any) => item.serviceId === ser.parentServiceId)
        ) {
            return false;
        }
        return true;
    });

    return listRemoveChild;
};

export const formatPriceWithKCharacter = (price = 0) => {
    return `${price > 1000 ? price.toString().slice(0, -3) : price}K`;
};

export const formatPriceServiceToNum = (price = '') => {
    const priceConvert = Number(price.toString().replace('K', '000'));
    return Number.isNaN(priceConvert) ? 0 : priceConvert;
};

export const ENUM_BACKGROUND_TAG_SERVICE = [
    { categoryId: 1, background: 'bg-[#F6CFBE]', color: 'text-[#3D3D3D]' },
    { categoryId: 2, background: 'bg-[#FDE791]', color: 'text-[#3D3D3D]' },
    { categoryId: 3, background: 'bg-[#A1DDF9]', color: 'text-[#3D3D3D]' },
];
