import { Dispatch } from 'redux';
import API from 'repository';
import Cookies from 'js-cookie';
import { USER_SET } from '../actionTypes/user';
import { TRACKING_DATA_SET } from '../actionTypes/trackingData';
import { IStateUser } from '../reducers/user';

// Define setUserAction type
export type setUserAction = {
    type: typeof USER_SET;
    payload: IStateUser | false; // Adjusted payload type to include false
};

// Action creator for setting user
export const setUser = (value: IStateUser | false): setUserAction => ({
    type: USER_SET,
    payload: value,
});

export const logoutUser = () => async (dispatch: Dispatch) => {
    try {
        localStorage.clear();
        Cookies.remove('_cus_accessToken2', {
            path: '/',
            domain: process.env.REACT_APP_BASE_URL,
        });
        dispatch(setUser(false));
    } catch (error) {
        console.error('Error logout user data:', error);
    }
};

// Async action to fetch user data
export const fetchUser = () => async (dispatch: Dispatch) => {
    const accessToken = Cookies.get('_cus_accessToken2');
    // Handle missing access token gracefully
    if (!accessToken) {
        dispatch(setUser(false));
        dispatch({
            type: TRACKING_DATA_SET,
            payload: { phoneNumber: '', customerId: 0 },
        });
        return [];
    }

    try {
        const response = await API.getUser();

        // Check for successful response status code
        // @ts-ignore
        if (response.statusCode !== 200) {
            dispatch(setUser(false));
            return [];
        }

        // Extract relevant user data
        const { userInfo, accountBalance, isRegistered } = response.data;

        // Handle unregistered user scenario
        if (!isRegistered) {
            console.warn(
                'User is not registered. Implement registration logic here.',
            );
            dispatch(setUser(false));
            return [];
        }

        // Build user object for dispatch
        const user = {
            ...userInfo,
            userIdMongo: userInfo.userId,
            userId: userInfo.userIdSql,
            accountBalance,
            agreeProtectPersonalInfo: response.data.agreeProtectPersonalInfo,
        };

        // Dispatch user and tracking data actions
        dispatch(setUser(user));
        dispatch({
            type: TRACKING_DATA_SET,
            payload: {
                phoneNumber: `+84${userInfo.phoneNumber.slice(1)}`,
                customerId: userInfo.userIdSql,
            },
        });

        return response;
    } catch (error) {
        console.error('Error fetching user data:', error);
        dispatch(setUser(false));
        return [];
    }
};

// Define Action type to include setUserAction
export type Action = setUserAction;
