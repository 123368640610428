export default {
    HOME_PAGE: 'HOME_PAGE',
    NEW_HOME_PAGE: 'NEW_HOME_PAGE',
    BOOKING_PAGE: 'BOOKING_PAGE',
    LOOKBOOK_HOME_PAGE: 'LOOKBOOK_HOME_PAGE',
    DISCOVER_PAGE: 'DISCOVER_PAGE',
    SYSTEM_SALON_PAGE: 'SYSTEM_SALON_PAGE',
    SALON_DETAIL_PAGE: 'SALON_DETAIL_PAGE',
    COMMON_PAGE: 'COMMON_PAGE',
    BOOKING_SINGLE_PAGE: 'BOOKING_SINGLE_PAGE',
    LANDINGPAGE_30SHINESPA_HIGHTECH: 'LANDINGPAGE_30SHINESPA_HIGHTECH',
    LANDINGPAGE_30SHINESPA_LIGHTSMOOTH: 'LANDINGPAGE_30SHINESPA_LIGHTSMOOTH',
    LANDINGPAGE_30SHINESPA_DRFORSKIN: 'LANDINGPAGE_30SHINESPA_DRFORSKIN',
    LANDINGPAGE_30SHINESPA_NANOTECH: 'LANDINGPAGE_30SHINESPA_NANOTECH',
    LANDINGPAGE_30SHINESPA_SYSTEM: 'LANDINGPAGE_30SHINESPA_SYSTEM',
    LANDINGPAGE_30SHINESPA_HOME: 'LANDINGPAGE_30SHINESPA_HOME',
};
