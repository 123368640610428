import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

const RedirectLink: FC<{
    children: React.ReactNode;
    redirect: string;
    className?: string;
}> = ({ children, redirect, className }) => {
    const history = useHistory();

    const handleOnClick = (e: any) => {
        e.preventDefault();
        if (redirect[0] === '/') {
            history.push(redirect);
        } else {
            window.open(redirect);
        }
    };

    if (redirect) {
        return (
            <a
                href={redirect ? redirect : ''}
                rel="noreferrer"
                onClick={(e) => handleOnClick(e)}
                className={className}
            >
                {children}
            </a>
        );
    }
    return <div className={className}>{children}</div>;
};

export default RedirectLink;
