import React, { useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import Skeleton from 'antd/lib/skeleton/index';
import queryString from 'query-string';

const MiddleWareRouter: React.FC<{ component: any }> = ({
    component: Component,
    ...others
}) => {
    const { location } = others as any;
    const history: any = useHistory();
    const common = useSelector((state: any) => state.common);
    const { utmSource, source } = useSelector(
        (state: any) => state.trackingData,
    );

    useEffect(() => {
        const locationSearch: any = queryString.parse(location?.search);
        if (common.inApp && locationSearch?.inApp == undefined) {
            history.replace({
                hash: location?.hash,
                search: `?inApp=1${
                    utmSource ? `&utm_source=${utmSource}` : ''
                }${source ? `&source=${source}` : ''}`,
            });
        }
    }, [location?.pathname]);

    return (
        <Route
            {...others}
            render={(props: any) => {
                return (
                    <Suspense fallback={<Skeleton paragraph={{ rows: 10 }} />}>
                        <Component {...others} {...props} />
                    </Suspense>
                );
            }}
        />
    );
};

export default MiddleWareRouter;
