/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import API from 'repository';
import { Skeleton } from 'antd';
import iconTime from 'images/icons/icon_time.svg';
import './styles.scss';
import { connect, useSelector } from 'react-redux';
import { ENUM_BACKGROUND_TAG_SERVICE } from 'utils/BookingUltils';
import iconTick from 'images/icons/ic_tick.svg';
import iconArrowLeft from 'images/icons/icon_arrow_left_blue.svg';
import iconArrowDown from 'images/fillBlue/double-down.svg';
import YoutubePlayer from '../components/YoutubePLayer';
import ServiceUpSale from '../components/ServiceUpSale';
import KEY from '../../Const';
import {
    IService,
    IServiceGroup,
    ServiceDetailType,
    UpsaleServicesType,
} from '../../types/Service';
import { ISelected } from '../../types/Selected';

const DetailService: FC<{
    serviceGroup: IServiceGroup;
    setService: Function;
    onSelectService: Function;
    selected: ISelected;
    onChangeStep: any;
    role?: any;
}> = ({
    serviceGroup,
    setService,
    onSelectService,
    selected,
    onChangeStep,
    role,
}) => {
    const { inApp, lang } = useSelector((state: any) => state.common);
    const [data, setData] = useState<ServiceDetailType | null>();
    const [loading, setLoading] = useState(true);
    const [selectedService, setSelectedService] = useState<IService[]>([]);
    const [showExpand, setShowExpand] = useState(false);
    const [serviceActive, setServiceActive] = useState<IService>();
    const [showError, setShowError] = useState(false);

    const selectedChosenServiceId = selected.chosenService.map(
        (item) => item.serviceId,
    );

    const getServiceItem = (service: UpsaleServicesType) => {
        return {
            order: 1,
            categoryId: 0,
            serviceId: service.serviceId,
            parentServiceId: 0,
            comboDetail: [
                {
                    id: service.serviceId,
                    name: service.title,
                    type: 'service',
                    skillId: '0',
                },
            ],
            group: 0,
            name: service.title,
            subtitle: '',
            description: null,
            timeServe: null,
            campaignId: null,
            price: service.price,
            oldPrice: '',
            priceNum: service.priceNum,
            selected: 0,
            tags: [],
            used: 0,
            hasSpecial: false,
            priceSpecialDay: '',
            oldPriceSpecialDay: '',
            priceSpecialDayNum: 0,
            hasDetail: false,
            listService: [],
            serviceSaleInfo: null,
            serviceImage: [],
            upsaleServices: null,
        };
    };

    const getListServices = async () => {
        const params: any = {
            salonId: selected[KEY.SALON].id || 0,
            groupId: serviceGroup.groupId,
            memberType: role?.memberType || 0,
            lang,
        };

        try {
            setLoading(true);
            const res: any = await API.getDetailServiceV3(params);
            const list = res?.data || null;
            if (!list) return;
            if (list) {
                setData(list);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const handleOnSelectedService = () => {
        if (selectedService.length === 0) {
            setShowError(true);
            return;
        }
        const selectedServiceGroupId = selectedService.map(
            (item) => item.groupId,
        );
        const newChosenService = [
            ...selected.chosenService.filter(
                (service) => !selectedServiceGroupId.includes(service.groupId),
            ),
            ...selectedService,
        ];
        const uniqueChosenService = Array.from(
            new Map(
                newChosenService.map((obj) => [obj.serviceId, obj]),
            ).values(),
        );
        onSelectService(uniqueChosenService);
        setService(0);
        onChangeStep(2);
    };

    const onHandleClickUpsale = (
        serviceUpSale: UpsaleServicesType,
        check: boolean,
    ) => {
        let service: any = null;
        selected.listAllService.forEach((item: any) => {
            if (item.serviceId === serviceUpSale.serviceId) {
                service = item;
            }
        });

        const newService = getServiceItem(serviceUpSale);
        if (!service) service = newService;
        if (check) {
            setSelectedService([...selectedService, service]);
        } else {
            setSelectedService(
                selectedService.filter(
                    (item) => service.serviceId !== item.serviceId,
                ),
            );
        }
    };

    const getColorTagService = (index: number) => {
        switch (index) {
            case 0: {
                const color = ENUM_BACKGROUND_TAG_SERVICE.find(
                    (cate) => cate.categoryId === serviceActive?.categoryId,
                );
                return color
                    ? `${color.background} ${color.color}`
                    : 'bg-[#FFE985] text-[#222]';
            }
            case 1:
                if (serviceActive?.used === 1) {
                    // lần trước
                    if (serviceActive?.selected === 1)
                        return 'bg-[#11B14B] text-[#FFF]';

                    // từng dùng
                    return 'bg-[#F4933C] text-[#FFF]';
                }
                return 'bg-[#11B14B] text-[#F0F8FF]';
            default:
                return 'bg-[#FFE985] text-[#222]';
        }
    };

    const onHandleClickSelectedServiceVariant = (service: IService) => {
        if (serviceActive?.serviceId === service.serviceId) return;

        const newListSelected = selectedService.filter(
            (item) => item.serviceId !== serviceActive?.serviceId,
        );

        setSelectedService([service, ...newListSelected]);
        setServiceActive(service);
    };

    const activeGroupService = (service: IService) => {
        return (
            serviceActive?.serviceId === service.serviceId &&
            serviceGroup.services.length > 1
        );
    };

    useEffect(() => {
        const serviceGroupId = serviceGroup.services.map(
            (item) => item.serviceId,
        );
        const newServiceActive = selected.chosenService.find((service) =>
            serviceGroupId.includes(service.serviceId),
        );

        if (newServiceActive) {
            const newListService: IService[] = [];
            if (
                serviceGroup?.upsaleServices &&
                serviceGroup?.upsaleServices.length
            ) {
                const listSelectedUpsale = serviceGroup.upsaleServices.filter(
                    (item) => selectedChosenServiceId.includes(item.serviceId),
                );
                if (listSelectedUpsale.length) {
                    listSelectedUpsale.map((sv) => {
                        let newItem: any = null;
                        selected.listAllService.forEach((item) => {
                            if (item.serviceId === sv.serviceId) {
                                newItem = item;
                            }
                        });
                        if (!newItem) newItem = getServiceItem(sv);
                        newListService.push(newItem);
                    });
                }
            }
            setSelectedService([newServiceActive, ...newListService]);
            setServiceActive(newServiceActive);
        }
    }, [serviceGroup, selected.chosenService]);

    useEffect(() => {
        if (serviceGroup.groupId > 0) {
            getListServices();
        }
    }, [serviceGroup.groupId]);

    useEffect(() => {
        if (selectedService.length) {
            setShowError(false);
        }
    }, [selectedService]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="service__detail">
            <div
                className={`navigator pointer ${
                    inApp ? 'bg-white border-b border-f7f7f7' : ''
                }`}
            >
                <img
                    src={iconArrowLeft}
                    alt=""
                    onClick={() => {
                        onChangeStep(2);
                    }}
                    role="presentation"
                />
                <span className="text-center text-[#20489B] pl-2">
                    {data?.title}
                </span>
            </div>
            {loading ? (
                <div className="min-h-screen">
                    <Skeleton.Avatar
                        className="loading__banner"
                        shape="square"
                    />
                    <Skeleton
                        paragraph={{ rows: 1 }}
                        className="loading__title"
                    />
                </div>
            ) : (
                <div className="detail__content">
                    <div
                        className={`p-[15px] ${
                            showExpand ? 'h-auto' : 'h-[500px]'
                        } overflow-hidden`}
                    >
                        <div className="flex items-baseline gap-x-6 mb-5">
                            <div>
                                <div
                                    className="text-[#20489B] font-semibold whitespace-pre-line"
                                    role="presentation"
                                >
                                    {data?.title}
                                </div>
                                <div
                                    className="text-[#5F5F5F] text-xs whitespace-pre-line"
                                    role="presentation"
                                >
                                    {data?.titleContent}
                                </div>
                                <div className="flex gap-[5px] min-h-[24px] pt-1.5">
                                    {data?.tags && data?.tags?.length
                                        ? data.tags.map((tag, index) => (
                                              <div
                                                  key={tag}
                                                  className={`rounded-[3px] ${getColorTagService(
                                                      index,
                                                  )} text-xs py-1 px-2.5`}
                                              >
                                                  {tag}
                                              </div>
                                          ))
                                        : null}
                                </div>
                            </div>

                            <div className="flex-none ml-auto flex items-center text-sm text-[#20489B]">
                                <img src={iconTime} alt="" className="mr-1" />
                                {serviceActive?.timeServe}
                            </div>
                        </div>
                        {data && data.banners.length && (
                            <>
                                {data.banners.map((media, index: number) => (
                                    <div key={media.link} className="pb-2">
                                        {media.type === 'image' ? (
                                            <img src={media.link} alt="" />
                                        ) : media.type === 'video' ? (
                                            <YoutubePlayer
                                                id={index}
                                                videoId={media.link}
                                                activeSlide={index}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                    <div
                        className={`${
                            showExpand ? 'pb-3' : 'detail__expand'
                        } flex items-end justify-center gap-3 text-xs cursor-pointer lg:hover:text-[#111] text-[#20489B]`}
                        onClick={() => setShowExpand(!showExpand)}
                        aria-hidden="true"
                    >
                        <img
                            src={iconArrowDown}
                            alt=""
                            className={`w-[14px] h-[14px] ${
                                showExpand ? 'rotate-180' : ''
                            }`}
                        />
                        {showExpand
                            ? lang === 'vi'
                                ? 'Thu gọn'
                                : 'See less'
                            : lang === 'vi'
                            ? 'Xem thêm'
                            : 'See more'}
                        <img
                            src={iconArrowDown}
                            alt=""
                            className={`w-[14px] h-[14px] ${
                                showExpand ? 'rotate-180' : ''
                            }`}
                        />
                    </div>
                </div>
            )}
            <div className="my-[12px] py-3 px-[15px] bg-white">
                <div className="border-b border-[#E8E8E8] pb-3  font-semibold text-sm">
                    <span className="text-[#20489B]">
                        {lang === 'vi' ? 'Chọn phiên bản' : 'Select version'}
                    </span>
                    <span className="text-[#5F5F5F] pl-2.5">
                        {lang === 'vi' ? 'Chọn 1' : 'Choose 1'}
                    </span>
                </div>
                <div className="px-[5px] flex gap-[5px]">
                    {serviceGroup?.services.length
                        ? serviceGroup?.services.map((service) => (
                              <div
                                  className={`flex-1 relative border border-[#20489B] rounded-[5px] py-1 cursor-pointer ${
                                      activeGroupService(service)
                                          ? 'bg-[#F0F8FF]'
                                          : 'lg:hover:bg-[#F0F8FF]'
                                  }${
                                      showError
                                          ? ' border border-animation'
                                          : ''
                                  }`}
                                  onClick={() =>
                                      onHandleClickSelectedServiceVariant(
                                          service,
                                      )
                                  }
                                  aria-hidden="true"
                              >
                                  <div className="px-3 text-[#20489B] text-xs font-semibold text-center">
                                      {service.versionName}
                                  </div>

                                  <img
                                      src={iconTick}
                                      alt=""
                                      className={`absolute top-[-1px] left-[-1px] transition-all rounded-l-[5px] ${
                                          activeGroupService(service)
                                              ? 'opacity-1'
                                              : 'opacity-0'
                                      }`}
                                  />
                                  <div className="mt-auto text-sm font-semibold text-[#3D3D3D] text-center">
                                      {service?.price ? service.price : ''}
                                      {service?.oldPrice !== service?.price ? (
                                          <span className="text-sm font-semibold text-[#5F5F5F] text-[#3D3D3D] line-through pl-2.5">
                                              {service?.oldPrice}
                                          </span>
                                      ) : null}
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
            {serviceGroup?.upsaleServices &&
            serviceGroup?.upsaleServices?.length ? (
                <div className="my-[12px] py-3 px-[15px] bg-white">
                    <div className="border-b border-[#E8E8E8] pb-3 text-[#20489B] font-semibold text-sm">
                        <span className="text-[#20489B]">
                            {lang === 'vi'
                                ? 'Dịch vụ dùng kèm (Chọn thêm)'
                                : 'Add-on services'}
                        </span>
                        <span className="text-[#5F5F5F] pl-2.5">
                            {lang === 'vi'
                                ? 'Chọn nhiều, không bắt buộc'
                                : 'Multiple selections, optional'}
                        </span>
                    </div>
                    {serviceGroup.upsaleServices.map((item: any) => (
                        <div className="pt-3" key={item.serviceId}>
                            <ServiceUpSale
                                showDetail
                                serviceUpSale={item}
                                onSelectService={(
                                    data: UpsaleServicesType,
                                    check: boolean,
                                ) => onHandleClickUpsale(data, check)}
                                chosenService={selectedService}
                            />
                        </div>
                    ))}
                </div>
            ) : null}
            <div className="button-affix bg-white shadow-[0px_-2px_10px_0px_rgba(0,0,0,0.1)] p-3">
                <div
                    className="bg-[#20489B] cursor-pointer text-white text-center py-3 px-4 text-base rounded-[5px] text-base font-semibold lg:hover:opacity-50"
                    onClick={() => handleOnSelectedService()}
                    role="presentation"
                >
                    {lang === 'vi'
                        ? `Thêm ${
                              selectedService.length === 0
                                  ? ''
                                  : selectedService.length
                          } dịch vụ`
                        : `Choose ${selectedService.length} ${
                              selectedService.length > 1
                                  ? 'services'
                                  : 'service'
                          }`}
                </div>
            </div>
        </div>
    );
};
function mapStateToProps(state: any) {
    if (process.env.NODE_ENV === 'development')
        console.log('%cStore', 'color: #d343dd', state);
    const { role } = state;
    return {
        role,
    };
}

export default connect(mapStateToProps, null)(DetailService);
