import React, { FC, useState, useRef } from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import KEY from '../../../Const';
import { ITimeBox } from '../../../types/DateTime';
import { ISelectedSalonBackUp } from '../../../types/Selected';

const SwiperTimeBox: FC<{
    onChangeTime: Function;
    hours: ITimeBox[];
    selected: ISelectedSalonBackUp;
}> = ({ onChangeTime, hours, selected }) => {
    const paramSwiper = {
        spaceBetween: 8,
        slidesPerView: 4.2,
        breakpoints: {
            1200: {
                spaceBetween: 8,
                slidesPerView: 5.2,
            },
            100: {
                spaceBetween: 8,
                slidesPerView: 4.2,
            },
        },
    };

    const swiperRef = useRef<any>(null);
    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);

    const handleSlideChange = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const classButtonPrev = classNames('swiper_button button_prev', {
        swiper_button_disable: isFirstSlide,
    });

    const classButtonNext = classNames('swiper_button button_next', {
        swiper_button_disable: isLastSlide,
    });

    return (
        hours && (
            <div className="box-time">
                <Swiper
                    {...paramSwiper}
                    ref={swiperRef}
                    on={{ slideChange: handleSlideChange }}
                >
                    {hours.map((hour: any) => {
                        const {
                            bookHourId,
                            subHourId,
                            secondSubHourId,
                        } = selected[KEY.HOUR];
                        const isCheck = !!(
                            bookHourId === hour.hourId &&
                            subHourId === hour.subHourId &&
                            secondSubHourId === hour.secondSubHourId
                        );
                        const wapperClass = `box-time__item ${
                            isCheck ? 'has-choice' : ''
                        }`;
                        return (
                            <div
                                className={wapperClass}
                                onClick={() => onChangeTime(hour)}
                                role="presentation"
                                key={hour.hour}
                            >
                                {hour.hour}
                            </div>
                        );
                    })}
                </Swiper>
                <Button
                    className={classButtonPrev}
                    shape="circle"
                    disabled={isFirstSlide}
                    icon={<LeftOutlined className="swiper_button_icon" />}
                    onClick={goPrev}
                />
                <Button
                    className={classButtonNext}
                    shape="circle"
                    disabled={isLastSlide}
                    icon={<RightOutlined className="swiper_button_icon" />}
                    onClick={goNext}
                />
            </div>
        )
    );
};

export default SwiperTimeBox;
