import { Reducer } from 'redux';
import { ROLE_SET } from '../actionTypes/role';
import { Action } from '../actions/role';

export type IStateRole = {
    memberType: number;
    allowBookStylist: boolean;
    allowMultiple: boolean;
    daysBookFuture: number;
    maxDaysBookFuture: number;
    specialHours: {
        today: any;
        normal: any;
        weekend: any;
    };
    fetched: boolean;
};

const initialState = {
    memberType: 0,
    allowBookStylist: true,
    allowMultiple: false,
    daysBookFuture: 1,
    maxDaysBookFuture: 2,
    specialHours: {
        today: null,
        normal: null,
        weekend: null,
    },
    fetched: false
};

const role: Reducer<IStateRole, Action> = (
    state = initialState,
    action: Action,
) => {
    switch (action.type) {
        case ROLE_SET: {
            return action.role ? action.role : initialState;
        }

        default:
            return state;
    }
};

export default role;
