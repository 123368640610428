import React, { FC } from 'react';
import iconRight from 'images/fillBlue/chevronRight.svg';
import RedirectLink from '../redirectLink';
import '../index.scss';

const LayoutHeadTitle: FC<{ data: any }> = ({ data }) => {
    return (
        <RedirectLink redirect={data.linkViewAll}>
            <div className="layout__head">
                <div>
                    {data.title && (
                        <div className="text-xl md:text-[26px] font-bold uppercase pl-2 text-[#20489B] relative border-l-4 border-[#EF4E24]">
                            {data.title}
                        </div>
                    )}
                    {data.subTitle && (
                        <div className="text-sm md:text-lg font-normal mt-0.5">
                            {data.subTitle}
                        </div>
                    )}
                </div>
                {/* {data.linkViewAll && (
                    <div className="head__text-view-all">
                        <span>{data.textViewAll}</span>
                        <img src={iconRight} alt="" />
                    </div>
                )} */}
            </div>
        </RedirectLink>
    );
};

export default LayoutHeadTitle;
