import Client from "./client/TrackingClient";

export default {
    putTracking(data) {
        return Client.put(`api-tracking`, data);
    },
    postTrackingLocation({customerPhone, longitude, latitude}) {
        return Client.post(`api-tracking/tracking-customer/location`, {
            customerPhone, longitude, latitude
        });
    },
    postTracking(data) {
        return Client.post(`api-tracking`, data);
    },
    postError(data) {
        const listError = JSON.parse(localStorage.getItem('listError')) ? JSON.parse(localStorage.getItem('listError')) : [];
        const selected = JSON.parse(localStorage.getItem('selected'));
        const token = localStorage.getItem('token');
        data.phone = selected ? selected.customerPhone : data.phone;
        const check = listError && listError.find(i => { return i.message === data.message; });
        if (!check) {
            listError.push(data);
            data.tokenKey = token;
            localStorage.setItem('listError', JSON.stringify(listError));
            return Client.post(`api-tracking-error/booking`, data).then().catch(er => console.log(er));
        }
        return null;
    },
    postErrorTopup(data) {
        return Client.post(`api-tracking-error/booking`, data);
    }
};