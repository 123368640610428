import React, { useEffect, useState } from 'react';
import {
    formatPriceServiceToNum,
    formatPriceWithKCharacter,
    removeDuplicateGroupService,
} from 'utils/BookingUltils';
import iconChecked from 'images/icons/icon_checked_blue.svg';
import iconTick from 'images/icons/ic_tick.svg';
import { IService } from '../types/Service';

const ServiceItem: React.FC<{
    data: ServiceItemApplyType;
    chosenService: IService[];
    listAllServices: IService[];
    changeChosenServiceBooking: Function;
    campaignId: number | null;
}> = ({
    data,
    chosenService,
    listAllServices,
    changeChosenServiceBooking,
    campaignId,
}) => {
    const [serviceInList, setServiceInList] = useState<any>(null);

    const onSelectService = (selected: boolean) => {
        const {
            listService: listSubService,
            ...serviceWithoutSubService
        } = serviceInList;
        serviceWithoutSubService.price = formatPriceWithKCharacter(data.price);
        serviceWithoutSubService.oldPrice = formatPriceWithKCharacter(
            data.originPrice,
        );
        serviceWithoutSubService.priceNum = data.price;
        serviceWithoutSubService.oldPriceNum = data.originPrice;

        let newSelectedService = [];

        if (selected) {
            const listServiceWithOutDuplicate = removeDuplicateGroupService(
                chosenService,
                serviceInList,
            );

            newSelectedService = [
                ...listServiceWithOutDuplicate,
                serviceWithoutSubService,
            ];
        } else {
            newSelectedService = chosenService.filter(
                (selectedService) =>
                    selectedService.serviceId !== serviceInList.serviceId,
            );
            if (listSubService && listSubService.length > 0) {
                newSelectedService = newSelectedService.filter(
                    (selectedService) =>
                        selectedService.parentServiceId !==
                        serviceInList.serviceId,
                );
            }
        }
        changeChosenServiceBooking(newSelectedService);
    };

    useEffect(() => {
        if (data?.id && listAllServices.length) {
            let service = null;

            listAllServices.forEach((item: any) => {
                if (item.serviceId === data.id) {
                    service = item;
                }
            });
            const newData = {
                ...data,
                order: 1,
                categoryId: 0,
                serviceId: data.id,
                parentServiceId: null,
                comboDetail: [
                    {
                        id: data.id,
                        name: data.name,
                        type: 'service',
                        skillId: '0',
                    },
                ],
                group: 0,
                name: data.name,
                subtitle: null,
                description: null,
                timeServe: null,
                campaignId,
                oldPrice: formatPriceWithKCharacter(data.originPrice),
                price: formatPriceWithKCharacter(data.price),
                priceNum: data.price,
                selected: 0,
                tags: [],
                used: 0,
                noDisplay: false,
                hasSpecial: false,
                priceSpecialDay: null,
                oldPriceSpecialDay: null,
                priceSpecialDayNum: null,
                images: null,
                serviceImage:
                    'https://storage.30shine.com/ResourceWeb/data/images/Booking/booking-bạn-mac-dinh.jpg',
                hasDetail: false,
                allowAutoSelect: false,
                serviceSaleInfo: null,
            };
            setServiceInList(service || newData);
        }
    }, [listAllServices, data]);

    return (
        <div className="relative border border-[#D1D1D1] rounded-md overflow-hidden flex flex-col justify-between">
            <div className="cursor-pointer " role="presentation">
                <img
                    src={
                        data.serviceImage ||
                        'https://storage.30shine.com/ResourceWeb/data/images/Booking/booking-bạn-mac-dinh.jpg'
                    }
                    alt=""
                />
            </div>
            <div
                className="cursor-pointer text-xs md:text-sm text-[#111] font-semibold mt-1 mb-[5px] ml-2 mr-[5px] min-h-[44px]"
                role="presentation"
            >
                {data.name}
            </div>
            <div
                className="cursor-pointer text-[10px] md:text-xs text-[#5F5F5F] mb-2 mx-2"
                role="presentation"
            >
                {data.description}
            </div>
            <div className="mt-auto mb-2.5 mx-2 relative border border-[#20489B] rounded-[5px] py-1 cursor-pointer bg-[#F0F8FF]">
                <div className="text-[#20489B] text-xs font-semibold text-center">
                    Tiêu chuẩn
                </div>

                <img
                    src={iconTick}
                    alt=""
                    className="absolute top-[-1px] left-[-1px] transition-all rounded-l-[5px]"
                />
                <div className="text-sm font-semibold text-[#3D3D3D] text-center">
                    {data?.price >= 0
                        ? formatPriceWithKCharacter(data.price)
                        : 'Free'}
                    {data?.originPrice !== data?.price ? (
                        <span className="text-sm font-semibold text-[#5F5F5F] text-[#3D3D3D] line-through pl-2.5">
                            {formatPriceWithKCharacter(data?.originPrice)}
                        </span>
                    ) : null}
                </div>
            </div>
            <div
                className={`${
                    data?.selected
                        ? 'bg-white border-[#20489B] text-[#111]'
                        : 'bg-[#20489B] border-[#20489B] text-white hover:bg-white hover:text-[#111]'
                } border rounded-[3px] mx-2 mb-2 py-[6px] text-sm flex items-center justify-center gap-1 font-semibold transition cursor-pointer`}
                role="presentation"
                onClick={() => onSelectService(!data.selected)}
            >
                {data?.selected ? (
                    <>
                        <img src={iconChecked} alt="icon" className="w-4 h-4" />
                        Đã thêm
                    </>
                ) : (
                    'Thêm dịch vụ'
                )}
            </div>
        </div>
    );
};

export default ServiceItem;
