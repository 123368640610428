import uuid from 'uuid';
import { Reducer } from 'redux';
import { TRACKING_DATA_SET } from '../actionTypes/trackingData';
import { Action } from '../actions/trackingData';
import { getQuery } from '../components/func/Function';

const uuidv1 = uuid.v1;

export type IStateTrackingData = {
    phoneNumber: string;
    token: string;
    source: string;
    utmSource: string;
    customerId: number;
};

const initialState = (): IStateTrackingData => {
    const trackingData = {
        phoneNumber: '',
        token: '',
        source: '',
        utmSource: '',
        customerId: 0,
    };
    const params = getQuery(window.location.search);

    const customerId = localStorage.getItem('customerId') || 0;
    if (customerId) {
        trackingData.customerId = parseInt(customerId);
    }

    const phone = localStorage.getItem('customerPhone');
    if (phone) {
        trackingData.phoneNumber = phone.replace('+84', '0');
    }
    trackingData.token = uuidv1();
    if (params.source) trackingData.source = params.source;
    if (params.utm_source) trackingData.utmSource = params.utm_source;
    return trackingData;
};

const trackingData: Reducer<IStateTrackingData, Action> = (
    state = initialState(),
    action: Action,
) => {
    switch (action.type) {
        case TRACKING_DATA_SET: {
            return { ...state, ...action.payload };
        }

        default:
            return state;
    }
};

export default trackingData;
