import React, { FC, useEffect, useState, createRef } from 'react';
import BoxShadown from 'components/BoxShadown/BoxShadown';
import useOutside from 'hooks/useOutside';
import up from 'images/icons/caretRight.svg';
import down from 'images/icons/caretDown.svg';
import { IDistrict } from '../../types/CityDistrict';
import { IValue } from '../../types/Selected';

const SelectedDistrict: FC<{
    list: IDistrict[];
    onChange: Function;
    filterDistrict: IValue;
}> = ({ list = [], onChange, filterDistrict }) => {
    const [text, setText] = useState<string>(filterDistrict.name);
    const [isShow, setIsShow] = useState<boolean>(false);
    const refDiv = createRef<HTMLDivElement>();

    useOutside(refDiv, () => {
        if (isShow) setIsShow(false);
    });

    const onChangeItem = (item: IDistrict) => {
        setText(item.districtName);
        onChange({ id: item.districtId, name: item.districtName });
    };

    useEffect(() => {
        setText(filterDistrict.name);
    }, [filterDistrict]);

    const wapperClass = isShow ? 'block' : 'close';
    return (
        <div
            className="selected-district order-1"
            ref={refDiv}
            onClick={() => setIsShow((state) => !state)}
            role="presentation"
        >
            <div className="district">
                <div className="district__box">
                    <div className="text">{text}</div>
                    <div className="icon">
                        {isShow ? (
                            <img src={down} alt="" />
                        ) : (
                            <img src={up} alt="" />
                        )}
                    </div>
                </div>
                <BoxShadown isShow={isShow}>
                    <div className={`${wapperClass} district__dropdown`}>
                        <div
                            className="district__item flex space-between"
                            onClick={() =>
                                onChangeItem({
                                    districtId: 0,
                                    districtName: 'Tất cả Quận/Huyện',
                                })
                            }
                            role="presentation"
                        >
                            Tất cả Quận/Huyện
                        </div>
                        {list.length > 0 &&
                            list.map((item: IDistrict, key: number) => {
                                return (
                                    <div
                                        key={`${key + 1}`}
                                        className="district__item flex space-between"
                                        onClick={() => onChangeItem(item)}
                                        role="presentation"
                                    >
                                        <div>{item.districtName}</div>
                                    </div>
                                );
                            })}
                    </div>
                </BoxShadown>
            </div>
        </div>
    );
};

export default SelectedDistrict;
