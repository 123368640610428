import React from 'react';
import { useSelector } from 'react-redux';
import iconDown from 'images/icons/icon_angle_double_down.svg';
import iconRemove from 'images/icons/icon_close_blue.svg';
import { IService } from '../../types/Service';

const TotalServiceSelected: React.FC<{
    show: boolean;
    onClose: Function;
    chosenService: IService[];
    onSelectService: Function;
}> = ({ show, onClose, chosenService, onSelectService }) => {
    const common = useSelector((state: any) => state.common);

    return (
        <div className={`${show ? 'block' : 'hidden'} px-[18px] pt-3 bg-white`}>
            <div
                className="flex items-center justify-center text-xs gap-3 text-[#20489B] pt-1 pb-3 border-b border-[#E8E8E8] cursor-pointer"
                onClick={() => onClose()}
                role="presentation"
            >
                <img src={iconDown} alt="" />
                {common.lang === 'vi'
                    ? 'Ẩn dịch vụ đã chọn'
                    : 'Hide selected service'}{' '}
                <img src={iconDown} alt="" />
            </div>
            {chosenService.length ? (
                <div className="pt-3 border-b border-[#E8E8E8] text-sm">
                    {chosenService.map((service) => (
                        <div
                            key={service.serviceId}
                            className="pb-3 flex justify-between gap-3 text-[#20489B]"
                        >
                            <div>{service.name}</div>
                            <div className="flex items-center flex-none">
                                <span>
                                    {service.oldPrice
                                        ? service.oldPrice
                                        : service.price}
                                </span>
                                <img
                                    src={iconRemove}
                                    alt=""
                                    className="ml-[15px] cursor-pointer"
                                    onClick={() =>
                                        onSelectService(service, false)
                                    }
                                    role="presentation"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default TotalServiceSelected;
