import React, { createRef, FC, useEffect, useState } from 'react';
import { ISalon } from '../../types/Salon';
import ListSalon from './ListSalon';

const ListSalonCurrentPosition: FC<{
    list: ISalon[];
    onChange: Function;
    isParking: boolean;
}> = ({ list = [], onChange, isParking }) => {
    const [listData, setListData] = useState<ISalon[]>([]);
    const [limit, setLimit] = useState<number>(5);
    const refDiv = createRef<HTMLDivElement>();

    const handleExpand = () => {
        if (listData.length < list.length) {
            setLimit(limit + 5);
        }
    };

    useEffect(() => {
        if (list.length > 0) {
            const firstData = [...list].slice(0, limit);
            setListData(firstData);
        }
    }, [list, limit]);

    return (
        <div className="salon__nearly order-last" ref={refDiv}>
            <ListSalon
                list={listData}
                onChange={onChange}
                isSalonNear={true}
                isParking={isParking}
            />
            {list.length > listData.length ? (
                <div className="expand">
                    <div
                        className="expand__text"
                        onClick={() => {
                            handleExpand();
                        }}
                        role="presentation"
                    >
                        Xem thêm
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default ListSalonCurrentPosition;
