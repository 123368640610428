import React, { Fragment } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const antIcon = <LoadingOutlined style={{ fontSize: 27 }} spin />;

const MySpin = (props) => {
    return (
        <Fragment>
            {props.showSpin && (
                <div className="background-spin fixed ">
                    <div className="center-middle loading content-center-middle">
                        <Spin indicator={antIcon} />
                    </div>
                </div>
            )}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    const { showSpin } = state.common;
    return {
        showSpin,
    };
};

export default connect(mapStateToProps)(MySpin);
