import React, { FC, memo } from 'react';
import { ISalon } from '../../types/Salon';
import ListSalon from './ListSalon';
import SelectedDistrict from './SelectedDistrict';

const ListSalonSelectedDistrict: FC<{
    list: ISalon[];
    onChange: Function;
    listDistrict: any;
    filterDistrict: any;
    handleOnChangeDistrict: Function;
    isParking: boolean;
}> = ({
    list = [],
    onChange,
    listDistrict,
    filterDistrict,
    handleOnChangeDistrict,
    isParking,
}) => {
    return (
        <ListSalon list={list} onChange={onChange} isParking={isParking}>
            <SelectedDistrict
                list={listDistrict}
                onChange={handleOnChangeDistrict}
                filterDistrict={filterDistrict}
            />
        </ListSalon>
    );
};

export default ListSalonSelectedDistrict;
