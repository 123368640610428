import React, { FC } from 'react';
import moment from 'moment';
import 'moment/locale/vi';
import { ITimeBox } from '../../../types/DateTime';
import { useSelector } from 'react-redux';

const ItemBoxTime: FC<{
    bookingDate: string;
    timeBox: ITimeBox;
    isCheck: boolean;
    onChangeTime: Function;
    onHandleExpiredTime: Function;
    isHighLight: Boolean;
    grHour: any;
}> = ({
    bookingDate,
    timeBox,
    isCheck,
    onChangeTime,
    onHandleExpiredTime,
    isHighLight,
    grHour,
}) => {
    const wapperClass = `box-time_item ${
        !timeBox.isFree ? 'unavailable' : ''
    } ${isCheck ? 'has-choice' : ''} ${
        isHighLight ? 'time-box-high-light' : ''
    }`;

    const trackingData = useSelector((state: any) => state.trackingData);

    const timeInBox = moment(bookingDate, 'DD-MM-YYYY')
        .add(parseInt(timeBox.hour.split('h')[0]), 'hours')
        .add(parseInt(timeBox.hour.split('h')[1]), 'minutes');

    const isExpiredTime =
        timeBox.isFree && timeInBox.isBefore(moment(new Date()));

    const handleChangeTime = () => {
        const isTimeOpenBookAtSalon =
            trackingData.utmSource === 'book_at_salon' && timeBox.isFree;
        if (!isExpiredTime || isTimeOpenBookAtSalon) {
            onChangeTime(timeBox, grHour);
        } else {
            onHandleExpiredTime(timeBox.secondSubHourId);
        }
    };

    return (
        <div
            className={wapperClass}
            onClick={handleChangeTime}
            role="presentation"
        >
            {timeBox.hour}
        </div>
    );
};

export default ItemBoxTime;
