import React from 'react';
import LayoutHeadTitle from '../headTitle';
import '../index.scss';

const LayoutServiceGrid: React.FC<{
    data: any;
}> = ({ data }) => {
    return (
        <div className="layout ">
            <div className="container">
                <LayoutHeadTitle data={data} />
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                    {data.list &&
                        data.list.length > 0 &&
                        data.list.map((item: any, index: number) => {
                            return (
                                <div
                                    key={item.id}
                                    className="flex bg-white rounded-lg shadow"
                                >
                                    <img
                                        className={`flex-1 ${
                                            index % 2 === 0
                                                ? 'order-1'
                                                : 'order-2'
                                        }`}
                                        src={item.linkImage}
                                        alt=""
                                    />
                                    <div
                                        className={`p-4 ${
                                            index % 2 === 0
                                                ? 'order-2'
                                                : 'order-1'
                                        }`}
                                    >
                                        <div className="uppercase text-[#222222] text-sm md:text-base font-bold">
                                            {item.title}
                                        </div>
                                        {Array.isArray(item.content) ? (
                                            <div className="text-sm md:text-base">
                                                <ul className="list-none">
                                                    {item.content.map(
                                                        (el: string) => {
                                                            return (
                                                                <li>{el}</li>
                                                            );
                                                        },
                                                    )}
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className="text-sm md:text-base">
                                                {item.content}
                                            </div>
                                        )}
                                        {item.tag && (
                                            <div className="bg-[#EF4E24] text-white text-sm rounded-md px-2 inline-block mt-4">
                                                {item.tag}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default LayoutServiceGrid;
