import React, { useEffect, useState } from 'react';

const SentOtp: React.FC<{
    onGetOtp: Function;
    count: number;
    setCount: Function;
    postTracking: Function;
}> = ({ onGetOtp, count, setCount, postTracking }) => {
    const sendOtp = () => {
        if (count <= 0) {
            onGetOtp();
            postTracking('event');
        }
    };

    useEffect(() => {
        if (count > 0) {
            setTimeout(() => {
                setCount(count - 1);
            }, 1000);
        }
    }, [count]);

    const show = count > 0 ? 'hide' : '';

    return (
        <div className="sendOTP">
            <div>
                <span
                    className={`sendOTP__resend ${show}`}
                    onClick={sendOtp}
                    role="presentation"
                >
                    Gửi lại OTP
                </span>
                {count > 0 && (
                    <span className="sendOTP__count">sau {count} giây</span>
                )}
            </div>
        </div>
    );
};

export default SentOtp;
