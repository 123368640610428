import BookingApi from './BookingApi';
import TrackingApi from './TrackingApi';
import BookingAppApi from './BookingAppApi';
import AuthenticApi from './AuthenticApi';
import BLogStyleApi from './HomeApi';
import StoreApi from './StoreApi';
import ProductApi from './productApi';
import trackingDynamicApi from './TrackingDynamicApi';
import lookBookApi from './LookBookAPI';
import SavingLocationApi from './SavingLocationApi';
import SearchAddressApi from './SearchAddressApi';
import RewardApi from './RewardApi';
import HistoryCustomer from './HistoryCustomer';
import LuckyWheel from './LuckyWheelApi';
import StyleMaster from './StyleMasterApi';
import CustomerCampaignHistory from './CustomerCampaignHistoryApi';
import StaffViewApi from './StaffViewApi';
import LuckyWheelCustomerInsight from './LuckyWheelCustomerInsightApi';
import CompetitionApi from './CompetitionApi';

export default {
    ...AuthenticApi,
    ...BLogStyleApi,
    ...BookingApi,
    ...TrackingApi,
    ...BookingAppApi,
    ...StoreApi,
    ...ProductApi,
    ...trackingDynamicApi,
    ...lookBookApi,
    ...SavingLocationApi,
    ...SearchAddressApi,
    ...RewardApi,
    ...HistoryCustomer,
    ...LuckyWheel,
    ...StyleMaster,
    ...CustomerCampaignHistory,
    ...StaffViewApi,
    ...LuckyWheelCustomerInsight,
    ...CompetitionApi,
};
