import React, { FC } from 'react';
import right from 'images/icons/caretRight.svg';
import down from 'images/icons/caretDown.svg';
import iconSalon from 'images/fillGray/homeOutline.svg';
import iconService from 'images/fillGray/service.svg';
import iconCalendar from 'images/fillGray/calendar-2.svg';

const Selected: FC<{
    type: string;
    show?: boolean;
    message: string;
    placeholder: string;
    value: any;
    onHandleClick: Function;
    required: boolean;
}> = ({
    type,
    show = false,
    message,
    placeholder,
    value,
    onHandleClick,
    required = false,
}) => {
    const renderLeftIcon = (type: string) => {
        switch (type) {
            case 'salon':
                return iconSalon;
            case 'service':
                return iconService;
            case 'datebook':
                return iconCalendar;
            default:
                return undefined;
        }
    };

    return (
        <>
            <div
                className={`cursor-pointer flex item-center bg-f7f7f7 h-11 rounded px-2.5 ${
                    message ? 'border border-red-f46969' : ''
                }`}
                onClick={() => onHandleClick()}
                aria-hidden="true"
            >
                <div className="flex relative mr-2.5 md:mr-3.5">
                    <img src={renderLeftIcon(type)} alt="" />
                    {required ? (
                        <div className="absolute flex w-2 h-2 -right-2 -top-0">
                            <div className="absolute inline-flex w-2 w-full h-2 h-full rounded-full opacity-75 ping-fast bg-red-ea1601" />
                            <div className="relative inline-flex w-2 h-2 rounded-full bg-red-ea1601" />
                        </div>
                    ) : null}
                </div>
                <div
                    className={`pr-0.5 overflow-hidden whitespace-nowrap overflow-ellipsis w-full text-sm color-${
                        value ? '111111' : 'a3a3a3'
                    }`}
                >
                    {value || placeholder}
                </div>
                <div className="ml-auto w-2.5">
                    <img src={show ? down : right} alt="" />
                </div>
            </div>
            {message ? (
                <div className="color-red-f46969 text-xs mt-1.5">{message}</div>
            ) : null}
        </>
    );
};

export default Selected;
