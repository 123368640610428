import React, { useEffect, useState, FC, useRef } from 'react';
import API from 'repository';
import moment from 'moment';
// import { Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import trackingConst from 'config/TrackingConst';
import iconNote from 'images/icons/icon_note.svg';
// import iconCamera from 'images/icons/icon_camera.svg';
import iconAdvice from 'images/icons/icon_advice.svg';
// import iconSkinner from 'images/icons/icon_skinner.svg';
// import iconDown from 'images/icons/chevronDown.svg';
// import iconMustache from 'images/icons/icon_mustache.svg';
import { IService } from '../../types/Service';
import ItemCheck from './components/ItemCheck';
import ModalConfirmAdviceService from './components/ModalConfirmAdviceService';
import './styles.scss';

// const { Option } = Select;
interface Props {
    salonId: Number;
    onChangeExtension: Function;
    trackingData: any;
    listServiceSelected: IService[];
    bookManyPeople: string;
    datebook: string;
    note: string;
    listSkinner: any;
    skinnerId: number;
    selectConsultation: boolean;
}

const today = moment(new Date()).format('DD-MM-YYYY');

const Extension: FC<Props> = ({
    salonId = 0,
    onChangeExtension,
    trackingData,
    listServiceSelected,
    bookManyPeople,
    datebook,
    note,
    listSkinner,
    skinnerId,
    selectConsultation,
}) => {
    const [params, setParams] = useState({
        receiveAdvice: true,
        visible: false,
        utilityService: [],
        bookWithChild: false,
        acceptMakePhoto: true,
        isManyPeople: false,
        muonCaoMat: false,
    });

    const inputRef = useRef<any>(null);

    async function postTrackingEvent(data: any) {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    }

    async function postTracking(eventId: any) {
        const data = {
            data: {
                customerId: trackingData.customerId,
                phone: trackingData.phoneNumber,
                salonId: salonId.toString(),
                chosenServices: JSON.stringify(listServiceSelected),
                utilityService: JSON.stringify(params.utilityService),
                skinnerId: skinnerId.toString(),
            },
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
        };
        try {
            await postTrackingEvent(data);
        } catch (e) {
            console.log(e.message);
        }
    }

    const onChangeNote = (e: any) => {
        const { value } = e.target;
        onChangeExtension('note', value);
    };

    const onSubmitNote = (e: any) => {
        e.target.blur();
    };

    const onFocusNote = () => {
        postTracking(trackingConst.Events.CLICK_FORM_NOTE);
    };

    const onChangeData = (key: string, value: any) => {
        const data = { ...params, [key]: value };
        setParams(data);
        if (key === 'isManyPeople') {
            let eventId = '';
            if (value) {
                onChangeExtension('numberPersons', 2);
                eventId =
                    trackingConst.Events.SELECT_ON_CHECKBOX_BOOK_MANY_PEOPLE;
            } else {
                onChangeExtension('numberPersons', 0);
                eventId =
                    trackingConst.Events.SELECT_OFF_CHECKBOX_BOOK_MANY_PEOPLE;
            }
            postTracking(eventId);
        } else {
            onChangeExtension([key], value);
        }
    };

    const handleok = () => {
        const data = { ...params, visible: false, receiveAdvice: false };
        setParams(data);
        onChangeExtension('receiveAdvice', false);
        onChangeExtension('littleAdvice', false);
    };

    const onCancel = () => {
        const data = { ...params, visible: false, receiveAdvice: true };
        setParams(data);
        onChangeExtension('receiveAdvice', true);
        onChangeExtension('littleAdvice', true);
    };

    const showModal = () => {
        if (!params.receiveAdvice) {
            const data = { ...params, receiveAdvice: true };
            setParams(data);
            onChangeExtension('receiveAdvice', true);
        } else {
            setParams({ ...params, visible: true });
        }
    };

    const isShowCheckBoxManyPeople = () => {
        switch (bookManyPeople) {
            case 'off':
                return false;
            case 'all':
                return true;
            case 'notToday':
                if (today === datebook) {
                    return false;
                }
                return true;
            default:
                return false;
        }
    };

    // const handleChange = (value: any) => {
    //     onChangeExtension('skinnerId', value);
    //     const data = {
    //         data: {
    //             customerId: trackingData.customerId,
    //             phone: trackingData.phoneNumber,
    //             salonId: salonId.toString(),
    //             chosenServices: JSON.stringify(listServiceSelected),
    //             utilityService: JSON.stringify(params.utilityService),
    //             skinnerId: value.toString(),
    //         },
    //         pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
    //         screenId:
    //             trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
    //         eventId: trackingConst.Events.CLICK_SELECT_SKINNER,
    //         tokenKey: trackingData.token,
    //         source: trackingData.source,
    //         utmSource: trackingData.utmSource,
    //     };
    //     postTrackingEvent(data);
    // };

    // const onDropdownVisibleChange = (open: boolean) => {
    //     if (open) {
    //         postTracking(trackingConst.Events.CLICK_SHOW_LIST_SKINNER);
    //     } else {
    //         postTracking(trackingConst.Events.CLOSE_LIST_SKINNER);
    //     }
    // };

    useEffect(() => {
        if (!params.receiveAdvice) {
            // TRACKING
            postTracking(trackingConst.Events.SELECT_CHECKBOX_REJECT_ADV);
        }
    }, [params.receiveAdvice]);

    useEffect(() => {
        if (!params.acceptMakePhoto) {
            // TRACKING
            postTracking(
                trackingConst.Events.SELECT_CHECKBOX_REJECT_MAKE_PHOTO,
            );
        }
    }, [params.acceptMakePhoto]);

    useEffect(() => {
        if (params.utilityService.length > 0) {
            // TRACKING
            postTracking(trackingConst.Events.CLICK_SELECT_UTILITY_SERVICE);
        }
    }, [params.utilityService]);

    useEffect(() => {
        if (listSkinner.length) {
            const isHasSkinnerId =
                [...listSkinner].filter((item) => item.staffId === skinnerId)
                    .length > 0;
            if (!isHasSkinnerId) {
                onChangeExtension('skinnerId', 0);
            }
        }
    }, [listSkinner, skinnerId]);

    return (
        <div className="extension mg-top-20">
            <>
                <div className="mt-5 note">
                    <div className="flex items-center f-be-pro font-medium text-base mb-1.5">
                        <img src={iconNote} alt="" className="w-6 h-6 mr-2" />
                        Yêu cầu đặc biệt
                    </div>
                    <div className="note__input">
                        <TextArea
                            ref={inputRef}
                            value={note}
                            onChange={(e) => onChangeNote(e)}
                            placeholder="VD: Gãi kỹ phần gáy, Sấy tạo kiểu,..."
                            onPressEnter={onSubmitNote}
                            onFocus={onFocusNote}
                        />
                    </div>
                </div>
                {/* {today === datebook ? (
                    <div className="skinner">
                        <div className="flex flex-wrap items-center f-be-pro font-medium text-base mb-1.5">
                            <img
                                src={iconSkinner}
                                alt=""
                                className="w-6 h-6 mt-5 mr-2"
                            />
                            <div className="mr-0.5 mt-5">
                                Cố gắng sắp xếp cho bạn skinner
                            </div>
                            <div className="flex items-center mt-1 ml-8 md:mt-5">
                                <Select
                                    value={skinnerId}
                                    style={{ width: 120 }}
                                    onChange={(value) => handleChange(value)}
                                    dropdownClassName="skinner-dropdown"
                                    onDropdownVisibleChange={(open) =>
                                        onDropdownVisibleChange(open)
                                    }
                                    dropdownMatchSelectWidth={false}
                                >
                                    <Option value={0}>Tốt nhất</Option>
                                    {listSkinner.map((item: any) => (
                                        <Option
                                            value={item.staffId}
                                            key={item.staffId}
                                        >
                                            {item.staffName.toLowerCase()}
                                        </Option>
                                    ))}
                                </Select>
                                <div className="ml-2">nhé!</div>
                            </div>
                        </div>
                    </div>
                ) : null} */}
                {isShowCheckBoxManyPeople() ? (
                    <ItemCheck
                        checked={params.isManyPeople}
                        onChange={() =>
                            onChangeData('isManyPeople', !params.isManyPeople)
                        }
                        text={
                            params.isManyPeople
                                ? 'Yêu cầu của bạn sẽ được chuyển cho salon ngay sau khi đặt lịch thành công. Sẽ có nhân viên liên lạc trước ít nhất 30 phút để sắp xếp lịch cho từng người.'
                                : 'Lịch đặt hiện tại chỉ dành cho một người'
                        }
                        title="Đặt nhiều người"
                    />
                ) : null}
                {!selectConsultation ? (
                    <ItemCheck
                        checked={params.receiveAdvice}
                        onChange={showModal}
                        text={`Bạn ${
                            !params.receiveAdvice ? 'không' : ''
                        } cho phép các
                            chúng mình giới thiệu về chương trình khuyến mãi, dịch vụ
                            tốt nhất dành cho bạn`}
                        title="Yêu cầu tư vấn"
                        icon={iconAdvice}
                    />
                ) : null}
                {/* <ItemCheck
                    checked={params.acceptMakePhoto}
                    onChange={() =>
                        onChangeData('acceptMakePhoto', !params.acceptMakePhoto)
                    }
                    text={
                        params.acceptMakePhoto
                            ? 'Bạn cho phép chụp hình lưu lại kiểu tóc, để lần sau không phải mô tả lại cho thợ khác'
                            : 'Bạn không cho phép chụp ảnh, không cần thợ lần sau biết'
                    }
                    title="Chụp hình sau khi cắt"
                    icon={iconCamera}
                /> */}
                {/* <ItemCheck
                    checked={params.muonCaoMat}
                    onChange={() =>
                        onChangeData('muonCaoMat', !params.muonCaoMat)
                    }
                    text={
                        params.muonCaoMat
                            ? 'Bạn muốn được cạo mặt, cạo râu bằng dao lam'
                            : 'Bạn không cần cạo mặt, cạo râu bằng dao lam'
                    }
                    title="Cạo mặt bằng dao lam"
                    icon={iconMustache}
                /> */}
            </>
            <ModalConfirmAdviceService
                visible={params.visible}
                onCancel={onCancel}
                onOk={handleok}
            />
        </div>
    );
};

export default Extension;
