import React, { Fragment, useState, useRef, useEffect } from 'react';
import './style.scss';
import MediaQuery from 'react-responsive';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Swiper } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';

const Content = (props) => {
    const [swiper, updateSwiper] = useState(null);
    const swiperRef = useRef(null);

    const goNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
            setIsFirstSlide(swiperRef.current.swiper.isBeginning);
            setIsLastSlide(swiperRef.current.swiper.isEnd);
        }
    };

    const [isFirstSlide, setIsFirstSlide] = useState(true);
    const [isLastSlide, setIsLastSlide] = useState(false);

    const handleSlideInit = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        setIsLastSlide(swiperRef.current.swiper.isEnd);
    };

    const handleSlideChange = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        if(Object.prototype.hasOwnProperty.call(props, "onSlideChange")){
            props.onSlideChange(swiperRef.current.swiper);
        }
    };

    const handleTouchChange = () => {
        setIsFirstSlide(swiperRef.current.swiper.isBeginning);
        setIsLastSlide(swiperRef.current.swiper.isEnd);
    };

    const classButtonNext = isLastSlide
        ? 'btn-action right btn_hide'
        : 'btn-action right';
    const classButtonPrev = isFirstSlide
        ? 'btn-action left btn_hide'
        : 'btn-action left';

    return (
        <div className='my-swiper'>
            <ReactIdSwiperCustom
                on={{
                    init: handleSlideInit,
                    touchMove: handleTouchChange,
                    slideChange: handleSlideChange,
                }}
                Swiper={Swiper}
                {...props}
                getSwiper={updateSwiper}
                ref={swiperRef}
            >
                {props.children}
            </ReactIdSwiperCustom>
            {props.pc && !props.notNavigation && (
                <Fragment>
                    <div className={classButtonPrev}>
                        <LeftOutlined onClick={goPrev} />
                    </div>
                    <div className={classButtonNext}>
                        <RightOutlined onClick={goNext} />
                    </div>
                </Fragment>
            )}
        </div>
    );
};

Content.defaultProps = {
    onSlideChange: () => {},
};

const MySwiper = (props) => {
    return (
        <Fragment>
            <MediaQuery minWidth={1024}>
                <Content
                    slidesPerView={props.large ? props.large : 4.25}
                    {...props}
                    pc
                />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
                <Content
                    slidesPerView={props.medium ? props.medium : 3.25}
                    {...props}
                    pc
                />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <Content
                    slidesPerView={props.small ? props.small : 2.25}
                    {...props}
                    pc={false}
                />
            </MediaQuery>
        </Fragment>
    );
};

export default MySwiper;
