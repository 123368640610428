import { combineReducers } from 'redux';
import trackingData from './trackingData';
import salon from './salon';
import user from './user';
import banner from './banner';
import role from './role';
import booking from './booking';
import common from './common';

const rootReducer = combineReducers({
    trackingData,
    salon,
    user,
    dataBanner: banner,
    role,
    booking,
    common,
});

export default rootReducer;
