import React, { FC } from 'react';
import { Modal } from 'antd';

const ModalConfirmAdviceService: FC<{
    visible: boolean;
    onCancel: Function;
    onOk: Function;
}> = ({ visible, onCancel, onOk }) => {
    return (
        <Modal
            className="modal-checkbox"
            title="CÓ VẺ BẠN ĐANG GẶP VẤN ĐỀ VỀ TƯ VẤN?"
            visible={visible}
            footer={null}
            maskClosable={false}
            width={420}
            closable={false}
        >
            <div className="text-checkbox">
                Liệu có phải bạn đang rất cần sự yên tĩnh, bạn chỉ muốn nghe các
                thông tin khuyến mãi hữu ích cho mình
            </div>
            <div
                className="btn btn-top"
                onClick={() => onCancel()}
                role="presentation"
            >
                Có thể tư vấn, nhưng ít thôi
            </div>
            <div
                className="btn btn-bottom"
                onClick={() => onOk()}
                role="presentation"
            >
                Chắc chắn không muốn nghe tư vấn
            </div>
        </Modal>
    );
};

export default ModalConfirmAdviceService;
