/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { scrollToSmoothly } from 'utils/ScrollSmooth';

const SuggestBooking: FC<{
    handleClick: Function;
    data: any;
    text?: any;
}> = ({
    handleClick,
    data,
    text = 'Ngày mai còn nhiều khung giờ trống. Click để xem ngay bạn nhé!',
}) => {
    const scrollToSelectListSalonBackup = () => {
        const element = window.document.querySelector(
            '#listSalonBackUpAttrId',
        ) as HTMLDivElement;
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const pos = elementPosition ? elementPosition - 50 : 0;
            scrollToSmoothly(pos, 300);
        }
    };

    return (
        <div
            className={`flex item-center ${
                data?.length > 0 ? 'suggestion-salon__date' : ''
            }`}
            onClick={() => {
                handleClick();
                scrollToSelectListSalonBackup();
            }}
            role="presentation"
        >
            <div className="suggestion-salon__text">{text}</div>
        </div>
    );
};

export default SuggestBooking;
