/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import Modal from 'antd/lib/modal/Modal';
import KEY from '../../Const';
import { ITimeBox, IHour } from '../../types/DateTime';
import { ISelected, ISelectedSalonBackUp } from '../../types/Selected';
import SalonBackUp from './components/SalonBackUp';
import './styles.scss';
import SuggestBooking from './components/SuggestBooking';
import TitleSuggestSalon from './TitleSuggestSalon';

const TODAY = moment(new Date()).format('DD-MM-YYYY');

export type ISalonBackUP = {
    salonId: number;
    latitude: number;
    longitude: number;
    name: string;
    distance: number;
};

const HoursSalonBackUp: FC<{
    selected: ISelected;
    selectedSalonBackUp: ISelectedSalonBackUp;
    onChangeSelectedBackUp: Function;
    onChangeSelected: Function;
    scrollToSelectDateBook: Function;
    listSalon: any;
    trackingData: any;
    children?: any;
    onChangeSalon: Function;
}> = ({
    selected,
    selectedSalonBackUp,
    onChangeSelectedBackUp,
    onChangeSelected,
    scrollToSelectDateBook,
    trackingData,
    listSalon,
    children,
    onChangeSalon,
}) => {
    const [isShowSalonBackup, setIsShowSalonBackup] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [show, setShow] = useState(false);
    const [showPopupChangeService, setShowPopupChangeService] = useState(false);
    const [selectedSalon, setSelectedSalon] = useState<any>(null);

    const commonDataTracking = {
        pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
        screenId: trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
        tokenKey: trackingData.token,
        source: trackingData.source,
        utmSource: trackingData.utmSource,
    };

    const dataTracking = {
        customerId: trackingData.customerId || 0,
        phone: selected[KEY.PHONE],
        salonId: selected[KEY.SALON].id.toString(),
        chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
        dateBook: selected[KEY.DATE_BOOK],
        stylistId: selected[KEY.STYLIST].id.toString(),
        hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
        hourBook: selected[KEY.HOUR].hour,
    };

    const postTracking = async (data: any) => {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const onChangeSalonNotSaveService = () => {
        postTracking({
            ...commonDataTracking,
            data: {
                customerId: trackingData.customerId || 0,
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id,
                extendData: {
                    selectedSalonBackUp: selectedSalon,
                },
            },
            eventId:
                trackingConst.Events.CLICK_CHANGE_SALON_SUGGESTED_SALON_BACKUP,
        });
        const salonSelected = listSalon.find(
            (salonItem: any) => salonItem.id === selectedSalon?.salonId,
        );
        onChangeSalon(selectedSalon, 2);
    };

    const onCancel = () => {
        setShowPopupChangeService(false);
        postTracking({
            ...commonDataTracking,
            data: {
                customerId: trackingData.customerId || 0,
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id,
                extendData: {
                    selectedSalonBackUp: selectedSalon,
                },
            },
            eventId:
                trackingConst.Events
                    .CLICK_CANCEL_CHANGE_SALON_SUGGESTED_SALON_BACKUP,
        });
        setSelectedSalon(null);
    };

    const resetSelectedBackUp = () => {
        onChangeSelectedBackUp([KEY.HOUR], {
            bookHourId: 0,
            subHourId: 0,
            secondSubHourId: 0,
            hour: '',
        });
        onChangeSelectedBackUp([KEY.SALON], {
            id: 0,
            name: '',
            openingDate: '',
        });
    };

    const getListSalonSuggest = async () => {
        if (!loading) {
            setLoading(true);
            const listServiceIDs = selected[KEY.CHOSEN_SERVICES].map(
                (service) => service.serviceId,
            );
            const stringServiceIds = listServiceIDs.join(',');
            try {
                const data: any = await API.getListBookHoursBackup({
                    salonId: selected[KEY.SALON].id,
                    bookDate: selected[KEY.DATE_BOOK],
                    choosenServices: stringServiceIds,
                });
                if (data.statusCode === 0 && data.data.length > 0) {
                    setData(data);
                }
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
    };

    const handleClickSalonSuggest = async () => {
        postTracking({
            ...commonDataTracking,
            data: dataTracking,
            eventId: trackingConst.Events.CLICK_SUGGESTED_SALON_BACKUP,
        });
        setIsShowSalonBackup(true);
    };

    const handleClickDateBook = async () => {
        scrollToSelectDateBook();
        postTracking({
            ...commonDataTracking,
            data: dataTracking,
            eventId:
                trackingConst.Events
                    .CLICK_SHOW_DATE_BOOK_FROM_SUGGESTED_SALON_BACKUP,
        });
    };

    const onChangeTime = (
        hour: ITimeBox,
        salon: ISalonBackUP,
        isSaveServiceChoosen: boolean,
    ) => {
        const salonSelected = listSalon.find(
            (salonItem: any) => salonItem.id === salon?.salonId,
        );
        setSelectedSalon(salonSelected);
        postTracking({
            ...commonDataTracking,
            data: {
                customerId: trackingData.customerId || 0,
                phone: selected[KEY.PHONE],
                salonId: salon.salonId,
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id,
                hourBook: hour.hour,
                extendData: {
                    salonBackUp: salon,
                    hourSalonBackup: hour.hour,
                },
            },
            eventId:
                trackingConst.Events
                    .CLICK_SELECT_HOUR_IN_SUGGESTED_SALON_BACKUP,
        });
        // co nhieu case dich vu salon backup khac dich vu salon hien tai => hien thi popup xac nhan doi salon
        setShowPopupChangeService(true);
        return;
        if (!isSaveServiceChoosen) {
            setShowPopupChangeService(true);
            return;
        }
        // chon gio salon
        if (hour.isFree) {
            const newhour: IHour = {
                bookHourId: hour.hourId,
                subHourId: hour.subHourId,
                secondSubHourId: hour.secondSubHourId,
                hour: hour.hour,
                hourFrame: hour.hourFrame,
            };

            onChangeSelectedBackUp([KEY.HOUR], newhour);
            onChangeSelectedBackUp([KEY.SALON], {
                id: salon.salonId,
                name: salon.name,
                openingDate: '',
            });

            onChangeSelected([KEY.HOUR], {
                bookHourId: 0,
                subHourId: 0,
                secondSubHourId: 0,
                hour: '',
            });
            return;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 5000);
        return () => {
            resetSelectedBackUp();
        };
    }, []);

    useEffect(() => {
        if (selected[KEY.HOUR].bookHourId > 0) {
            resetSelectedBackUp();
        }
    }, [selected[KEY.HOUR]]);

    useEffect(() => {
        if (selected[KEY.STYLIST].id > 0) {
            setIsShowSalonBackup(false);
        }
    }, [selected[KEY.STYLIST]]);

    useEffect(() => {
        if (
            selected[KEY.CHOSEN_SERVICES].length > 0 &&
            selected[KEY.STYLIST].id === 0 &&
            selected[KEY.DATE_BOOK] === TODAY
        ) {
            getListSalonSuggest();
        } else {
            resetSelectedBackUp();
            setData([]);
            setIsShowSalonBackup(false);
        }
    }, [
        selected[KEY.DATE_BOOK],
        selected[KEY.STYLIST],
        selected[KEY.CHOSEN_SERVICES],
    ]);

    return (
        <>
            {selected[KEY.DATE_BOOK] === TODAY && show && !loading ? (
                <div className="block suggestion-salon">
                    {!isShowSalonBackup && (
                        <div className="mt-2">
                            <TitleSuggestSalon
                                data={data.data}
                                handleClickSalonSuggest={
                                    handleClickSalonSuggest
                                }
                                selected={selected}
                                handleClickDateBook={handleClickDateBook}
                            />
                        </div>
                    )}
                </div>
            ) : null}
            {children}
            <div id={data?.data?.length > 0 ? 'listSalonBackUpAttrId' : ''}>
                {selected[KEY.DATE_BOOK] === TODAY && isShowSalonBackup && (
                    <>
                        {data?.data?.length > 0 && (
                            <div className="suggestion-salon__list">
                                <div className="list__item">
                                    {data.data.map((item: any) => (
                                        <SalonBackUp
                                            salon={item.salon}
                                            hours={item.hours}
                                            onChangeTime={(hourSelected: any) =>
                                                onChangeTime(
                                                    hourSelected,
                                                    item.salon,
                                                    item.isSaveServiceChoosen,
                                                )
                                            }
                                            selected={selectedSalonBackUp}
                                            key={item.salon.salonId}
                                            listSalon={listSalon}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                        <SuggestBooking
                            handleClick={handleClickDateBook}
                            data={data.data}
                        />
                    </>
                )}
            </div>
            <Modal
                className="modal-change-service-salon-backup"
                title={
                    <div>
                        BẠN CHẮC CHẮN CHỌN{' '}
                        <span className="whitespace-nowrap">
                            SALON {selectedSalon?.name}
                        </span>
                    </div>
                }
                visible={showPopupChangeService}
                footer={null}
                onCancel={() => onCancel()}
            >
                <div style={{ marginBottom: 15 }}>
                    <div>
                        Bạn chọn <b>TIẾP TỤC</b> để chọn dịch vụ tại salon{' '}
                        <b className="whitespace-nowrap">
                            {selectedSalon?.name}
                        </b>
                        .
                    </div>
                    <div>
                        Chọn <b>HỦY</b> để trở về salon{' '}
                        <b>{selected.salon.name}</b>
                    </div>
                </div>
                <div className="flex items-center">
                    <div
                        className="btn-cancel h2 br-4 padding-20 pointer"
                        onClick={() => onCancel()}
                    >
                        HUỶ
                    </div>
                    <div
                        className="btn-action"
                        onClick={() => onChangeSalonNotSaveService()}
                    >
                        TIẾP TỤC
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: any) => {
    const { trackingData, salon } = state;
    return { trackingData, listSalon: salon.listSalon };
};

export default connect(mapStateToProps)(HoursSalonBackUp);
