export default {
    // home
    GO_TO_HOME_PAGE: 'GO_TO_HOME_PAGE',
    CLICK_BUTTON_EXPLORE: 'CLICK_BUTTON_EXPLORE',
    CLICK_SHOW_ALL_SERVICE: 'CLICK_SHOW_ALL_SERVICE',
    CLICK_SHOW_DETAIL_SERVICE: 'CLICK_SHOW_DETAIL_SERVICE',
    CLICK_SHOW_ALL_PRODUCT: 'CLICK_SHOW_ALL_PRODUCT',
    CLICK_SHOW_DETAIL_PRODUCT: 'CLICK_SHOW_DETAIL_PRODUCT',
    CLICK_SHOW_ALL_SALON: 'CLICK_SHOW_ALL_SALON',
    CLICK_SHOW_DETAIL_SALON: 'CLICK_SHOW_DETAIL_SALON',
    CLICK_SHOW_SHINE_MEMBER: 'CLICK_SHOW_SHINE_MEMBER',
    CLICK_SHOW_ALL_REWARD: 'CLICK_SHOW_ALL_REWARD',
    CLICK_DIRECT_SALON: 'CLICK_DIRECT_SALON',
    CLICK_DIRECT_OTO: 'CLICK_DIRECT_OTO',
    CLICK_CHANGE_CANCEL_BOOKING: 'CLICK_CHANGE_CANCEL_BOOKING',
    CLICK_BUTTON_BOOKING: 'CLICK_BUTTON_BOOKING',
    SHOW_POPUP_BANNER_LUCKY_WHEEL: 'SHOW_POPUP_BANNER_LUCKY_WHEEL',
    CLICK_POPUP_BANNER_LUCKY_WHEEL: 'CLICK_POPUP_BANNER_LUCKY_WHEEL',
    CLOSE_POPUP_BANNER_LUCKY_WHEEL: 'CLOSE_POPUP_BANNER_LUCKY_WHEEL',

    // BOOKING Event
    GO_TO_BOOKING_SELECT_SALON_SCREEN: 'GO_TO_BOOKING_SELECT_SALON_SCREEN',
    INPUT_SEARCH_SALON: 'INPUT_SEARCH_SALON',
    CLICK_FIND_SALON_NEAR: 'CLICK_FIND_SALON_NEAR',
    CLICK_SELECT_PROVINCE: 'CLICK_SELECT_PROVINCE',
    CLICK_SELECT_SALON: 'CLICK_SELECT_SALON',
    CLICK_FILTER_CAR_PARKING: 'CLICK_FILTER_CAR_PARKING',
    SWIPE_NEXT_SALON: 'SWIPE_NEXT_SALON',
    SWIPE_PREV_SALON: 'SWIPE_PREV_SALON',
    SWIPE_NEXT_IN_SUGGEST_SERVICE: 'SWIPE_NEXT_IN_SUGGEST_SERVICE',
    SWIPE_PREV_IN_SUGGEST_SERVICE: 'SWIPE_PREV_IN_SUGGEST_SERVICE',
    SWIPE_NEXT_IN_LAST_SERVICE: 'SWIPE_NEXT_IN_LAST_SERVICE',
    SWIPE_PREV_IN_LAST_SERVICE: 'SWIPE_PREV_IN_LAST_SERVICE',
    SWIPE_NEXT_IN_SHINE_COMBO: 'SWIPE_NEXT_IN_SHINE_COMBO',
    SWIPE_PREV_IN_SHINE_COMBO: 'SWIPE_PREV_IN_SHINE_COMBO',
    SWIPE_NEXT_IN_CURLING_DYE: 'SWIPE_NEXT_IN_CURLING_DYE',
    SWIPE_PREV_IN_CURLING_DYE: 'SWIPE_PREV_IN_CURLING_DYE',
    SWIPE_NEXT_IN_SKIN_CARE: 'SWIPE_NEXT_IN_SKIN_CARE',
    SWIPE_PREV_IN_SKIN_CARE: 'SWIPE_PREV_IN_SKIN_CARE',
    CLICK_SAVING_LOCATION: 'CLICK_SAVING_LOCATION',
    EXIT_INPUT_LOCATION_WITHOUT_SAVING: 'EXIT_INPUT_LOCATION_WITHOUT_SAVING',
    SWITCH_CAR_PARKING: 'SWITCH_CAR_PARKING',
    CLICK_TIME_OFF: 'CLICK_TIME_OFF',
    CLICK_SELECT_TIME: 'CLICK_SELECT_TIME',
    CLICK_SHOW_STYLIST: 'CLICK_SHOW_STYLIST',
    CLICK_CLOSE_STYLIST: 'CLICK_CLOSE_STYLIST',
    CLICK_SELECT_OLD_STYLIST: 'CLICK_SELECT_OLD_STYLIST',
    SHOW_AFFIX_BOOKING_INFORMATION: 'SHOW_AFFIX_BOOKING_INFORMATION',
    HIDE_AFFIX_BOOKING_INFORMATION: 'HIDE_AFFIX_BOOKING_INFORMATION',
    SHOW_ERROR_NO_CHOICE_SERVICE: 'SHOW_ERROR_NO_CHOICE_SERVICE',

    GO_TO_BOOKING_SELECT_SERVICE_SCREEN: 'GO_TO_BOOKING_SELECT_SERVICE_SCREEN',
    SCROLL_TO_CATEGORY: 'SCROLL_TO_CATEGORY',
    CLICK_SELECT_SERVICE: 'CLICK_SELECT_SERVICE',
    CLICK_REMOVE_SERVICE: 'CLICK_REMOVE_SERVICE',
    CLICK_SHOW_DETAIL_SERVICE_SHINE_COMBO:
        'CLICK_SHOW_DETAIL_SERVICE_SHINE_COMBO',
    SHOW_SUGGEST_SERVICE: 'SHOW_SUGGEST_SERVICE',
    SHOW_POPUP_SUGGEST_SERVICE: 'SHOW_POPUP_SUGGEST_SERVICE',
    CLOSE_POPUP_SUGGEST_SERVICE: 'CLOSE_POPUP_SUGGEST_SERVICE',
    CLICK_BUTTON_CLOSE_IN_POPUP_SUGGEST_SERVICE:
        'CLICK_BUTTON_CLOSE_IN_POPUP_SUGGEST_SERVICE',
    CLICK_BUTTON_OK_IN_POPUP_SUGGEST_SERVICE:
        'CLICK_BUTTON_OK_IN_POPUP_SUGGEST_SERVICE',
    CLICK_SELECT_SERVICE_IN_POPUP_SUGGEST_SERVICE:
        'CLICK_SELECT_SERVICE_IN_POPUP_SUGGEST_SERVICE',
    CLICK_REMOVE_SERVICE_IN_POPUP_SUGGEST_SERVICE:
        'CLICK_REMOVE_SERVICE_IN_POPUP_SUGGEST_SERVICE',
    GO_TO_BOOKING_DETAIL_SERVICE_SCREEN: 'GO_TO_BOOKING_DETAIL_SERVICE_SCREEN',
    CLICK_SERVICE_NO_HAS_DETAIL: 'CLICK_SERVICE_NO_HAS_DETAIL',
    CLICK_SERVICE_HAS_DETAIL: 'CLICK_SERVICE_HAS_DETAIL',
    CLICK_BACK_IN_SERVICE_DETAIL: 'CLICK_BACK_IN_SERVICE_DETAIL',
    CLICK_ICON_SERVICE_DETAIL: 'CLICK_ICON_SERVICE_DETAIL',

    GO_TO_BOOKING_SELECT_STYLIST_TIME_SCREEN:
        'GO_TO_BOOKING_SELECT_STYLIST_TIME_SCREEN',
    CLICK_SELECT_STYLIST: 'CLICK_SELECT_STYLIST',
    CLICK_SELECT_DATEBOOK: 'CLICK_SELECT_DATEBOOK',
    CLICK_SELECT_BACKUP_SALON: 'CLICK_SELECT_BACKUP_SALON',
    CLICK_BUTTON_OTHER_SALON: 'CLICK_BUTTON_OTHER_SALON',

    GO_TO_BOOKING_SELECT_EXTENSION_SCREEN:
        'GO_TO_BOOKING_SELECT_EXTENSION_SCREEN',
    SELECT_CHECKBOX_REJECT_ADV: 'SELECT_CHECKBOX_REJECT_ADV',
    SELECT_ON_CHECKBOX_BOOK_MANY_PEOPLE: 'SELECT_ON_CHECKBOX_BOOK_MANY_PEOPLE',
    SELECT_OFF_CHECKBOX_BOOK_MANY_PEOPLE:
        'SELECT_OFF_CHECKBOX_BOOK_MANY_PEOPLE',

    GO_TO_BOOKING_SUCCESS_PAGE: 'GO_TO_BOOKING_SUCCESS_PAGE',
    INPUT_PHONE_NUMBER: 'INPUT_PHONE_NUMBER',
    SHOW_POPUP_INPUT_CUSTOMER_NAME: 'SHOW_POPUP_INPUT_CUSTOMER_NAME',
    SHOW_POPUP_INPUT_PHONE: 'SHOW_POPUP_INPUT_PHONE',
    GO_TO_HOME_IN_POPUP_INPUT_PHONE: 'GO_TO_HOME_IN_POPUP_INPUT_PHONE',
    CLICK_BOOKING_IN_POPUP_INPUT_PHONE: 'CLICK_BOOKING_IN_POPUP_INPUT_PHONE',
    CLICK_BOOKING_IN_POPUP_INPUT_CUSTOMER_NAME:
        'CLICK_BOOKING_IN_POPUP_INPUT_CUSTOMER_NAME',
    CLICK_CANCEL_BOOKING_IN_POPUP_INPUT_CUSTOMER_NAME:
        'CLICK_CANCEL_BOOKING_IN_POPUP_INPUT_CUSTOMER_NAME',
    CLICK_GO_TO_HOME: 'CLICK_GO_TO_HOME',
    CANCEL_BOOKING: 'CANCEL_BOOKING',
    CHANGE_BOOKING: 'CHANGE_BOOKING',
    CLICK_FORM_NOTE: 'CLICK_FORM_NOTE',
    CLICK_FORM_CUSTOMER_PHONE_INVITE: 'CLICK_FORM_CUSTOMER_PHONE_INVITE',
    CLICK_BUTTON_SERVICE: 'CLICK_BUTTON_SERVICE',
    CLICK_BUTTON_DATEBOOK: 'CLICK_BUTTON_DATEBOOK',
    CLICK_SHOW_DATEBOOK: 'CLICK_SHOW_DATEBOOK',
    CLICK_CLOSE_DATEBOOK: 'CLICK_CLOSE_DATEBOOK',
    SELECT_CHECKBOX_REJECT_MAKE_PHOTO: 'SELECT_CHECKBOX_REJECT_MAKE_PHOTO',
    CLICK_SELECT_RECENT_SALON: 'CLICK_SELECT_RECENT_SALON',
    CLICK_SELECT_UTILITY_SERVICE: 'CLICK_SELECT_UTILITY_SERVICE',
    CLICK_SELECT_CATEGORY_SERVICE: 'CLICK_SELECT_CATEGORY_SERVICE',
    CLICK_BUTTON_SUCCESS_INACTIVE: 'CLICK_BUTTON_SUCCESS_INACTIVE',
    SHOW_POPUP_STYLIST_BUSY: 'SHOW_POPUP_STYLIST_BUSY',
    CLOSE_POPUP_STYLIST_BUSY: 'CLOSE_POPUP_STYLIST_BUSY',
    GET_HOURS_GROUP_BY_DATE: 'GET_HOURS_GROUP_BY_DATE',
    SHOW_POPUP_STYLIST_MOVE_SALON: 'SHOW_POPUP_STYLIST_MOVE_SALON',
    CLOSE_POPUP_STYLIST_MOVE_SALON: 'CLOSE_POPUP_STYLIST_MOVE_SALON',
    CLICK_STYLIST_MOVE_SALON: 'CLICK_STYLIST_MOVE_SALON',
    SHOW_POPUP_SALON_OFF: 'SHOW_POPUP_SALON_OFF',
    CLOSE_POPUP_SALON_OFF: 'CLOSE_POPUP_SALON_OFF',
    CLICK_SELECT_SALON_BACKUP_POPUP_SALON_OFF:
        'CLICK_SELECT_SALON_BACKUP_POPUP_SALON_OFF',

    CLICK_SHOW_DATE_BOOK_FROM_SUGGESTED_SALON_BACKUP:
        'CLICK_SHOW_DATE_BOOK_FROM_SUGGESTED_SALON_BACKUP',
    CLICK_SELECT_HOUR_IN_SUGGESTED_SALON_BACKUP:
        'CLICK_SELECT_HOUR_IN_SUGGESTED_SALON_BACKUP',
    CLICK_SUGGESTED_SALON_BACKUP: 'CLICK_SUGGESTED_SALON_BACKUP',
    // Event show change service salon back up
    CLICK_CHANGE_SALON_SUGGESTED_SALON_BACKUP:
        'CLICK_CHANGE_SALON_SUGGESTED_SALON_BACKUP',
    CLICK_CANCEL_CHANGE_SALON_SUGGESTED_SALON_BACKUP:
        'CLICK_CANCEL_CHANGE_SALON_SUGGESTED_SALON_BACKUP',
    CLICK_SHOW_LIST_SKINNER: 'CLICK_SHOW_LIST_SKINNER',
    CLOSE_LIST_SKINNER: 'CLOSE_LIST_SKINNER',
    CLICK_SELECT_SKINNER: 'CLICK_SELECT_SKINNER',
    CLICK_SHOW_LIST_SERVICES_SELECTED: 'CLICK_SHOW_LIST_SERVICES_SELECTED',
    CLICK_HIDE_LIST_SERVICES_SELECTED: 'CLICK_HIDE_LIST_SERVICES_SELECTED',
    CLICK_CANCEL_UPSALE_SERVICE_ITEM: 'CLICK_CANCEL_UPSALE_SERVICE_ITEM',
    CLICK_ACCEPT_UPSALE_SERVICE_ITEM: 'CLICK_ACCEPT_UPSALE_SERVICE_ITEM',

    // booking campaign
    GO_TO_BOOKING_CAMPAIGN_SCREEN: 'GO_TO_BOOKING_CAMPAIGN_SCREEN',
    GO_TO_BOOKING_CAMPAIGN_DETAIL_SCREEN:
        'GO_TO_BOOKING_CAMPAIGN_DETAIL_SCREEN',

    // booking cau hoi tu van
    SHOW_POPUP_SELECT_CONSULTATION: 'SHOW_POPUP_SELECT_CONSULTATION',
    CLICK_BUTTON_CONFIRM_POPUP_SELECT_CONSULTATION:
        'CLICK_BUTTON_CONFIRM_POPUP_SELECT_CONSULTATION',
    CLICK_BUTTON_CONFIRM_DISABLE_POPUP_SELECT_CONSULTATION:
        'CLICK_BUTTON_CONFIRM_DISABLE_POPUP_SELECT_CONSULTATION',
    CLICK_OPTIONS_UND_POPUP_SELECT_CONSULTATION:
        'CLICK_OPTIONS_UND_POPUP_SELECT_CONSULTATION',
    CLICK_OPTIONS_PRODUCT__POPUP_SELECT_CONSULTATION:
        'CLICK_OPTIONS_PRODUCT__POPUP_SELECT_CONSULTATION',

    // discover
    GO_TO_DISCOVER_PAGE: 'GO_TO_DISCOVER_PAGE',
    GO_TO_DISCOVER_SCREEN: 'GO_TO_DISCOVER_SCREEN',
    GO_TO_STYLE_MASTER_SCREEN: 'GO_TO_STYLE_MASTER_SCREEN',
    OPEN_SHOW_IMAGE: 'OPEN_SHOW_IMAGE',
    CLOSE_SHOW_IMAGE: 'CLOSE_SHOW_IMAGE',
    CLICK_NEXT_IMAGE: 'CLICK_NEXT_IMAGE',
    CLICK_PREV_IMAGE: 'CLICK_PREV_IMAGE',
    CLICK_TAB_MONTHLY_RANKING: 'CLICK_TAB_MONTHLY_RANKING',
    CLICK_TAB_HISTORY_RANKING: 'CLICK_TAB_HISTORY_RANKING',
    CLICK_TAB_LIKED: 'CLICK_TAB_LIKED',

    // system salon
    GO_TO_SYSTEM_SALON_PAGE: 'GO_TO_SYSTEM_SALON_PAGE',
    GO_TO_SALON_DETAIL_PAGE: 'GO_TO_SALON_DETAIL_PAGE',
    CLICK_SELECT_PROVINCE_IN_LIST: 'CLICK_SELECT_PROVINCE_IN_LIST',
    CLICK_SELECT_PROVINCE_IN_DROPDOWN: 'CLICK_SELECT_PROVINCE_IN_DROPDOWN',
    CLICK_SELECT_DISTRICT: 'CLICK_SELECT_DISTRICT',
    SHOW_PROMPT_ALLOW_ACCESS_LOCATION: 'SHOW_PROMPT_ALLOW_ACCESS_LOCATION',
    ALLOW_ACCESS_LOCATION: 'ALLOW_ACCESS_LOCATION',
    DENIED_ACCESS_LOCATION: 'DENIED_ACCESS_LOCATION',
    CLICK_CALL_HOTLINE: 'CLICK_CALL_HOTLINE',
    CLICK_DIRECT: 'CLICK_DIRECT',
    CLICK_BOOKING: 'CLICK_BOOKING',
    CLICK_GO_TO_SALON: 'CLICK_GO_TO_SALON',

    // common
    HEADER_TOGGLE_MENU: 'HEADER_TOGGLE_MENU',
    HEADER_CLICK_SERVICE: 'HEADER_CLICK_SERVICE',
    HEADER_CLICK_STORE: 'HEADER_CLICK_STORE',
    HEADER_CLICK_SHINE_MEMBER: 'HEADER_CLICK_SHINE_MEMBER',
    HEADER_CLICK_LOOK_BOOK: 'HEADER_CLICK_LOOK_BOOK',
    HEADER_CLICK_BLOG: 'HEADER_CLICK_BLOG',
    HEADER_CLICK_RECRUITMENT: 'HEADER_CLICK_RECRUITMENT',
    HEADER_CLICK_BOOKING_INFO: 'HEADER_CLICK_BOOKING_INFO',
    HEADER_CLICK_LOGIN: 'HEADER_CLICK_LOGIN',
    HEADER_CLICK_REWARD: 'HEADER_CLICK_REWARD',
    HEADER_CLICK_DISCOVER: 'HEADER_CLICK_DISCOVER',
    HEADER_CLICK_LUCKY_WHEEL: 'HEADER_CLICK_LUCKY_WHEEL',
    HEADER_CLICK_HANH_TRINH_TOA_SANG: 'HEADER_CLICK_HANH_TRINH_TOA_SANG',
    HEADER_CLICK_SYSTEM_SALON: 'HEADER_CLICK_SYSTEM_SALON',
    HEADER_CLICK_KIEM_TRA_LICH_DAT: 'HEADER_CLICK_KIEM_TRA_LICH_DAT',
    HEADER_CLICK_GROUP_HOI_DAP_FB: 'HEADER_CLICK_GROUP_HOI_DAP_FB',
    HEADER_CLICK_30SHINE_ACADEMY: 'HEADER_CLICK_30SHINE_ACADEMY',
    HEADER_CLICK_30SHINE_SPA: 'HEADER_CLICK_30SHINE_SPA',

    CLICK_ZALO: 'CLICK_ZALO',
    CLICK_HOTLINE: 'CLICK_HOTLINE',
    CLICK_MESSENGER: 'CLICK_MESSENGER',
    CLICK_SHOW_BUTTON_CONTACT: 'CLICK_SHOW_BUTTON_CONTACT',
    CLICK_HIDE_BUTTON_CONTACT: 'CLICK_HIDE_BUTTON_CONTACT',

    // login
    CLICK_FORGOT_PASSWORD: 'CLICK_FORGOT_PASSWORD',
    CLICK_SAVING_PASSWORD: 'CLICK_SAVING_PASSWORD',
    CLICK_SKIP_SAVING_PASSWORD: 'CLICK_SKIP_SAVING_PASSWORD',
    GO_TO_STEP_INPUT_PHONE: 'GO_TO_STEP_INPUT_PHONE',
    GO_TO_STEP_INPUT_PASSWORD: 'GO_TO_STEP_INPUT_PASSWORD',
    GO_TO_STEP_CONFIRM_CODE: 'GO_TO_STEP_CONFIRM_CODE',
    GO_TO_STEP_CREATE_PASSWORD: 'GO_TO_STEP_CREATE_PASSWORD',
    GO_TO_STEP_END_LOGIN: 'GO_TO_STEP_END_LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',

    // booking single screen
    GO_TO_BOOKING_SELECT_BOOKING_SINGLE_SCREEN:
        'GO_TO_BOOKING_SELECT_BOOKING_SINGLE_SCREEN',
};
