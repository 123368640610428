/* eslint-disable no-nested-ternary */
import React, { FC, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Swiper from 'react-id-swiper';
import API from 'repository';
import { validatePhone } from 'utils/validator';
import trackingConst from 'config/TrackingConst';
import { notification } from 'antd';
import { fetchAllSalon } from 'actions/salon';
import {
    diffTimeToday,
    removeDuplicateSalon,
    setQuery,
} from 'utils/BookingUltils';
import navigate from 'images/icons/navigate-booking.svg';
import close from 'images/icons/close.svg';
import { ISelected } from '../../types/Selected';
import { ISalon, ISalonSelected } from '../../types/Salon';
import KEY from '../../Const';
import Title from '../components/Title';
import Selected from '../components/Selected';

const TODAY = moment(new Date()).format('DD-MM-YYYY');

const SelectedSalon: FC<{
    onChangeSelected: Function;
    selected: ISelected;
    listSalon: ISalon[];
    hasClickInSalon: number;
    onChangeSalon: Function;
    trackingData: any;
    setIsSelectedSalonNear: Function;
    fetchAllSalon: Function;
    guideSelectSalon: any;
}> = ({
    onChangeSelected,
    selected,
    listSalon,
    hasClickInSalon,
    onChangeSalon,
    trackingData,
    setIsSelectedSalonNear,
    fetchAllSalon,
    guideSelectSalon,
}) => {
    const swiperRef = useRef<any>(null);
    const [isChosenSalon, setIsChosenSalon] = useState<boolean>(true);
    const [listSalonHasCame, setListSalonHasCame] = useState<any>([]);
    const history = useHistory();

    const phone = selected[KEY.PHONE];

    const paramsSwiper: {
        spaceBetween: number;
        slidesPerView: number | 'auto' | undefined;
    } = {
        spaceBetween: 10,
        slidesPerView: 'auto',
    };

    const postTracking = async (data: any) => {
        try {
            API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    };

    const getSalon = async () => {
        if (listSalon.length === 0) {
            const listSalonApi = await fetchAllSalon();
            return Promise.resolve(listSalonApi);
        }
        return Promise.resolve(listSalon);
    };

    const getSalonById = (id: number) => {
        const data: any = [...listSalon].find((item: any) => item.id === id);
        let dateBook: string = TODAY;
        if (data) {
            if (data?.openingDate) {
                if (diffTimeToday(data.openingDate)) {
                    dateBook = data.openingDate;
                }
            }
            const salon: ISalonSelected = {
                id: data?.id || 0,
                name: data?.name || '',
                openingDate: dateBook,
                address: data?.address || '',
                warnText: data?.warnText || '',
                addressNew: data?.addressNew || '',
                bookManyPeople: data?.bookManyPeople || '',
                selectConsultation: data?.selectConsultation || false,
            };
            onChangeSelected([KEY.SALON], salon);
            onChangeSelected([KEY.DATE_BOOK], dateBook);
        } else {
            onChangeSelected([KEY.SALON], {
                id: 0,
                name: '',
                openingDate: '',
            });
            const params = setQuery(selected, {
                salonId: 0,
            });
            history.push({
                pathname: '/booking',
                search: params,
            });
        }
    };

    const getSalonHasCame = async (listSalon: ISalon[]) => {
        try {
            if (!phone.length || !phone) throw new Error('Phone invalid!');
            const res: any = await API.getSalonHasCameNew({
                customerPhone: phone,
            });
            if (res?.data) {
                const sections = res?.data.map((item: any, index: number) => {
                    const ids = Array.from(new Set(item.data));
                    const salons = listSalon
                        .filter((salon: any) => ids.includes(salon.id))
                        .map((salon: any) => {
                            return {
                                ...salon,
                                isPreviousSalon: !index,
                            };
                        })
                        .sort(function (a, b) {
                            return ids.indexOf(a.id) - ids.indexOf(b.id);
                        });
                    return {
                        title: item.title,
                        data: salons,
                    };
                });
                const list = removeDuplicateSalon([...sections[0].data]);
                if (list && list.length === 0) {
                    throw new Error('Salon empty');
                } else {
                    setListSalonHasCame(list.slice(0, 3));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleOnChangeSalon = (salon: ISalon) => {
        onChangeSalon(salon);
        const data = {
            data: {
                customerId: trackingData.customerId,
                phone: selected[KEY.PHONE],
                salonId: salon.id,
            },
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            eventId: trackingConst.Events.CLICK_SELECT_RECENT_SALON,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
        };
        postTracking(data);
        const params = setQuery(selected, {
            salonId: salon.id,
        });
        history.push({
            pathname: '/booking',
            search: params,
        });
    };

    const handleOnClickSalonNear = () => {
        const data = {
            data: {
                customerId: trackingData.customerId,
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id,
            },
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId:
                trackingConst.Screens.BOOKING_SELECT_BOOKING_SINGLE_SCREEN,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
        };
        onChangeSelected(KEY.STEP, 1);

        navigator.geolocation.getCurrentPosition(
            (position) => {
                setIsSelectedSalonNear({
                    isAllowAccessLocation: true,
                    isDeniedAccessLocation: false,
                });
                const params = {
                    ...data,
                    eventId: trackingConst.Events.ALLOW_ACCESS_LOCATION,
                };
                postTracking(params);
            },
            () => {
                // tracking
                setIsSelectedSalonNear({
                    isAllowAccessLocation: false,
                    isDeniedAccessLocation: true,
                });
                const params = {
                    ...data,
                    eventId: trackingConst.Events.DENIED_ACCESS_LOCATION,
                };
                postTracking(params);
            },
        );
    };

    const getClassSalonStep = () => {
        return selected[KEY.SALON].id > 0
            ? 'main-screen__block--done'
            : 'main-screen__block--active';
    };

    useEffect(() => {
        getSalon();
    }, []);

    useEffect(() => {
        if (
            selected[KEY.SALON].id &&
            !selected[KEY.SALON].name &&
            listSalon.length > 0
        ) {
            getSalonById(selected[KEY.SALON].id);
        }
    }, [selected[KEY.SALON].id, listSalon]);

    useEffect(() => {
        if (
            listSalon.length &&
            !validatePhone(selected[KEY.PHONE]) &&
            selected[KEY.SALON].id === 0
        ) {
            getSalonHasCame(listSalon);
        }
    }, [selected[KEY.SALON].id, selected[KEY.PHONE], listSalon]);

    useEffect(() => {
        if (hasClickInSalon === 0 && selected[KEY.SALON].id === 0) {
            setIsChosenSalon(false);
        } else {
            setIsChosenSalon(true);
        }
    }, [hasClickInSalon, selected[KEY.SALON].id]);

    useEffect(() => {
        if (selected[KEY.SALON].id > 0) {
            setListSalonHasCame([]);
        }
    }, [selected[KEY.SALON]]);

    return (
        <>
            <div
                className={`main-screen__block ${getClassSalonStep()}`}
                id="salonAttributeId"
            >
                <div className="first-item" />
                <Title text="1. Chọn salon" />
                <Selected
                    type="salon"
                    value={selected[KEY.SALON].addressNew || ''}
                    placeholder="Xem tất cả salon"
                    message={
                        isChosenSalon
                            ? ''
                            : 'Bạn vui lòng chọn salon trước để xem lịch còn trống ạ!'
                    }
                    // disable click
                    onHandleClick={() => {
                        if (listSalon && listSalon.length > 1) {
                            onChangeSelected(KEY.STEP, 1);
                        }
                    }}
                    required={selected[KEY.SALON].id === 0}
                />
                {selected[KEY.SALON].id === 0 ? (
                    <div className="block__wrapper">
                        <div className="wrapper__swiper">
                            <Swiper
                                ref={swiperRef}
                                {...paramsSwiper}
                                shouldSwiperUpdate
                            >
                                <div
                                    className="swiper__salon buttonSalonNear"
                                    onClick={() => handleOnClickSalonNear()}
                                    role="presentation"
                                >
                                    <img
                                        src={navigate}
                                        alt=""
                                        style={{ width: 20 }}
                                        className="list-address__icon"
                                    />
                                    <span className="list-address__text">
                                        Tìm salon gần bạn
                                    </span>
                                </div>
                                {listSalonHasCame.map((salon: ISalon) => {
                                    return (
                                        <div
                                            className="swiper__salon pointer"
                                            role="presentation"
                                            key={salon.id}
                                            onClick={() =>
                                                handleOnChangeSalon(salon)
                                            }
                                        >
                                            <span className="swiper__text">
                                                {salon.name}
                                            </span>
                                        </div>
                                    );
                                })}
                            </Swiper>
                        </div>
                    </div>
                ) : selected[KEY.SALON].warnText ? (
                    <div className="block__wrapper">
                        <div className="wrapper__warning">
                            {selected[KEY.SALON].warnText}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        listSalon: state.salon.listSalon,
        trackingData: state.trackingData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchAllSalon: () => dispatch(fetchAllSalon()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SelectedSalon);
