import React, { FC, useEffect, useState } from 'react';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import { Button, Checkbox, Dropdown } from 'antd';
import iconArrowLeft from 'images/icons/icon_arrow_left_blue.svg';
import iconLayoutDefault from 'images/icons/ic_service_layout_default.svg';
import iconLayoutGrid from 'images/icons/ic_service_layout_grid.svg';
import iconExpand from 'images/fillBlue/chevronUp.svg';
import { useSelector } from 'react-redux';
import { ISelected } from '../types/Selected';
import { IListService } from '../types/Service';
import './styles.scss';
import ButtonFooter from './components/ButtonFooter';
import KEY from '../Const';
import SearchBar from './SearchBar';
import CategoriesServicesSwiper from './components/CategoriesServicesSwiper';
import ListCategoriesServices from './components/CategoriesServices';
import DropdownLanguage from './components/DropdownLanguage';

const Service: FC<{
    selected: ISelected;
    listService: IListService[];
    setShowDetailService: Function;
    onSelectService: Function;
    isLoading: boolean;
    isGenZMember: boolean;
    trackingData: any;
    userId: any;
    onChangeStep: Function;
    setPositionScroll: Function;
    gotoCampaignScreen: Function;
    ruleShowBooking: any;
    layoutDefault: boolean;
    setLayoutDefault: Function;
    allowChange: boolean;
    setAllowChange: Function;
}> = ({
    selected,
    listService,
    setShowDetailService,
    onSelectService,
    isLoading,
    isGenZMember,
    trackingData,
    userId,
    onChangeStep,
    setPositionScroll,
    gotoCampaignScreen,
    ruleShowBooking,
    setLayoutDefault,
    layoutDefault,
    allowChange,
    setAllowChange,
}) => {
    const common = useSelector((state: any) => state.common);
    const [isShowListUsedServices, setIsShowListUsedServices] = useState(false);
    const [listUsedServices, setListUsedServices] = useState<IListService[]>(
        [],
    );
    const [tottalServiceShow, setTottalServiceShow] = useState<number>(0);
    const [listServicesShow, setListServicesShow] = useState<IListService[]>(
        [],
    );
    const [listServiceRule, setListServiceRule] = useState<IListService[]>([]);
    // khai báo trang thái tìm kiếm và trạng thái đang chọn diịch vụ
    const [selectService, setSelectService] = useState<Array<number>>([]);
    const [search, setSearch] = useState('');
    const [initialState, setInitialState] = useState<boolean>(true);
    const [showExpand, setShowExpand] = useState(false);

    async function postTracking(data: any) {
        try {
            await API.postTrackingDynamic(data);
        } catch (e) {
            console.log(e.message);
        }
    }

    const handleOnSelectedService = (service: any, checked: boolean) => {
        onSelectService(service, checked, true);
    };

    const handleOnClickDetailService = async (service: any) => {
        setPositionScroll(
            window.scrollY ||
                window.pageYOffset ||
                document.body.scrollTop +
                    ((document.documentElement &&
                        document.documentElement.scrollTop) ||
                        0),
        );
        setShowDetailService(service);
        onChangeStep(3);

        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SELECT_SERVICE_SCREEN,
            eventId: trackingConst.Events.CLICK_SHOW_DETAIL_SERVICE,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                hourBook: selected[KEY.HOUR].hour,
                step: selected[KEY.STEP],
            },
        };

        await postTracking(dataTracking);
    };

    const content = (
        <div>
            <div
                className={`flex gap-1 items-center cursor-pointer font-content text-[#20489B]  p-2 text-xs ${
                    layoutDefault
                    ? 'bg-white hover:bg-[#DBE9F6]'
                        : 'bg-[#F0F8FF]'
                }`}
                onClick={() => setLayoutDefault(false)}
                aria-hidden="true"
            >
                <img src={iconLayoutGrid} alt="" />{' '}
                {common.lang === 'vi' ? 'Chế độ lưới' : 'Grid'}
            </div>
            <div
                className={`flex gap-1 items-center cursor-pointer font-content text-[#20489B]  p-2 text-xs ${
                    layoutDefault
                        ? 'bg-[#F0F8FF]'
                    : 'bg-white hover:bg-[#DBE9F6]'
                }`}
                onClick={() => setLayoutDefault(true)}
                aria-hidden="true"
            >
                <img src={iconLayoutDefault} alt="" />{' '}
                {common.lang === 'vi' ? 'Mặc định' : 'Default'}
            </div>
        </div>
    );

    useEffect(() => {
        if (listService.length) {
            const newListServiceUsed: any = [];

            listService.forEach((cate) => {
                cate.serviceGroups.forEach((svGroup) => {
                    const seviceBeforeList = svGroup.services.filter(
                        (sv) => sv.used === 1,
                    );
                    if (seviceBeforeList.length) {
                        newListServiceUsed.push(svGroup);
                    }
                });
            });

            const data: IListService[] = [
                {
                    categoryName: 'Dịch vụ bạn đã từng dùng',
                    order: 0,
                    listService: newListServiceUsed,
                    serviceGroups: newListServiceUsed,
                    title: '',
                },
            ];

            setListUsedServices(data);
        } else {
            const data: IListService[] = [
                {
                    categoryName: 'Dịch vụ bạn đã từng dùng',
                    order: 0,
                    listService: [],
                    serviceGroups: [],
                    title: '',
                },
            ];
            setListUsedServices(data);
        }
    }, [listService]);

    return (
        <>
            <div className="booking-screen__service">
                <div className="flex items-center justify-between py-3 px-2.5 pointer">
                    <img
                        src={iconArrowLeft}
                        alt=""
                        onClick={() => {
                            onChangeStep(0);
                        }}
                        role="presentation"
                    />
                    <span className="text-center text-[#20489B] font-semibold">
                        {common.lang === 'vi'
                            ? 'Chọn dịch vụ'
                            : 'Select a service'}{' '}
                        <span className="text-[#20489B] text-sm font-normal">
                            (1K = 1000đ)
                        </span>
                    </span>
                    <div className="flex gap-1">
                        <Dropdown
                            overlay={content}
                            className="button-layout"
                            placement="bottomRight"
                            overlayClassName="popover-layout"
                            trigger={['click']}
                        >
                            <Button>
                                {layoutDefault ? (
                                    <img src={iconLayoutDefault} alt="" />
                                ) : (
                                    <img src={iconLayoutGrid} alt="" />
                                )}
                                {common.lang === 'vi' ? 'Kiểu xem' : 'Display'}
                            </Button>
                        </Dropdown>
                        <DropdownLanguage />
                    </div>
                </div>
                <SearchBar
                    setSelectService={setSelectService}
                    listService={listService}
                    setInitialState={setInitialState}
                    ruleShowBooking={ruleShowBooking}
                    setListServiceRule={setListServiceRule}
                    setListServicesShow={setListServicesShow}
                    selectService={selectService}
                    listServicesShow={listServicesShow}
                    setTottalServiceShow={setTottalServiceShow}
                    search={search}
                    setSearch={(value: string) => setSearch(value)}
                    isShowListUsedServices={isShowListUsedServices}
                    setIsShowListUsedServices={setIsShowListUsedServices}
                    selected={selected}
                    listUsedServices={listUsedServices}
                />
                {!initialState && (
                    <div className="bg-[#FFF] mt-2.5 py-2.5 px-[15px] flex items-center justify-between">
                        <div className="text-[#20489B] font-semibold text-base">
                            {common.lang === 'vi'
                                ? 'Kết quả tìm kiếm'
                                : 'Result'}
                        </div>
                        <div className="text-[#A3A3A3] text-sm">
                            {tottalServiceShow}{' '}
                            {common.lang === 'vi'
                                ? 'dịch vụ được tìm thấy'
                                : tottalServiceShow > 0
                                ? 'services'
                                : 'service'}
                        </div>
                    </div>
                )}
                {listServicesShow.length > 0 ? (
                    listServicesShow.map((item: any) => {
                        return layoutDefault ? (
                            <CategoriesServicesSwiper
                                isLoading={isLoading}
                                item={item}
                                selected={selected}
                                onSelectService={handleOnSelectedService}
                                onClickDetailService={
                                    handleOnClickDetailService
                                }
                                trackingData={trackingData}
                                userId={userId}
                                key={item.order}
                                isSearchResult={!initialState}
                                layoutDefault={layoutDefault}
                            />
                        ) : (
                            <ListCategoriesServices
                                isLoading={isLoading}
                                item={item}
                                selected={selected}
                                onSelectService={handleOnSelectedService}
                                onClickDetailService={
                                    handleOnClickDetailService
                                }
                                trackingData={trackingData}
                                userId={userId}
                                key={item.order}
                                isSearchResult={!initialState}
                                layoutDefault={layoutDefault}
                            />
                        );
                    })
                ) : (
                    <div className="bg-white h-96 color-a3a3a3 flex items-center justify-center font-light text-sm">
                        Đang tải dữ liệu ...
                    </div>
                )}

                {!initialState && (
                    <div
                        className="bg-[#FFF] mt-2.5 py-2.5 mb-2.5 px-[15px] flex justify-between items-center cursor-pointer"
                        onClick={() => setShowExpand(!showExpand)}
                        role="presentation"
                    >
                        <div className="text-[#20489B] font-semibold text-base">
                            {common.lang === 'vi'
                                ? 'Dịch vụ khác'
                                : 'Other services'}
                        </div>
                        <img
                            src={iconExpand}
                            alt=""
                            className={showExpand ? '' : 'rotate-180'}
                        />
                    </div>
                )}
                {showExpand &&
                listServiceRule.length > 0 &&
                (search || isShowListUsedServices)
                    ? listServiceRule.map((item) => {
                          return layoutDefault ? (
                              <CategoriesServicesSwiper
                                  isLoading={isLoading}
                                  item={item}
                                  selected={selected}
                                  onSelectService={handleOnSelectedService}
                                  onClickDetailService={
                                      handleOnClickDetailService
                                  }
                                  trackingData={trackingData}
                                  userId={userId}
                                  key={item.order}
                                  isSearchResult={item?.categoryName === search}
                                  layoutDefault={layoutDefault}
                              />
                          ) : (
                              <ListCategoriesServices
                                  isLoading={isLoading}
                                  item={item}
                                  selected={selected}
                                  onSelectService={handleOnSelectedService}
                                  onClickDetailService={
                                      handleOnClickDetailService
                                  }
                                  trackingData={trackingData}
                                  userId={userId}
                                  key={item.order}
                                  isSearchResult={item?.categoryName === search}
                                  layoutDefault={layoutDefault}
                              />
                          );
                      })
                    : null}
            </div>
            <ButtonFooter
                gotoCampaignScreen={() => gotoCampaignScreen()}
                onChangeStep={onChangeStep}
                dataBooking={selected}
                onSelectService={onSelectService}
                allowChange={allowChange}
                setAllowChange={setAllowChange}
            />
        </>
    );
};

export default Service;
