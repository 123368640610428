import React from 'react';

const LayoutHeadContent: React.FC<{
    data: any;
}> = ({ data }) => {
    return (
        <div className="layout layout-service !mt-[26px]">
            <div className="container">
                <div className="mx-auto max-w-[677px] text-center text-sm lg:text-lg text-[#20489B] my-[26px] lg:my-10 font-semibold">
                    {data.title}
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-[15px] lg:gap-[55px]">
                    {data.list &&
                        data.list.length > 0 &&
                        data.list.map((item: any, index: number) => (
                            <div
                                className="flex gap-4"
                                key={`head-content-${item.id}`}
                            >
                                <img
                                    src={item.linkImage}
                                    alt=""
                                    className={`rounded ${index % 2 === 0
                                            ? 'order-first'
                                            : 'order-last md:order-first'
                                        }`}
                                />
                                <div className="grow">
                                    <div className="text-[#20489B] font-semibold uppercase text-sm lg:text-base">
                                        {item.title}
                                    </div>
                                    <ul className="pl-6 text-sm list-disc lg:text-base">
                                        {item.content.map((content: string) => (
                                            <li
                                                key={`head-content-item-${content}`}
                                            >
                                                {content}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default LayoutHeadContent;
