import { Button, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import OtpInput from 'components/OtpInput/index.js';
import './styles.scss';
import SentOtp from './SentOtp';
import { connect } from 'react-redux';
import API from 'repository';
import trackingConst from 'config/TrackingConst';
import KEY from '../../Const';
import { useHistory } from 'react-router';

const ModalOtp: React.FC<{
    trackingData: any;
    selected: any;
    userId: any;
    visibleOtp: boolean;
    onCancel: Function;
    bookingData: any;
}> = ({
    trackingData,
    selected,
    userId,
    visibleOtp,
    onCancel,
    bookingData,
}) => {
    const [otpNumber, setOtpNumber] = useState('');
    const [message, setMessage] = useState('');
    const [verificationMethod, setVerificationMethod] = useState('sms');
    const [countSendOtp, setCountSendOtp] = useState(0);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const onChangeOtp = (value: any) => {
        if (
            (value.charCodeAt(value.length - 1) > 47 &&
                value.charCodeAt(value.length - 1) < 58) ||
            !value
        ) {
            setOtpNumber(value);
            setMessage('');
        } else {
            setMessage('Mã xác minh là số và có 6 kí tự');
        }
    };

    const onGetOtp = async () => {
        if (loading) return;
        try {
            setOtpNumber('');
            setMessage('');
            setLoading(true);
            const data: any = await API.getOpt({
                phone: selected.customerPhone,
            });
            if (data) {
                if (data.success) {
                    setOtpNumber('');
                    setMessage('');
                } else {
                    setOtpNumber('');
                    setMessage(data.message);
                }
                setCountSendOtp(data.allowResendAfterSeconds);
                setVerificationMethod(data.verificationMethod);
            }
        } catch (error) {}
        setLoading(false);
    };

    const onHandleSubmit = async () => {
        if (otpNumber.length !== 6) {
            setOtpNumber('');
            setMessage('Mã xác minh là số và có 6 kí tự');
            return;
        }
        if (loading) return;
        try {
            setLoading(true);
            const response: any = await API.checkOtp({
                bookingId: bookingData.id,
                phone: selected.customerPhone,
                code: otpNumber,
            });

            onCancel();
            const config = {
                message: 'Thông Báo',
                description: 'Đặt lịch thành công!',
                duration: 1,
                className: 'success',
            };
            notification.success(config);
            history.push({
                pathname: `/booking/success`,
                search: `?bookingId=${bookingData.id}`,
                state: {
                    bookingData: bookingData ? bookingData : undefined,
                },
            });
        } catch (error) {
            const config = {
                message: 'Thông báo',
                description: error.response.data.message
                    ? error.response.data.message
                    : 'Mã Xác Nhận Không Chính Xác!',
                duration: 3,
                className: 'error',
            };
            notification.error(config);
        }
        setLoading(false);
    };

    const postTracking = async (event: string) => {
        const dataTracking = {
            pageId: trackingConst.Pages.BOOKING_SINGLE_PAGE,
            screenId: trackingConst.Screens.BOOKING_SUCCESS_SCREEN,
            eventId: event,
            tokenKey: trackingData.token,
            source: trackingData.source,
            utmSource: trackingData.utmSource,
            data: {
                phone: selected[KEY.PHONE],
                salonId: selected[KEY.SALON].id.toString(),
                dateBook: selected[KEY.DATE_BOOK],
                stylistId: selected[KEY.STYLIST].id.toString(),
                hoursAvailable: selected[KEY.AVAILABLE_HOURS].toString(),
                chosenServices: JSON.stringify(selected[KEY.CHOSEN_SERVICES]),
                hourBook: selected[KEY.HOUR].hour,
                customerId: userId || 0,
                skinnerId: selected[KEY.SKINNER_ID].toString(),
            },
        };
        try {
            API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };

    useEffect(() => {
        if (visibleOtp) onGetOtp();
    }, [visibleOtp]);

    return (
        <div className="modal-otp-booking">
            <Modal
                visible={visibleOtp}
                title={`NHẬP MÃ OTP GỬI VỀ SĐT ${selected[KEY.PHONE]}`}
                className="modal-otp-booking"
                footer={false}
                width={480}
                closable={false}
            >
                <div className="text-center text-sm md:text-base pb-5">
                    Vì lí do bảo mật, tránh nhầm SĐT.
                    <br />
                    Bạn vui lòng nhập mã OTP để xác nhận đặt lịch
                </div>
                <div className="otp ">
                    <OtpInput
                        value={otpNumber}
                        onChange={onChangeOtp}
                        numInputs={6}
                        isInputNum
                        focusStyle={{ borderColor: '#222222' }}
                        shouldAutoFocus
                        onKeyDown={(e: any) => {
                            if (e.keyCode === 13) {
                                onHandleSubmit();
                            }
                        }}
                        className="input-otp"
                    />
                </div>
                {message ? (
                    <div className="message__error">{message}</div>
                ) : null}
                {verificationMethod ? (
                    <div className="message__otp">
                        {verificationMethod === 'sms'
                            ? 'Kiểm tra SMS '
                            : 'Nhận cuộc gọi tự động '}
                        để lấy OTP đặt lịch.
                    </div>
                ) : null}
                <SentOtp
                    onGetOtp={onGetOtp}
                    count={countSendOtp}
                    setCount={(value: number) => setCountSendOtp(value)}
                    postTracking={postTracking}
                />
                <Button
                    type="primary"
                    onClick={() => onHandleSubmit()}
                    className="button btn-color-1"
                    loading={loading}
                >
                    HOÀN TẤT
                </Button>
            </Modal>
        </div>
    );
};

function mapStateToProps(state: any) {
    const { trackingData, user, booking } = state;
    return {
        trackingData,
        userId: user.userId,
    };
}
export default connect(mapStateToProps)(ModalOtp);
