import React from 'react';
import { Skeleton, Row, Col } from 'antd';
import './index.scss';

const LoadingSkeleton = () => {
    return (
        <>
            <div className="skeleton-loading">
                <Skeleton paragraph={{ rows: 1 }} className="loading__title" />
                <Skeleton.Avatar shape="square" className="loading__banner" />
                <Row gutter={{ xs: 0, sm: 30 }}>
                    <Col xs={24} md={12} lg={6}>
                        <div className="flex item-center">
                            <Skeleton.Avatar
                                className="loading__media"
                                shape="square"
                            />
                            <Skeleton
                                paragraph={{ rows: 1 }}
                                className="loading__title"
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className="flex item-center">
                            <Skeleton.Avatar
                                className="loading__media"
                                shape="square"
                            />
                            <Skeleton
                                paragraph={{ rows: 1 }}
                                className="loading__title"
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className="flex item-center">
                            <Skeleton.Avatar
                                className="loading__media"
                                shape="square"
                            />
                            <Skeleton
                                paragraph={{ rows: 1 }}
                                className="loading__title"
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className="flex item-center">
                            <Skeleton.Avatar
                                className="loading__media"
                                shape="square"
                            />
                            <Skeleton
                                paragraph={{ rows: 1 }}
                                className="loading__title"
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="skeleton-loading">
                <Skeleton paragraph={{ rows: 1 }} className="loading__title" />
                <Row gutter={{ xs: 0, sm: 30 }}>
                    <Col xs={24} md={12} lg={12}>
                        <Skeleton.Avatar
                            className="loading__banner"
                            shape="square"
                        />
                        <Skeleton
                            paragraph={{ rows: 1 }}
                            className="loading__title"
                        />
                    </Col>
                    <Col xs={24} md={12} lg={12} className="mobile-hide">
                        <Skeleton.Avatar
                            className="loading__banner"
                            shape="square"
                        />
                        <Skeleton
                            paragraph={{ rows: 1 }}
                            className="loading__title"
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default LoadingSkeleton;
