import React, { FC } from 'react';
import { Switch } from 'antd';

const ItemCheck: FC<{
    checked: boolean;
    onChange: Function;
    text: string;
    title: string;
    icon?: string;
}> = ({ checked, onChange, text, title, icon }) => {
    return (
        <div className="advice-extension mt-5">
            <div className="flex item-center">
                <div className="text-base flex items-center font-medium mb-0.5">
                    {icon && (
                        <img src={icon} alt="" className="w-6 h-6 mr-1.5" />
                    )}
                    {title}
                </div>
                <Switch
                    checked={checked}
                    onChange={() => onChange()}
                    className="advice-extension__switch"
                />
            </div>
            <div className="text-sm">{text}</div>
        </div>
    );
};

export default ItemCheck;
