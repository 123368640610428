import React, { FC } from 'react';
import { Row, Col } from 'antd';
import '../index.scss';
import TrackingConst from 'config/TrackingConst';
import API from 'repository';
import { connect } from 'react-redux';
import VectorRight from 'images/icons/VectorRight.svg';
import useWindowSize from 'hooks/useWindowSize';
import RedirectLink from '../redirectLink';
import Banner from '../banner';
import LayoutHeadTitle from '../headTitle';

const LayoutService: React.FC<{
    data: any;
    trackingData: any;
}> = ({ data, trackingData }) => {
    const size: any = useWindowSize();
    const isMobile = size.width < 768;
    const colLg = data.list.length === 3 ? 8 : 6;

    const postTracking = async (eventId: string) => {
        if (!eventId) return;
        try {
            const dataTracking = {
                pageId: TrackingConst.Pages.HOME_PAGE,
                screenId: TrackingConst.Pages.HOME_PAGE,
                eventId,
                tokenKey: trackingData.token,
                source: trackingData.source,
                utmSource: trackingData.utmSource,
                data: {
                    phone: trackingData.phoneNumber || '',
                    customerId: trackingData.customerId || 0,
                },
            };
            await API.postTrackingDynamic(dataTracking);
        } catch (e) {
            console.log(e.message);
        }
    };
    const createMarkup = (htmlCode: string) => {
        return { __html: htmlCode };
    };
    return (
        <div className="layout layout-service">
            <div className="container">
                <LayoutHeadTitle data={data} />
                {/* <Banner data={data} /> */}
                <div className="layout__list">
                    <Row gutter={{ xs: 10, sm: 20 }}>
                        {data.list &&
                            data.list.length > 0 &&
                            data.list.map((item: any, index: number) => {
                                return (
                                    <Col
                                        xs={8}
                                        md={8}
                                        lg={colLg}
                                        key={item.id}
                                        onClick={() =>
                                            postTracking(item?.eventId || '')
                                        }
                                        className="column_card"
                                    >
                                        <RedirectLink
                                            redirect={item.redirectLink}
                                        >
                                            <div
                                                className={`list__item  
                                            ${
                                                item.redirectLink
                                                    ? 'pointer'
                                                    : ''
                                            }
                                            ${index === 0 ? 'border-none' : ''}
                                            ${
                                                data.isHover
                                                    ? 'list__item-hover'
                                                    : ''
                                            }
                                        `}
                                            >
                                                <div className="item__media">
                                                    <img
                                                        src={
                                                            isMobile &&
                                                            item.linkImageMb
                                                                ? item.linkImageMb
                                                                : item.linkImage
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="wrapCard">
                                                    <div className="item__content">
                                                        <div
                                                            dangerouslySetInnerHTML={createMarkup(
                                                                item.title,
                                                            )}
                                                            className="item__title label_service"
                                                        />

                                                        <div className="wrap_bottom_title">
                                                            <div className="item__subTitle">
                                                                {item.subTitle}
                                                            </div>
                                                            <div className="show_more">
                                                                <div className="show_more__text">
                                                                    <span>
                                                                        Tìm hiểu
                                                                        thêm
                                                                    </span>
                                                                    <img
                                                                        src={
                                                                            VectorRight
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </RedirectLink>
                                    </Col>
                                );
                            })}
                    </Row>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        trackingData: state.trackingData,
    };
};

export default connect(mapStateToProps)(LayoutService);
