import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { setTimeSpecial } from 'actions/booking';
import { scrollToSmoothly } from 'utils/ScrollSmooth';
import KEY from '../Const';
import { ISelected, ISelectedSalonBackUp } from '../types/Selected';
import SelectedSalon from './Salon';
import Service from './Service';
import Stylist from './Stylist';
import DateBook from './Datebook';
import BoxTime from './ListTime';
import HoursSalonBackUp from './HoursSalonBackUp';
import './styles.scss';
import Title from './components/Title';
import ModalSuggestService from './SussgestService';
import Extension from './Extensions';
import ModalSelectConsultation from './SelectConsultation';
import { IService } from '../types/Service';
import SelectNumberGroupBooking from './SelectNumberGroupBooking';
import BoxTimeGroupBooking from './ListTimeGroupBooking';

const TODAY = moment(new Date()).format('DD-MM-YYYY');
const dfHour = {
    bookHourId: 0,
    subHourId: 0,
    secondSubHourId: 0,
    hour: '',
};

interface Props {
    role: any;
    onChangeSelected: Function;
    moveSalonStylistbackUp: Function;
    selected: ISelected;
    trackingData: any;
    hasClickNextStep: number;
    changeCheckBox: Function;
    userInfo: any;
    hasClickInSalon: number;
    setHasClickNextInSalon: Function;
    onChangeSalon: Function;
    hasClickInService: number;
    setHasClickInService: Function;
    selectedSalonBackUp: ISelectedSalonBackUp;
    onChangeSelectedBackUp: Function;
    setIsSelectedSalonNear: Function;
    guideService: any;
    isLoadingServices: boolean;
    setListIconHotTime: Function;
    isBookStylistMaster: boolean;
    changeServiceWithChangeStylistDateBook: Function;
    isClickNextInService: boolean;
    previOusChosenServices: IService[];
    prevStep: number | undefined;
    allowChange: boolean;
}

const MainScreen: FC<Props> = ({
    changeCheckBox,
    role,
    onChangeSelected,
    moveSalonStylistbackUp,
    selected,
    trackingData,
    hasClickNextStep,
    hasClickInSalon,
    setHasClickNextInSalon,
    onChangeSalon,
    hasClickInService,
    setHasClickInService,
    selectedSalonBackUp,
    onChangeSelectedBackUp,
    setIsSelectedSalonNear,
    guideService,
    isLoadingServices,
    setListIconHotTime,
    isBookStylistMaster,
    changeServiceWithChangeStylistDateBook,
    isClickNextInService,
    previOusChosenServices,
    prevStep,
    allowChange,
}) => {
    const [guideSelectSalon, setGuideSelectSalon] = useState('');
    const [guideSelectService, setGuideSelectService] = useState('');
    const [guideStep5, setGuideStep5] = useState('');
    const [isStylistBusy, setStylistBusy] = useState<number>(1);
    const [isSalonBusy, setSalonBusy] = useState<number>(1);
    const [isLoadingHour, setIsLoadingHour] = useState<boolean>(true);
    const [isClickDateBook, setIsClickDateBook] = useState<boolean>(false);
    const [isShowDateBook, setIsShowDateBook] = useState<boolean>(false);
    const [reception, setReception] = useState({
        avatar: '',
        name: '',
    });
    const [listSkinner, setListSkinner] = useState([]);

    const scrollToSelectDateBook = () => {
        const element = document.querySelector('#datebookId') as HTMLDivElement;
        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const pos = elementPosition || 0;
            scrollToSmoothly(pos, 300);
        }
        if (!isShowDateBook) {
            setIsShowDateBook(true);
        }
    };

    function onChangeDay(item: any) {
        onChangeSelected(KEY.DATE_BOOK, item?.day ? item.day : TODAY);
        setIsClickDateBook(true);
    }

    function getClassHourStep() {
        const isSalonSelected = selected[KEY.SALON].id > 0;
        const isServiceSelected =
            selected[KEY.CHOSEN_SERVICES].length > 0 || allowChange;
        if (!isSalonSelected || !isServiceSelected) return '';
        if (
            (isServiceSelected && selected[KEY.HOUR].bookHourId > 0) ||
            (isSalonSelected && selectedSalonBackUp.hour.bookHourId > 0) || 
            (selected[KEY.BOOK_HOURS].length === selected[KEY.GROUP_BOOKING])
        )
            return 'main-screen__block--done';
        if (
            (isServiceSelected && selected[KEY.HOUR].bookHourId === 0) ||
            selectedSalonBackUp.hour.bookHourId === 0
        )
            return 'main-screen__block--active';
        return '';
    }

    useEffect(() => {
        if (role.guideScreens && role.guideScreens.length > 0) {
            const guideSalon = role.guideScreens.find(
                (item: any) => item.step === 1,
            );
            const guideService = role.guideScreens.find(
                (item: any) => item.step === 2,
            );
            setGuideSelectSalon(guideSalon ? guideSalon.guide : '');
            setGuideSelectService(guideService ? guideService.guide : '');
        }
    }, [role]);

    useEffect(() => {
        // Handle step 5 guide
        const listServices = `${selected[KEY.CHOSEN_SERVICES]
            .map((item: any) => item.serviceId)
            .join(';')};`;
        if (
            guideService &&
            guideService.length > 0 &&
            selected[KEY.CHOSEN_SERVICES].length > 0
        ) {
            const step5 = guideService.find((item: any) => item.step === 5);
            const guideList = step5.guideList.map((item: any) => {
                const splitItem = item.split('@@@');
                return {
                    regexString: splitItem[0],
                    message: splitItem[1],
                };
            });
            const guideString = guideList.find((item: any) => {
                const regex = new RegExp(item.regexString);
                return listServices.match(regex);
            });
            if (guideString) {
                setGuideStep5(guideString.message);
            }
        }
    }, [selected[KEY.CHOSEN_SERVICES]]);

    useEffect(() => {
        onChangeSelected(KEY.HOUR, dfHour);
        changeCheckBox();
    }, [selected[KEY.DATE_BOOK], selected[KEY.STYLIST].id]);

    return (
        <div className="main-screen">
            <SelectedSalon
                hasClickInSalon={hasClickInSalon}
                onChangeSelected={onChangeSelected}
                selected={selected}
                onChangeSalon={onChangeSalon}
                setIsSelectedSalonNear={setIsSelectedSalonNear}
                guideSelectSalon={guideSelectSalon}
            />
            <Service
                isLoadingServices={isLoadingServices}
                guideSelectService={guideSelectService}
                onChangeSelected={onChangeSelected}
                selected={selected}
                setHasClickNextInSalon={setHasClickNextInSalon}
                trackingData={trackingData}
                hasClickInService={hasClickInService}
                guideStep5={guideStep5}
                allowChange={allowChange}
            />
            <div className={`main-screen__block ${getClassHourStep()}`}>
                <div className="next-item" />
                <div className="block" id="service-time">
                    <Title text="3. Chọn ngày, giờ" />

                    <SelectNumberGroupBooking
                        selected={selected}
                        onChangeSelected={onChangeSelected}
                    />

                    <DateBook
                        onChangeDay={onChangeDay}
                        role={role}
                        dateBook={selected[KEY.DATE_BOOK]}
                        isSalonBusy={isSalonBusy}
                        isStylistBusy={isStylistBusy}
                        isLoadingHour={isLoadingHour}
                        salonId={selected[KEY.SALON].id}
                        setHasClickNextInSalon={setHasClickNextInSalon}
                        trackingData={trackingData}
                        chosenService={selected[KEY.CHOSEN_SERVICES]}
                        stylistId={selected[KEY.STYLIST].id}
                        setHasClickInService={setHasClickInService}
                        selected={selected}
                        setIsShow={setIsShowDateBook}
                        isShow={isShowDateBook}
                        isBookStylistMaster={isBookStylistMaster}
                        changeServiceWithChangeStylistDateBook={
                            changeServiceWithChangeStylistDateBook
                        }
                        allowChange={allowChange}
                    />
                    {selected[KEY.GROUP_BOOKING] === 1 ?
                        (selected[KEY.SALON].id > 0 &&
                            (selected[KEY.CHOSEN_SERVICES].length > 0 ||
                                allowChange) && (
                                <>
                                    <HoursSalonBackUp
                                        selected={selected}
                                        selectedSalonBackUp={selectedSalonBackUp}
                                        onChangeSelectedBackUp={
                                            onChangeSelectedBackUp
                                        }
                                        onChangeSelected={onChangeSelected}
                                        scrollToSelectDateBook={
                                            scrollToSelectDateBook
                                        }
                                        onChangeSalon={onChangeSalon}
                                    >
                                        <BoxTime
                                            setStylistBusy={setStylistBusy}
                                            isStylistBusy={isStylistBusy}
                                            role={role}
                                            selected={selected}
                                            setListIconHotTime={setListIconHotTime}
                                            onChangeSelected={onChangeSelected}
                                            hasClickNextStep={hasClickNextStep}
                                            trackingData={trackingData}
                                            userId={trackingData.customerId}
                                            setSalonBusy={setSalonBusy}
                                            isSalonBusy={isSalonBusy}
                                            setIsLoadingHour={setIsLoadingHour}
                                            isLoadingHour={isLoadingHour}
                                            isClickDateBook={isClickDateBook}
                                            setReception={setReception}
                                            allowChange={allowChange}
                                        />
                                    </HoursSalonBackUp>
                                </>
                            )
                        )

                        :
                        (selected[KEY.SALON].id > 0 &&
                            (selected[KEY.CHOSEN_SERVICES].length > 0 ||
                                allowChange) && (
                        <>
                            <HoursSalonBackUp
                                selected={selected}
                                selectedSalonBackUp={selectedSalonBackUp}
                                onChangeSelectedBackUp={
                                    onChangeSelectedBackUp
                                }
                                onChangeSelected={onChangeSelected}
                                scrollToSelectDateBook={
                                    scrollToSelectDateBook
                                }
                                onChangeSalon={onChangeSalon}
                            >
                                <BoxTimeGroupBooking
                                    role={role}
                                    selected={selected}
                                    setListIconHotTime={setListIconHotTime}
                                    onChangeSelected={onChangeSelected}
                                    hasClickNextStep={hasClickNextStep}
                                    trackingData={trackingData}
                                    userId={trackingData.customerId}
                                    setSalonBusy={setSalonBusy}
                                    isSalonBusy={isSalonBusy}
                                    setIsLoadingHour={setIsLoadingHour}
                                    isLoadingHour={isLoadingHour}
                                    isClickDateBook={isClickDateBook}
                                    setReception={setReception}
                                    allowChange={allowChange}
                                />
                            </HoursSalonBackUp>
                        </>
                            )
                        )
                    }

                </div>
                {' '}
                {(selected[KEY.HOUR].hour || selectedSalonBackUp.hour.hour) &&
                    selected[KEY.SALON].id > 0 &&
                    (selected[KEY.CHOSEN_SERVICES].length > 0 ||
                        allowChange) && (
                        <Extension
                            salonId={selected[KEY.SALON].id}
                            bookManyPeople={selected[KEY.SALON].bookManyPeople}
                            note={selected[KEY.NOTE]}
                            datebook={selected[KEY.DATE_BOOK]}
                            onChangeExtension={onChangeSelected}
                            trackingData={trackingData}
                            listServiceSelected={selected[KEY.CHOSEN_SERVICES]}
                            listSkinner={listSkinner}
                            skinnerId={selected[KEY.SKINNER_ID]}
                            selectConsultation={
                                selected[KEY.SALON]?.selectConsultation || false
                            }
                        />
                    )}
            </div>

            <ModalSuggestService
                selected={selected}
                trackingData={trackingData}
                onChangeSelectedService={(value: any) =>
                    onChangeSelected(KEY.CHOSEN_SERVICES, value)
                }
                isClickNextInService={isClickNextInService}
            />
        </div>
    );
};

function mapStateToProps(state: any) {
    const { role, trackingData, user } = state;
    return { role, trackingData, userInfo: user };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setListIconHotTime: (value: any) => dispatch(setTimeSpecial(value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
