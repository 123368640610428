import React, { FC } from "react";
import arrowRight from "images/icons/arrowRight.svg";
import "./index.scss";

interface InputPhoneProps {
    text: any;
    handleClick: Function;
}

const ButtonBooking: FC<InputPhoneProps> = ({ text, handleClick }) => {
    return (
        <>
            <div className="button_booking" onClick={() => handleClick()}>
                <div className="button">{text}</div>
                <img className="icon_next" src={arrowRight} alt="button-booking" />
            </div>
        </>
    );
};

export default ButtonBooking;
