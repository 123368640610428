import React, { FC } from 'react';
import Image from 'components/MyImage/Image';
import parking from 'images/fillWhite/parking.svg';
import { ISalon } from '../../types/Salon';

const ItemSalon: FC<{
    item: ISalon;
    onChange: Function;
    index: number;
    isGrid?: boolean;
    width?: number;
    isSalonNear?: boolean;
    showNote?: boolean;
}> = ({
    item,
    onChange,
    index,
    isGrid = true,
    width = 0,
    isSalonNear = false,
    showNote = true,
}) => {
    const isHighlight = isSalonNear && index === 0;
    return (
        <div
            onClick={() => onChange(item)}
            className={`salon__item pointer show ${isGrid ? 'grid' : ''}`}
            role="presentation"
            style={{
                maxWidth: `${width > 0 ? `${width}px` : 'auto'}`,
            }}
        >
            <div className={`item ${isHighlight ? 'highlight' : ''}`}>
                {isHighlight ? (
                    <div className="tag">
                        {item?.distance > 0 && (
                            <div className="tag__item tag__item--speed">
                                {Math.round(item.distance) * 2} phút di chuyển
                            </div>
                        )}
                        {item.tag && item.distance > 0 && (
                            <div className="tag__item">{item.tag}</div>
                        )}
                    </div>
                ) : (
                    ''
                )}
                <div className={`${isGrid ? '' : 'flex'}`}>
                    <div
                        className="item__media"
                        style={{ width: isGrid ? 'auto' : '30%' }}
                    >
                        <div className="relative">
                            <Image
                                src={item.images || ''}
                                height={61}
                                size={30 / 21}
                            />
                            {item.parking && (
                                <div className="item__parking">
                                    <img src={parking} alt="" />
                                </div>
                            )}
                            {item?.isOpen && (
                                <div className="item__tag bg-30s">
                                    Sắp khai trương
                                </div>
                            )}
                            {item?.salonHot && (
                                <div className="item__tag bg-30s">
                                    Mới khai trương
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className="item__content"
                        style={{ width: isGrid ? 'auto' : '70%' }}
                    >
                        <div className="item__address">{item.addressNew}</div>
                        {item?.distance ||
                        item?.hasCame ||
                        item?.tagTopSalon ? (
                            <div className="flex space-x-1">
                                {item?.distance > 0 && (
                                    <div
                                        className={`item__tag 
                                                ${
                                                    isSalonNear
                                                        ? 'item__tag-list'
                                                        : ''
                                                }
                                                ${
                                                    isHighlight
                                                        ? 'bg-green'
                                                        : ''
                                                }`}
                                    >
                                        {`Cách ${item.distance} km`}
                                    </div>
                                )}
                                {item?.hasCame && (
                                    <div className="item__tag item__tag-list bg-green">
                                        Từng đến
                                    </div>
                                )}
                                {item?.tagTopSalon && !isGrid && showNote ? (
                                    <div className="item__tag item__tag-topSalon">
                                        {item?.tagTopSalon}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : null}
                        {item.addressNote && !isGrid && showNote ? (
                            <div className="item__note">{item.addressNote}</div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemSalon;
